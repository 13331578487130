import React, { createContext, useContext, useState, ReactNode } from 'react'
import { UserDTO, emptyUserDTO } from '../../../modules/users/models/User'

interface PatientContextProps {
  patient: UserDTO
  setPatient: (patient: UserDTO) => void
}

const PatientContext = createContext<PatientContextProps | undefined>(undefined)

export const PatientProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [patient, setPatient] = useState<UserDTO>(emptyUserDTO())

  return (
    <PatientContext.Provider value={{ patient, setPatient }}>{children}</PatientContext.Provider>
  )
}

export const usePatient = () => {
  const context = useContext(PatientContext)
  if (!context) {
    throw new Error('usePatient must be used within a PatientProvider')
  }
  return context
}
