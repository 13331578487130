import { RouteProps } from '../../routes/AppRouter'
import { ProfesionalTable as PT } from '../../features/profesionals'
import React from 'react'
import { useTitleHeaderContext } from '../../common/utils/TitleHeaderContext'

export function ProfesionalTable(props: RouteProps) {
  const title = props.title || ''
  const { setTitle } = useTitleHeaderContext()

  setTitle(title)

  return (
    <>
      <PT />
    </>
  )
}
