import { Query } from '../../../common/api/Query'
import { UserFormValue } from './UserFormValue'
import { ReadingStatus } from '../enums/ReadingStatus'
import { TemplateUserRecurrence } from './TemplateUserRecurrence'

export type UserFormWithTemplateUserRecurrenceProps = {
  id: string | undefined
  userID: string
  circleID: string
  formID: string
  userFormValues: UserFormValue[]
  readingStatus: ReadingStatus
  templateUserRecurrence: TemplateUserRecurrence
}

export class UserFormWithTemplateUserRecurrence {
  private readonly _id: string | undefined
  private readonly _userID: string
  private readonly _circleID: string
  private readonly _formID: string
  private _userFormValues: UserFormValue[]
  private _readingStatus: ReadingStatus
  private readonly _templateUserRecurrence: TemplateUserRecurrence

  constructor(p: UserFormWithTemplateUserRecurrenceProps) {
    this._id = p.id
    this._userID = p.userID
    this._circleID = p.circleID
    this._formID = p.formID
    this._userFormValues = p.userFormValues
    this._readingStatus = p.readingStatus
    this._templateUserRecurrence = p.templateUserRecurrence
  }

  get id(): string | undefined {
    return this._id
  }

  get userID(): string {
    return this._userID
  }

  get circleID(): string {
    return this._circleID
  }

  get formID(): string {
    return this._formID
  }

  get userFormValues(): UserFormValue[] {
    return [...this._userFormValues]
  }

  addUserFormValue(value: UserFormValue) {
    this._userFormValues.push(value)
  }

  removeUserFormValue(userFormID: string, formFieldID: string) {
    this._userFormValues = this._userFormValues.filter(
      (ufv) => ufv.userFormID !== userFormID || ufv.formFieldID !== formFieldID
    )
  }

  get readingStatus(): ReadingStatus {
    return this._readingStatus
  }

  set readingStatus(value: ReadingStatus) {
    this._readingStatus = value
  }

  get templateUserRecurrence(): TemplateUserRecurrence {
    return this._templateUserRecurrence
  }

  get templateID(): string {
    return this._templateUserRecurrence.templateID
  }

  get recurrenceValue(): string {
    return this._templateUserRecurrence.recurrence
  }
}

export class UserFormWithTemplateUserRecurrenceQuery extends Query<UserFormWithTemplateUserRecurrence> {}
