import { FormType } from '../../../modules/forms/enums/FormType'
import styles from '../feeding-form/FeedingForm.module.scss'
import { Box, ListItem, MenuItem } from '@mui/material'
import { Header } from '../../../components/header/Header'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import style from '../../training/Editor.module.css'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import {
  emptyUserTrainingDTO,
  UserTrainingDTO,
} from '../../../modules/training/models/UserTrainingDTO'
import calendarIcon from '../../../assets/calendar/calendar-newEvent.svg'
import { AppButton, ButtonTheme } from '../../../components/app-button/AppButton'
import { Input } from '../../../pages/user-profile/userProfileStylesMui'
import { emptyTrainingDTO, TrainingDTO } from '../../../modules/training/models/TrainingDTO'
import { getTrainingContainer } from '../../../container/training-module'
import { ITRainingService, TRAINING_SERVICE_KEY } from '../../../modules/training'
import { Query } from '../../../common/api/Query'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { Training } from '../../../modules/training/models/Training'
import { UserTrainingWithTrainingName } from '../../../modules/training/models/UserTrainingWithTrainingName'
import { UserTrainingWithTrainingNameDTO } from '../../../modules/training/models/UserTrainingWithTrainingNameDTO'
import { set } from 'lodash'

interface TrainingFormModalProps {
  handleRecurrence: (templateType: FormType, recurrence: string) => void
  onClose: () => void
  setTrainingSelected: (training: UserTrainingWithTrainingNameDTO) => void
  editTraining?: UserTrainingWithTrainingName
}

const trainingService = getTrainingContainer().get<ITRainingService>(TRAINING_SERVICE_KEY)

export const AssignTrainingFormModal: React.FC<TrainingFormModalProps> = ({
  handleRecurrence,
  setTrainingSelected,
  onClose,
  editTraining,
}) => {
  const { t } = useTranslation()
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false)
  const [userTraining, setUserTraining] = useState<UserTrainingDTO>(emptyUserTrainingDTO())
  const [isDatePickerFinishOpen, setIsDatePickerFinishOpen] = useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const [training, setTraining] = useState<TrainingDTO>(emptyTrainingDTO())
  const [listTraining, setListTraining] = useState<ItemList<Training>>(emptyList<Training>())
  const [errorMessage, setErrorMessage] = useState<string>('')

  useEffect(() => {
    trainingService
      .getFilteredList(
        new Query({
          query: [],
        })
      )
      .subscribe((response) => {
        setListTraining(response)
      })

    if (editTraining) {
      setUserTraining({
        ...userTraining,
        startDate: editTraining.userTraining.startDate,
        finishDate: editTraining.userTraining.finishDate,
        typeOfRepeating: editTraining.userTraining.typeOfRepeating,
        series: editTraining.userTraining.series,
        trainingID: editTraining.userTraining.trainingID,
      })
      setTraining({
        ...training,
        title: editTraining.title,
      })
    } else {
      setTraining(emptyTrainingDTO())
      setUserTraining(emptyUserTrainingDTO())
    }
  }, [editTraining])

  const handleTraining = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const selectedTraining = listTraining.items.find((item) => item.id === e.target.value)
    if (selectedTraining) {
      setTraining(selectedTraining)
      setUserTraining({
        ...userTraining,
        trainingID: e.target.value,
      })
    }
  }

  const handleDateChange = (date: any, key: 'startDate' | 'finishDate') => {
    if (date) {
      setUserTraining({
        ...userTraining,
        [key]: date.toISOString(),
      })
    }
  }

  const handleRepeatingType = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setUserTraining({
      ...userTraining,
      typeOfRepeating: parseInt(e.target.value),
    })
  }

  const validateSave = (training: TrainingDTO) => {
    return !!(training.title && training.title.trim() !== '')
  }

  const handleSave = (userTraining: UserTrainingDTO, training: TrainingDTO) => {
    if (validateSave(training)) {
      if (editTraining) {
        const userTrainingWithTrainingName: UserTrainingWithTrainingNameDTO = {
          title: training.title,
          userTraining: {
            id: editTraining.userTraining.id,
            startDate: userTraining.startDate,
            finishDate: userTraining.finishDate,
            series: userTraining.series,
            typeOfRepeating: userTraining.typeOfRepeating,
            trainingID: userTraining.trainingID,
            frecuency: userTraining.frecuency,
            minReps: userTraining.minReps,
            maxReps: userTraining.maxReps,
            repsEspecs: userTraining.repsEspecs,
            completed: userTraining.completed,
            userID: editTraining.userTraining.userID,
            recurrence: userTraining.recurrence,
          },
        }
        setTrainingSelected(userTrainingWithTrainingName)
        onClose()
        return
      }
      const userTrainingWithTrainingName: UserTrainingWithTrainingNameDTO = {
        title: training.title,
        userTraining: userTraining,
      }
      setTrainingSelected(userTrainingWithTrainingName)
      onClose()
    } else {
      setErrorMessage(t('selectTraining'))
      return false
    }
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.content}>
        <Header labelStyle={styles.header} label={t('AssignTraining')} />
        <Box mt={2}>
          <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
            {t('training')}
          </p>
          <Input
            select
            variant={'outlined'}
            sx={{ width: '80%' }}
            id="trainingId"
            name={'trainingId'}
            value={userTraining.trainingID}
            label={''}
            onChange={handleTraining}
          >
            {listTraining.items.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {t(item.title)}
              </MenuItem>
            ))}
          </Input>
          {errorMessage && (
            <Box mt={2}>
              <p style={{ color: 'red' }}>{errorMessage}</p>
            </Box>
          )}
        </Box>
        <Box mt={2} style={{ display: 'flex' }}>
          <ListItem>
            <Box>
              <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                {t('Comienzo')}
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                <DatePicker
                  open={isDatePickerOpen}
                  onOpen={() => setIsDatePickerOpen(true)}
                  onClose={() => setIsDatePickerOpen(false)}
                  key={'startDate'}
                  format={'DD/MM/YYYY'}
                  minDate={dayjs(new Date())}
                  onChange={(date) => handleDateChange(date, 'startDate')}
                  value={dayjs(userTraining.startDate)}
                  label={''}
                  onError={(reason) => {
                    switch (reason) {
                      case 'invalidDate':
                        setDateTimePickerError(t('invalidDateMessage'))
                        break
                      case 'minDate':
                        setDateTimePickerError(t('minDateMessage'))
                        break
                    }
                  }}
                  slotProps={{
                    textField: {
                      size: 'small',
                      id: 'startDate',
                      helperText: DateTimePickerError,
                      InputProps: {
                        sx: {
                          '& fieldset': {
                            borderRadius: 32,
                            border: '1px solid #E8E7EC',
                            fontFamily: 'Montserrat',
                          },
                          '& .MuiInputBase-root': { fontFamily: 'Montserrat' },
                          '& .MuiOutlinedInput-root': {
                            border: 'none',
                            '&.Mui-focused fieldset': {
                              borderColor: '#ADB84E',
                            },
                          },
                        },
                        onClick: () => setIsDatePickerOpen(true),
                      },
                    },
                  }}
                  slots={{
                    openPickerIcon: CalendarIcon,
                  }}
                />
              </LocalizationProvider>
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                {t('Finalizar el')}
              </p>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                <DatePicker
                  open={isDatePickerFinishOpen}
                  onOpen={() => setIsDatePickerFinishOpen(true)}
                  onClose={() => setIsDatePickerFinishOpen(false)}
                  key={'finishDate'}
                  format={'DD/MM/YYYY'}
                  value={dayjs(userTraining.finishDate)}
                  minDate={dayjs(startDate)}
                  onChange={(date) => handleDateChange(date, 'finishDate')}
                  label={''}
                  onError={(reason) => {
                    switch (reason) {
                      case 'invalidDate':
                        setDateTimePickerError(t('invalidDateMessage'))
                        break
                      case 'minDate':
                        setDateTimePickerError(t('minDateMessage'))
                        break
                    }
                  }}
                  slotProps={{
                    textField: {
                      size: 'small',
                      id: 'finishDate',
                      helperText: DateTimePickerError,
                      InputProps: {
                        sx: {
                          '& fieldset': {
                            borderRadius: 32,
                            border: '1px solid #E8E7EC',
                            fontFamily: 'Montserrat',
                          },
                          '& .MuiInputBase-root': { fontFamily: 'Montserrat' },
                          '& .MuiOutlinedInput-root': {
                            border: 'none',
                            '&.Mui-focused fieldset': {
                              borderColor: '#ADB84E',
                            },
                          },
                        },
                        onClick: () => setIsDatePickerFinishOpen(true),
                      },
                    },
                  }}
                  slots={{
                    openPickerIcon: CalendarIcon,
                  }}
                />
              </LocalizationProvider>
            </Box>
          </ListItem>
        </Box>

        <Box mt={2} mb={3} style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ width: '50%' }}>
            <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
              {t('amount')}
            </p>
            <Input
              variant={'outlined'}
              id="series"
              name={'series'}
              value={userTraining.series}
              label={''}
              onChange={(e) =>
                setUserTraining({ ...userTraining, series: parseInt(e.target.value) })
              }
            />
          </Box>
          <Box sx={{ width: '50%' }}>
            <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
              {t('RepeatType')}
            </p>
            <Input
              select
              variant={'outlined'}
              sx={{ width: '80%' }}
              id="typeOfRepeating"
              name={'typeOfRepeating'}
              value={userTraining.typeOfRepeating}
              label={''}
              onChange={handleRepeatingType}
            >
              <MenuItem value={1}>{t('day')}</MenuItem>
              <MenuItem value={2}>{t('week')}</MenuItem>
              <MenuItem value={3}>{t('month')}</MenuItem>
            </Input>
          </Box>
        </Box>

        <Box className={styles.buttons} mt={2} display="flex" justifyContent="center">
          <Box style={{ marginRight: 10 }}>
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'submit'}
              label={t('add')}
              handler={(e) => handleSave(userTraining, training)}
            />
          </Box>
          <AppButton
            theme={ButtonTheme.NewSecondary}
            type={'button'}
            label={t('cancel')}
            handler={onClose}
          />
        </Box>
      </Box>
    </Box>
  )
}

const CalendarIcon = () => <img src={calendarIcon} alt="Calendar" />
