import { Box, Grid, MenuItem } from '@mui/material'
import styles from '../users/Editor.module.css'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import {
  emptyUserTreatmentDTO,
  fromModel,
  UserTreatmentDTO,
} from '../../modules/treatments/models/UserTreatmentDTO'
import { getContentContainer } from '../../container/treatment-module'
import {
  ITreatmentService,
  TREATMENT_SERVICE_KEY,
  USER_TREATMENT_SERVICE_KEY,
} from '../../modules/treatments'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import genericStyle from '../../common/utils/generic.module.css'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { ROUTE_DASHBOARD, ROUTE_PATIENTS } from '../../routes/routes-constants'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { getAppContainer, STATUS_SERVICE_KEY } from '../../container/app'
import { IStatusService } from '../../common/status/StatusService'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import { Input } from '../../pages/user-profile/userProfileStylesMui'
import { InputTextDescription } from '../resource/userProfileStylesMui'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import calendarIcon from '../../assets/calendar/calendar-newEvent.svg'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import style from '../calendar/Editor.module.css'
import { useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import { unitTypeTypes } from '../../modules/treatments/enums/UnitType'
import { Treatment } from '../../modules/treatments/models/Treatment'
import { Query } from '../../common/api/Query'
import { RECURRENCE } from './constants'
import { IUserTreatmentService } from '../../modules/treatments/services/UserTreatmentService'

type userTreatmentsEditorProps = {
  id?: string
  userID?: string
}

const userContainer = getUserContainer()
const userTreatmentService = getContentContainer().get<IUserTreatmentService>(
  USER_TREATMENT_SERVICE_KEY
)
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)
const treatmentService = getContentContainer().get<ITreatmentService>(TREATMENT_SERVICE_KEY)

export function Editor(props: userTreatmentsEditorProps) {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [userTreatment, setUserTreatment] = useState<UserTreatmentDTO>(
    emptyUserTreatmentDTO(props.userID ?? '')
  )
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false)
  const [isDatePickerFinishOpen, setIsDatePickerFinishOpen] = useState<boolean>(false)
  const [treatments, setTreatments] = useState<Treatment[]>([])
  const [treatmentID, setTreatmentID] = useState<string>('')
  const [currentTreatment, setCurrentTreatment] = useState<Treatment | undefined>(undefined)
  const { openMenuLeft } = useIsOpenMenuLeftContext()
  const navigate = useNavigate()

  console.log('userTreatment', userTreatment)
  console.log('currentTreatment', currentTreatment)

  /*useEffect(() => {
      if (!props.id) return
      userTreatmentService.getByID(props.id).subscribe((res) => {
        if (!res) return
        setuserTreatment(fromModel(res))
      })
    }, [])*/

  useEffect(() => {
    treatmentService
      .getFilteredList(
        new Query({
          query: [],
        })
      )
      .subscribe((res) => {
        console.log('res', res)
        setTreatments(res.items)
      })
  }, [])

  useEffect(() => {
    if (!treatmentID) return
    treatmentService.getByID(treatmentID).subscribe((res) => {
      if (!res) return
      setCurrentTreatment(res)
    })
  }, [treatmentID])

  const handleUserTreatment = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (e.target.name === 'treatmentID') {
      setTreatmentID(e.target.value)
    }
    setUserTreatment(Object.assign({ ...userTreatment }, { [e.target.name]: e.target.value }))
  }

  const handleStartDate = (event: any) =>
    event && setUserTreatment(Object.assign({ ...userTreatment }, { startDate: event.toDate() }))

  const handleEndDate = (event: any) =>
    event && setUserTreatment(Object.assign({ ...userTreatment }, { endDate: event.toDate() }))

  const handleBack = () => navigate(ROUTE_PATIENTS)

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (loggedUser?.id) {
      const newUserTreatment: UserTreatmentDTO = {
        ...userTreatment,
        id: userTreatment.id,
        creatorID: loggedUser?.id,
      }

      if (props.id) {
        userTreatmentService.update(newUserTreatment).subscribe((res) => {
          navigate(`${ROUTE_DASHBOARD}/${userTreatment.userID}`)
          statusService.sendStatus({ variant: 'success' })
        })
      } else {
        userTreatmentService.add(newUserTreatment).subscribe((res) => {
          navigate(`${ROUTE_DASHBOARD}/${userTreatment.userID}`)
          statusService.sendStatus({ variant: 'success' })
        })
      }
    }
  }

  const CalendarIcon = () => <img src={calendarIcon} alt="Calendar" />

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: openMenuLeft ? '50px' : '',
      }}
    >
      <div
        style={{ maxWidth: openMenuLeft ? 'calc(100% - 250px)' : 'calc(100% - 300px)' }}
        className={style.pageContainer}
      >
        <div className={genericStyle.headerContainer}>
          <Typography>{t('medication')}</Typography>
        </div>
        <div style={{ height: '100%' }} className={style.container}>
          <>
            <form className={styles.form} onSubmit={handleSave}>
              <Box mb={2} className={style.textField} display={'flex'}>
                <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                  {t('name')}
                </p>
                <Input
                  select
                  variant={'outlined'}
                  sx={{ width: '80%' }}
                  id="id"
                  name={'treatmentID'}
                  value={userTreatment.treatmentID}
                  label={''}
                  onChange={handleUserTreatment}
                >
                  {treatments.map((treatment) => (
                    <MenuItem value={treatment.id}>{treatment.name}</MenuItem>
                  ))}
                </Input>
              </Box>
              <Box display={'flex'} width={'100%'} marginTop={4}>
                <Box mb={2} width={'100%'} mr={4}>
                  <p style={{ marginBottom: 10, marginRight: 24 }} className={style.label}>
                    {t('quantity')}
                  </p>
                  <Input
                    fullWidth
                    value={userTreatment.dose}
                    id="dose"
                    name="dose"
                    label={''}
                    variant="outlined"
                    required={true}
                    onChange={(e) => handleUserTreatment(e)}
                  />
                </Box>
                <Box mb={2} width={'100%'} mr={4}>
                  <p style={{ marginBottom: 10, marginRight: 24 }} className={style.label}>
                    {t('unit')}
                  </p>
                  <Input
                    fullWidth
                    value={currentTreatment ? unitTypeTypes()[currentTreatment.unit] : ''}
                    id="unit"
                    name="unit"
                    disabled={true}
                    label={''}
                    variant="outlined"
                    onChange={() => {}}
                  />
                </Box>
                <Box mb={2} width={'100%'} ml={4}>
                  <p style={{ marginBottom: 10, marginRight: 24 }} className={style.label}>
                    {t('timeInterval')}
                  </p>
                  <Input
                    select
                    variant={'outlined'}
                    sx={{ width: '80%' }}
                    id="recurrence"
                    name={'recurrence'}
                    value={userTreatment.recurrence}
                    label={''}
                    onChange={handleUserTreatment}
                  >
                    <MenuItem value={RECURRENCE.SIX_HOURS}>6h</MenuItem>
                    <MenuItem value={RECURRENCE.EIGHT_HOURS}>8h</MenuItem>
                    <MenuItem value={RECURRENCE.TWELVE_HOURS}>12h</MenuItem>
                    <MenuItem value={RECURRENCE.TWENTY_FOUR_HOURS}>24h</MenuItem>
                  </Input>
                </Box>
              </Box>
              <Box mb={2}>
                <p style={{ marginTop: 30, marginBottom: 15 }} className={style.label}>
                  {t('duration')}
                </p>
              </Box>
              <Box display={'flex'} alignItems={'center'} width={'100%'}>
                <Box mb={2} display={'flex'} alignItems={'center'} mr={4}>
                  <p style={{ marginBottom: 0, marginRight: 20 }} className={style.label}>
                    {t('startDate')}
                  </p>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                    <DatePicker
                      open={isDatePickerOpen}
                      onOpen={() => setIsDatePickerOpen(true)}
                      onClose={() => setIsDatePickerOpen(false)}
                      key={'startDate'}
                      format={'DD/MM/YYYY'}
                      onChange={handleStartDate}
                      value={dayjs(userTreatment.startDate)}
                      label={''}
                      onError={(reason) => {
                        switch (reason) {
                          case 'invalidDate':
                            setDateTimePickerError(t('invalidDateMessage'))
                            break
                          case 'minDate':
                            setDateTimePickerError(t('minDateMessage'))
                            break
                        }
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          id: 'startDate',
                          helperText: DateTimePickerError,
                          InputProps: {
                            sx: {
                              '& fieldset': {
                                borderRadius: 32,
                                border: '1px solid #E8E7EC',
                                fontFamily: 'Montserrat',
                              },
                              '& .MuiInputBase-root': { fontFamily: 'Montserrat' },
                              '& .MuiOutlinedInput-root': {
                                border: 'none',
                                '&.Mui-focused fieldset': {
                                  borderColor: '#ADB84E',
                                },
                              },
                            },
                            onClick: () => setIsDatePickerOpen(true),
                          },
                        },
                      }}
                      slots={{
                        openPickerIcon: CalendarIcon,
                      }}
                    />
                  </LocalizationProvider>
                </Box>
                <Box display={'flex'} alignItems={'center'} mb={2}>
                  <p style={{ marginBottom: 0, marginRight: 20 }} className={style.label}>
                    {t('finishDate')}
                  </p>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
                    <DatePicker
                      open={isDatePickerFinishOpen}
                      onOpen={() => setIsDatePickerFinishOpen(true)}
                      onClose={() => setIsDatePickerFinishOpen(false)}
                      key={'finishDate'}
                      format={'DD/MM/YYYY'}
                      onChange={handleEndDate}
                      value={dayjs(userTreatment.endDate)}
                      label={''}
                      onError={(reason) => {
                        switch (reason) {
                          case 'invalidDate':
                            setDateTimePickerError(t('invalidDateMessage'))
                            break
                          case 'minDate':
                            setDateTimePickerError(t('minDateMessage'))
                            break
                        }
                      }}
                      slotProps={{
                        textField: {
                          size: 'small',
                          id: 'finishDate',
                          helperText: DateTimePickerError,
                          InputProps: {
                            sx: {
                              '& fieldset': {
                                borderRadius: 32,
                                border: '1px solid #E8E7EC',
                                fontFamily: 'Montserrat',
                              },
                              '& .MuiInputBase-root': { fontFamily: 'Montserrat' },
                              '& .MuiOutlinedInput-root': {
                                border: 'none',
                                '&.Mui-focused fieldset': {
                                  borderColor: '#ADB84E',
                                },
                              },
                            },
                            onClick: () => setIsDatePickerFinishOpen(true),
                          },
                        },
                      }}
                      slots={{
                        openPickerIcon: CalendarIcon,
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              {/* <Box mb={2}>
                  <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                    {t('dose')}
                  </p>
                  <Input
                    fullWidth
                    value={userTreatment.dose}
                    id="dose"
                    name="dose"
                    label={''}
                    size={'medium'}
                    variant="outlined"
                    type={'number'}
                    onChange={handleuserTreatment}
                  />
                </Box>
                <Box display={'flex'}>
                  <Box mr={4}>
                    <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                      {t('duration')}
                    </p>
                    <Input
                      fullWidth
                      id={'duration'}
                      key={'duration'}
                      name={'duration'}
                      label={''}
                      value={userTreatment.duration}
                      type={'number'}
                      onChange={(e) =>
                        setuserTreatment(
                          Object.assign(
                            { ...userTreatment },
                            { [e.target.name]: Number(e.target.value) }
                          )
                        )
                      }
                      variant="outlined"
                    />
                  </Box>
                  <Box mb={2}>
                    <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                      {t('frequency')}
                    </p>
                    <Input
                      fullWidth
                      id={'frequency'}
                      key={'frequency'}
                      name={'frequency'}
                      label={''}
                      value={userTreatment.frequency}
                      type={'number'}
                      onChange={(e) =>
                        setuserTreatment(
                          Object.assign(
                            { ...userTreatment },
                            { [e.target.name]: Number(e.target.value) }
                          )
                        )
                      }
                      variant="outlined"
                    />
                  </Box>
                </Box>
                <Box>
                  <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                    {t('reason')}
                  </p>
                  <InputTextDescription
                    fullWidth
                    multiline
                    rows={8}
                    value={userTreatment.reason}
                    id="reason"
                    name="reason"
                    label={''}
                    size={'medium'}
                    variant="outlined"
                    required={true}
                    onChange={handleuserTreatment}
                  />
                </Box>*/}
              <Box mb={3} mr={2} mt={3} display="flex" justifyContent="flex-end">
                <Box mr={3}>
                  <AppButton
                    theme={ButtonTheme.NewSecondary}
                    type={'button'}
                    label={t('back')}
                    handler={handleBack}
                  />
                </Box>
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'submit'}
                  label={t('add')}
                  handler={(e) => handleSave(e)}
                />
              </Box>
            </form>
          </>
        </div>
      </div>
    </div>
  )
}
