import { Form } from '../models/Form'
import { Container, IInit } from '../../../common/container/Container'
import { FormContainerConfig } from '../container'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { FormDTO, Recurrence, toModel } from '../models/FormDTO'
import { prepareURL } from '../../../common/api/http-helpers'
import { FormField } from '../models/FormField'
import { STATUS_SERVICE_KEY } from '../../../container/app'

export interface IFormApi extends IInit {
  getByID(id: string): Observable<Form | undefined>
  getByName(name: string): Observable<Form | undefined>
  getByNamesAndAddUserForms(
    circleID: string,
    userID: string,
    recurrence: Recurrence[]
  ): Observable<any>

  getFilteredList(q: Query<Form>): Observable<ItemList<Form>>

  add(e: FormDTO): Observable<Form | undefined>

  update(e: FormDTO): Observable<Form | undefined>

  //updateFormField(e: FormField): Observable<FormField | undefined>
  delete(id: string): Observable<boolean>
}

export class FormApi implements IFormApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as FormContainerConfig).moduleFullUrl
  }

  add(e: FormDTO): Observable<Form | undefined> {
    return this._httpClient.post<any>({ url: `${this._url}/template`, body: e }).pipe(
      map<FormDTO, Form>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/template/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<Form | undefined> {
    return this._httpClient.get<any>({ url: `${this._url}/template/${id}` }).pipe(
      map<FormDTO, Form>((d) => {
        const result = d.formFields.map(
          (ff: any) =>
            new FormField({
              correctValues: ff.correctValues,
              id: ff.id,
              //formID: ff.formID,
              optionValues: ff.optionValues,
              order: ff.order,
              required: ff.required,
              title: ff.title,
              type: ff.type,
            })
        )
        //d.formFields = result.sort((a, b) => a.order - b.order)
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getByName(name: string): Observable<Form | undefined> {
    return this._httpClient.get<any>({ url: `${this._url}/template/name/${name}` }).pipe(
      map<FormDTO, Form>((d) => {
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<Form>): Observable<ItemList<Form>> {
    return this._httpClient
      .get<ItemList<any>>({ url: prepareURL(`${this._url}/template`, q) })
      .pipe(
        map<ItemList<FormDTO>, ItemList<Form>>((dto) => {
          const itemList = emptyList<Form>()
          itemList.count = dto.count
          itemList.items = dto.items.map((d) => toModel(d))
          return itemList
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<Form>())
        })
      )
  }

  update(e: FormDTO): Observable<Form | undefined> {
    return this._httpClient.put<any>({ url: `${this._url}/template`, body: e }).pipe(
      map<FormDTO, Form>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getByNamesAndAddUserForms(
    circleID: string,
    userID: string,
    recurrence: Recurrence[]
  ): Observable<any> {
    return this._httpClient
      .post<any>({
        url: `${this._url}/template/names`,
        body: { circleID, userID, recurrence },
      })
      .pipe(
        map((response) => {
          this._statusService.sendStatus({ variant: 'success' })
          return response
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  /*updateFormField(e: FormField): Observable<FormField | undefined> {
    return this._httpClient
      .put<FormField>({ url: `${this._url}/template_fields`, body: fromModelFormField(e) })
      .pipe(
        //map<FormFieldDTO, FormField>((d) => toModelFormField(d)),
        catchError((err) => {
          this._statusService.send(err)
          return of(undefined)
        })
      )
  }*/
}
