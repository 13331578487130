import React, { useEffect, useState } from 'react'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import { Alert, Box, Button, Typography, Skeleton, FormControlLabel } from '@mui/material'
import genericStyle from '../../common/utils/generic.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './CreateFamiliarView.module.css'
import { Input } from '../../pages/user-profile/userProfileStylesMui'
import AvatarProfile from '../../components/avatar-profile/AvatarProfile'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { AppCheckbox } from 'components/app-checkbox/AppCheckbox'
import { Familiar, useFamiliars } from '../../hooks/useFamiliars'
import { initialFamiliars } from './constants'
import { usePatientData } from '../../hooks/usePatientData'
const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)

interface EditorRelativesProps {
  familiars: Familiar[]
  onSave: () => void
  onCancel: () => void
  handleAddFamiliar: (familiar: Familiar) => void
  id?: string
}

export const CreateFamiliarView: React.FC<EditorRelativesProps> = ({
  familiars,
  onSave,
  onCancel,
  handleAddFamiliar,
  id,
}) => {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const navigate = useNavigate()
  const { openMenuLeft } = useIsOpenMenuLeftContext()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [message, setMessage] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const { isCheck, isCheckAll, handleClickCheckbox, handleSelectAll, checkEmailFamiliar } =
    useFamiliars(familiars)
  const [familiar, setFamiliar] = useState<Familiar>(initialFamiliars)
  const { handleUpdateFamiliars } = usePatientData(familiars, [], id)

  useEffect(() => {
    userService.getByID(loggedUser?.id || '').subscribe(() => {
      setIsLoading(false)
    })
  }, [])

  const addFamiliar = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFamiliar({
      ...familiar,
      [e.target.id]: e.target.value,
    })
  }

  const saveData = (e: React.FormEvent) => {
    console.log('entro', familiar)
    e.preventDefault()
    if (checkEmailFamiliar(familiar.email)) {
      setErrorMessage('emailAlreadyExists')
      return
    }
    handleAddFamiliar(familiar)
    handleUpdateFamiliars(familiar)
    onSave()
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: openMenuLeft ? '50px' : '',
      }}
    >
      <Box
        style={{ maxWidth: openMenuLeft ? 'calc(100% - 250px)' : 'calc(100% - 300px)' }}
        className={genericStyle.pageContainer}
      >
        <Box className={styles.containerAddRelative}>
          {!isLoading ? (
            <div className={styles.form}>
              <Box display={'flex'} bgcolor="transparent" width={'100%'} flexDirection="row">
                <Box className={styles.avatarContainer}>
                  <AvatarProfile />
                  <Box>
                    <Typography className={styles.userName}>
                      {`${familiar.firstName} ${familiar.lastName}`}
                    </Typography>
                    <Typography className={styles.roleText}>Familiar</Typography>
                  </Box>
                </Box>

                <Box mt={4} width={'60%'}>
                  <Box
                    style={{ maxWidth: openMenuLeft ? '100%' : '77%' }}
                    className={styles.headerContainer}
                  >
                    <Typography className={styles.headerTitle}>{t('relativeData')}</Typography>
                  </Box>
                  <Box mb={3} className={styles.textFieldBox} display="flex" gap={2}>
                    <Box flex={1}>
                      <Typography className={styles.inputTitle}>{t('name')}</Typography>
                      <Input
                        id={'firstName'}
                        name="firstName"
                        className={styles.textField}
                        value={familiar.firstName}
                        onChange={addFamiliar}
                        type="text"
                        required
                        variant={'outlined'}
                        size={'small'}
                      />
                    </Box>
                    <Box flex={3}>
                      <Typography className={styles.inputTitle}>{t('lastName')}</Typography>
                      <Input
                        id={'lastName'}
                        name="lastName"
                        className={styles.textField}
                        value={familiar.lastName}
                        onChange={addFamiliar}
                        type="text"
                        required
                        variant={'outlined'}
                        size={'small'}
                      />
                    </Box>
                  </Box>
                  <Box mb={3} className={styles.textFieldBox} display="flex" gap={2}>
                    <Box flex={1}>
                      <Typography className={styles.inputTitle}>{t('email')}</Typography>
                      <Input
                        id={'email'}
                        name="email"
                        className={styles.textField}
                        value={familiar.email}
                        type="email"
                        onChange={addFamiliar}
                        required
                        variant={'outlined'}
                        size={'small'}
                      />
                    </Box>
                    <Box flex={3}>
                      <Typography className={styles.inputTitle}>{t('phone')}</Typography>
                      <Input
                        id={'phone'}
                        name="phone"
                        className={styles.textField}
                        value={familiar.phone}
                        onChange={addFamiliar}
                        type="text"
                        required
                        variant={'outlined'}
                        size={'small'}
                      />
                    </Box>
                  </Box>
                  <Box mb={3} className={styles.textFieldBox}>
                    <Box>
                      <Typography className={styles.inputTitle}>{t('role')}</Typography>
                      <Typography className={styles.typoText} sx={{ paddingLeft: '14px' }}>
                        {t('familiar')}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    style={{ maxWidth: openMenuLeft ? '100%' : '77%' }}
                    className={styles.headerContainer}
                  >
                    <Typography className={styles.headerTitle} style={{ marginTop: '15px' }}>
                      {t('notifications')}
                    </Typography>
                  </Box>
                  <Box display="flex" gap={2}>
                    <Box display="flex" flexDirection="column" gap={1}>
                      <FormControlLabel
                        control={
                          <AppCheckbox onChange={handleSelectAll} name="app" checked={isCheckAll} />
                        }
                        label={<span className={styles.checkboxLabel}>{t('app')}</span>}
                      />
                      <FormControlLabel
                        control={
                          <AppCheckbox
                            onChange={handleClickCheckbox}
                            name="food"
                            checked={isCheck[0]}
                          />
                        }
                        label={<span className={styles.checkboxLabel}>{t('food')}</span>}
                      />
                      <FormControlLabel
                        control={
                          <AppCheckbox
                            onChange={handleClickCheckbox}
                            name="conduct"
                            checked={isCheck[2]}
                          />
                        }
                        label={<span className={styles.checkboxLabel}>{t('conduct')}</span>}
                      />
                    </Box>
                    <Box display="flex" flexDirection="column" gap={1} paddingTop="46px">
                      <FormControlLabel
                        control={
                          <AppCheckbox
                            onChange={handleClickCheckbox}
                            name="medication"
                            checked={isCheck[1]}
                          />
                        }
                        label={<span className={styles.checkboxLabel}>{t('medication')}</span>}
                      />
                      <FormControlLabel
                        control={
                          <AppCheckbox
                            onChange={handleClickCheckbox}
                            name="training"
                            checked={isCheck[3]}
                          />
                        }
                        label={<span className={styles.checkboxLabel}>{t('training')}</span>}
                      />
                    </Box>
                  </Box>

                  <Box className={styles.buttonContainer} display="flex">
                    <Button className={styles.buttonAdd} type={'button'} onClick={saveData}>
                      {t('save')}
                    </Button>
                    <Button className={styles.buttonCancel} type={'button'} onClick={onCancel}>
                      {t('cancel')}
                    </Button>
                  </Box>
                </Box>
                {message && (
                  <Box mb={3}>
                    <Alert severity="success" key="message" id="message">
                      {t(message)}
                    </Alert>
                  </Box>
                )}
                {errorMessage && (
                  <Box mb={3}>
                    <Alert severity="error" key="errorMessage" id="errorMessage">
                      {t(errorMessage)}
                    </Alert>
                  </Box>
                )}
              </Box>
            </div>
          ) : (
            <>
              <Box bgcolor="white" m="1rem">
                <Box className={styles.headerContainer}>
                  <p className={styles.label}>{t('relativeData')}</p>
                </Box>
                <Box className={styles.textFieldBox}>
                  <Skeleton className={styles.textField} height={70} />
                  <Skeleton className={styles.textField} height={70} />
                </Box>
                <Box className={styles.textFieldBox}>
                  <Skeleton className={styles.textField} height={70} />
                </Box>
                <Box
                  className={styles.buttonContainer}
                  display="flex"
                  justifyContent="center"
                  gap={2}
                >
                  <Button className={styles.buttonSubmit} type={'button'} onClick={saveData}>
                    {t('save')}
                  </Button>
                  <Button
                    className={styles.buttonCancel}
                    type={'button'}
                    onClick={() => navigate(-1)}
                  >
                    {t('cancel')}
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </div>
  )
}
