import { getUserContainer } from '../../container/user-module'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import {
  IRolesService,
  IUserService,
  LOGGED_USER_SERVICE_KEY,
  ROLES_SERVICE_KEY,
  USER_SERVICE_KEY,
} from '../../modules/users'
import { emptyUserDTO, UserDTO } from '../../modules/users/models/User'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import styles from './Editor.module.css'
import genericStyle from '../../common/utils/generic.module.css'
import { ROUTE_NOTIFICATIONS } from '../../routes/routes-constants'
import { Header } from '../../components/header/Header'
import { Role } from '../../modules/users/models/Role'
import { Box, Button, Typography, Alert, Skeleton } from '@mui/material'
import { UpdatePasswordDTO } from '../../modules/users/models/UpdatePasswordDTO'
import { IStatusService } from '../../common/status/StatusService'
import { getAppContainer, STATUS_SERVICE_KEY } from '../../container/app'
import AvatarProfile from '../../components/avatar-profile/AvatarProfile'
import { Input } from '../../pages/user-profile/userProfileStylesMui'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import { useNavigate } from 'react-router-dom'

const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const roleService = userContainer.get<IRolesService>(ROLES_SERVICE_KEY)
const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)

enum Messages {
  SUCCESS = 'changesSavedSuccessfully',
}

export function Editor() {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [userToEdit, setUserToEdit] = useState<UserDTO>(emptyUserDTO())
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [updatePassword, setUpdatePassword] = useState<UpdatePasswordDTO>()
  const [message, setMessage] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [role, setRole] = useState<Role[]>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { openMenuLeft } = useIsOpenMenuLeftContext()
  const navigate = useNavigate()

  useEffect(() => {
    userService.getByID(loggedUser?.id || '').subscribe((res) => {
      res && setUserToEdit(res.toDTO())
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    setMessage('')
  }, [userToEdit])

  useEffect(() => {
    roleService.getAll().subscribe((res) => {
      setRole(res)
    })
  }, [])
  /*
  useEffect(() => {
    updatePassword &&
    userService.changePassword(updatePassword).subscribe((res:any) => {
      if (res === "incorrectPassword") {
        setErrorMessage('La contraseña actual es incorrecta')
        setStopUpdate(true)
      }
    })
  }, [updatePassword])*/

  const handleInput = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
    setUserToEdit(Object.assign({ ...userToEdit }, { [e.target.name]: e.target.value }))

  const goHome = () => navigate(ROUTE_NOTIFICATIONS)

  const validatePassword = () => {
    if (!newPassword && !repeatPassword) {
      setErrorMessage(t('voidFields'))
      return false
    }

    if (newPassword !== repeatPassword) {
      setErrorMessage('passNotEquals')
      return false
    }

    return true
  }

  const saveData = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (oldPassword) {
      if (validatePassword() && loggedUser) {
        setErrorMessage('')
        const newPasswordSend: UpdatePasswordDTO = {
          id: loggedUser.id,
          password: oldPassword,
          newPassword: newPassword,
        }

        userService.changePassword(newPasswordSend).subscribe((res: any) => {
          if (res === 'incorrectPassword') {
            setErrorMessage('Contraseña actual incorrecta')
          } else {
            userService
              .update(userToEdit)
              .subscribe(() => statusService.sendStatus({ variant: 'success' }))
          }
        })
      }
    } else if (!oldPassword) {
      setErrorMessage('incorrectOldPass')
      return false
    } else {
      userService
        .update(userToEdit)
        .subscribe(() => statusService.sendStatus({ variant: 'success' }))
    }
  }

  const returnRole = () => {
    if (userToEdit.roles.includes('admin')) {
      return t('admin')
    } else if (userToEdit.roles.includes('med')) {
      return t('doctor')
    } else if (userToEdit.roles.includes('patient')) {
      return t('patient')
    } else {
      return t('familiar')
    }
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: openMenuLeft ? '50px' : '',
      }}
    >
      <Box
        style={{ maxWidth: openMenuLeft ? 'calc(100% - 250px)' : 'calc(100% - 300px)' }}
        className={genericStyle.pageContainer}
      >
        <Box className={genericStyle.container}>
          {!isLoading ? (
            <form className={styles.form} onSubmit={saveData}>
              <Box display={'flex'} bgcolor="transparent" m="1rem" width={'100%'}>
                <Box className={styles.avatarContainer}>
                  <AvatarProfile />
                  <Box>
                    <Typography
                      className={styles.userName}
                    >{`${userToEdit.firstName} ${userToEdit.lastName}`}</Typography>
                    <Typography className={styles.roleText}>{returnRole()}</Typography>
                  </Box>
                </Box>
                <Box mt={4} width={'100%'}>
                  <Box
                    style={{ maxWidth: openMenuLeft ? '100%' : '77%' }}
                    className={styles.headerContainer}
                  >
                    <Typography className={styles.headerTitle}>{t('userData')}</Typography>
                  </Box>
                  <Box mb={3} className={styles.textFieldBox}>
                    <Box>
                      <Typography className={styles.inputTitle}>{t('name')}</Typography>
                      <Input
                        id={'firstName'}
                        label=""
                        key={'firstName'}
                        name="firstName"
                        className={styles.textField}
                        value={userToEdit.firstName}
                        onChange={handleInput}
                        type="text"
                        required={true}
                        variant={'outlined'}
                        size={'small'}
                        disabled={userToEdit.isPatient}
                      />
                    </Box>
                    <Box>
                      <Typography className={styles.inputTitle}>{t('lastName')}</Typography>
                      <Input
                        id={'lastName'}
                        label={''}
                        key={'lastName'}
                        name="lastName"
                        className={styles.textField}
                        value={userToEdit.lastName}
                        onChange={handleInput}
                        type="text"
                        required={true}
                        variant={'outlined'}
                        size={'small'}
                        disabled={userToEdit.isPatient}
                      />
                    </Box>
                  </Box>
                  <Box mb={3} className={styles.textFieldBox}>
                    <Box>
                      <Typography className={styles.inputTitle}>{t('email')}</Typography>
                      <Input
                        id={'email'}
                        label={''}
                        key={'email'}
                        name="email"
                        className={styles.textField}
                        value={userToEdit.email}
                        type="email"
                        onChange={handleInput}
                        required={true}
                        variant={'outlined'}
                        size={'small'}
                        disabled={true}
                      />
                    </Box>
                    <Box>
                      <Typography className={styles.inputTitle}>{t('center')}</Typography>
                      <Input
                        id={'center'}
                        label={''}
                        key={'center'}
                        name="center"
                        className={styles.textField}
                        value={'Centro demo'}
                        type="text"
                        onChange={handleInput}
                        required={true}
                        variant={'outlined'}
                        size={'small'}
                        disabled={true}
                      />
                    </Box>
                  </Box>
                  <Box mb={3} className={styles.textFieldBox}>
                    <Box>
                      <Typography className={styles.inputTitle}>{t('role')}</Typography>
                      <Input
                        id={'role'}
                        label={''}
                        key={'role'}
                        name="role"
                        className={styles.textField}
                        value={returnRole()}
                        type="text"
                        onChange={handleInput}
                        required={true}
                        variant={'outlined'}
                        size={'small'}
                        disabled={true}
                      />
                    </Box>
                  </Box>
                  <Box
                    mb={3}
                    style={{ maxWidth: openMenuLeft ? '100%' : '77%' }}
                    className={styles.headerContainer}
                  >
                    <Typography className={styles.headerTitle} sx={{ marginTop: 4 }}>
                      {t('changePass')}
                    </Typography>
                  </Box>
                  <Box mb={3} className={styles.textFieldBox}>
                    <Box>
                      <Typography className={styles.inputTitle}>{t('currentPassword')}</Typography>
                      <Input
                        id={'currentPass'}
                        label={''}
                        key={'currentPass'}
                        name="currentPass"
                        className={styles.textFieldPass}
                        type="password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        variant={'outlined'}
                        size={'small'}
                      />
                    </Box>
                  </Box>
                  <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Box mb={3} className={styles.textFieldBox}>
                      <Box>
                        <Typography className={styles.inputTitle}>{t('newPass')}</Typography>
                        <Input
                          id={'newPass'}
                          label={''}
                          key={'newPass'}
                          name="newPass"
                          className={styles.textFieldPass}
                          type="password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          variant={'outlined'}
                          size={'small'}
                        />
                      </Box>
                    </Box>
                    <Box mb={3} className={styles.textFieldBox}>
                      <Box>
                        <Typography className={styles.inputTitle}>{t('repeatNewPass')}</Typography>
                        <Input
                          id={'repeatNewPass'}
                          label={''}
                          key={'repeatNewPass'}
                          name="repeatNewPass"
                          value={repeatPassword}
                          className={styles.textFieldPass}
                          type="password"
                          onChange={(e) => setRepeatPassword(e.target.value)}
                          variant={'outlined'}
                          size={'small'}
                        />
                      </Box>
                    </Box>
                    <Box className={styles.buttonContainer} display="flex">
                      <Button className={styles.buttonSubmit} type={'submit'} onClick={() => {}}>
                        {t('save')}
                      </Button>
                      <Button
                        className={styles.buttonCancel}
                        type={'button'}
                        onClick={() => navigate(-1)}
                      >
                        {t('cancel')}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
              {message && (
                <Box mb={3}>
                  <Alert severity="success" key="message" id="message">
                    {t(message)}
                  </Alert>
                </Box>
              )}
              {errorMessage && (
                <Box mb={3}>
                  <Alert severity="error" key="errorMessage" id="errorMessage">
                    {t(errorMessage)}
                  </Alert>
                </Box>
              )}
            </form>
          ) : (
            <form onSubmit={saveData}>
              <Box bgcolor="white" m="1rem">
                <Box className={styles.headerContainer}>
                  <Header label={t('userData')} />
                </Box>
                <Box className={styles.textFieldBox}>
                  <Skeleton className={styles.textField} height={70} />
                  <Skeleton className={styles.textField} height={70} />
                </Box>
                <Box className={styles.textFieldBox}>
                  <Skeleton className={styles.textField} height={70} />
                  <Skeleton className={styles.textField} height={70} />
                </Box>
                <Box className={styles.textFieldBox}>
                  <Skeleton className={styles.textField} height={70} />
                </Box>
                <Box style={{ borderBottom: '3px solid  #a9ba37' }}>
                  <p className={styles.label}>{t('changePass')}</p>
                </Box>
                <Box className={styles.textFieldBox}>
                  <Skeleton className={styles.textField} height={70} />
                </Box>
                <Box className={styles.textFieldBox}>
                  <Skeleton className={styles.textField} height={70} />
                </Box>
                <Box className={styles.textFieldBox}>
                  <Skeleton className={styles.textField} height={70} />
                </Box>
              </Box>
              <Box className={styles.buttonContainer} display="flex">
                <AppButton
                  theme={ButtonTheme.NewSecondary}
                  type={'button'}
                  label={t('cancel')}
                  handler={() => goHome()}
                />
                <AppButton
                  theme={ButtonTheme.NewPrimary}
                  type={'submit'}
                  label={t('save')}
                  handler={() => {}}
                />
              </Box>
            </form>
          )}
        </Box>
      </Box>
    </div>
  )
}
