import { styled } from '@mui/material/styles'
import { Checkbox } from '@mui/material'

export const AppCheckbox = styled(Checkbox)({
  '&.MuiCheckbox-root': {
    color: '#E8E7EC',
    scale: '1.2',
  },
  '&.Mui-checked': {
    color: '#a9ba37',
  },
  '&:hover': {
    transform: 'scale(1.1)',
    transition: 'all 0.2s ease-in-out',
    color: 'black',
  },
})
