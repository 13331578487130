import React from 'react'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Header } from '../../components/header/Header'
import { TrainingDTO } from '../../modules/training/models/TrainingDTO'
import close from '../../assets/articles/delete.svg'
import styles from './ViewMedical.module.scss'
import style from '../calendar/Editor.module.css'

type TrainingView = {
  currentTraining: TrainingDTO
  currentImage?: string
  handleClose: () => void
}

export function ViewMedicalModal(props: TrainingView) {
  const { t } = useTranslation()
  const { currentTraining } = props

  return (
    <Box
      className={styles.container}
      sx={{ paddingTop: '1%', overflowY: 'auto', maxHeight: '100vh' }}
    >
      <Box className={styles.content} sx={{ height: '80%', marginBottom: '1%' }}>
        <Header label={t(currentTraining?.title) ?? ''} icon={close} onClick={props.handleClose} />
        <Box width={'80%'} mt={2}>
          <Box mb={2} style={{ display: 'flex', flexDirection: 'row' }}>
            <p className={style.label} style={{ marginBottom: 0, marginRight: 24 }}>
              {t('name')}
            </p>
            <p>{t(currentTraining.title)}</p>
          </Box>
          <Box mb={2}>
            <p className={style.label} style={{ marginBottom: 0, marginRight: 24 }}>
              {t('description')}
            </p>
            <p>{t(currentTraining.description)}</p>
          </Box>
          <Box>
            <img
              className={styles.trainingImage}
              src={`data:image/png;base64,${props.currentImage}`}
              style={{ width: '85%', height: 'auto', padding: '1%' }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
