import { useState, ChangeEvent, useEffect } from 'react'
import { IUserFormService, USER_FORM_SERVICE_KEY } from '../modules/forms'
import { getFormContainer } from '../container/form-module'
import { getTrainingContainer } from '../container/training-module'
import { IUserTrainingService, USER_TRAINING_SERVICE_KEY } from '../modules/training'
import { Query } from '../common/api/Query'
import { FORM_NAME } from '../features/users/constants'
import { UserFormWithTemplateName } from '../modules/forms/models/UserFormWithTemplateName'
const userFormService = getFormContainer().get<IUserFormService>(USER_FORM_SERVICE_KEY)
const userTrainingService =
  getTrainingContainer().get<IUserTrainingService>(USER_TRAINING_SERVICE_KEY)

export const useForms = (id?: string) => {
  const [selectedForms, setSelectedForms] = useState<Set<string>>(new Set())
  const [currentSelectedForm, setCurrentSelectedForm] = useState<string>()
  const [openFeedingModal, setOpenFeedingModal] = useState<boolean>(false)
  const [openConductModal, setOpenConductModal] = useState<boolean>(false)
  const [openTrainingModal, setOpenTrainingModal] = useState<boolean>(false)
  const [openSeeMedicationModal, setOpenSeeMedicationModal] = useState<boolean>(false)
  const [userForms, setUserForms] = useState<UserFormWithTemplateName[]>([])

  useEffect(() => {
    if (!id) return

    const fetchUserForms = userFormService.getWithTemplateNamesByUserID(id).toPromise()
    const fetchUserTrainings = userTrainingService
      .getListWithTrainingNameByUserID(
        new Query({
          query: [
            {
              name: 'userID',
              value: id || '',
            },
          ],
        })
      )
      .toPromise()

    Promise.all([fetchUserForms, fetchUserTrainings])
      .then(([formsRes, trainingsRes]) => {
        const newSelectedForms: Set<string> = new Set()
        setUserForms(formsRes.items)

        if (formsRes && formsRes.items) {
          const filledTemplateNames = formsRes.items
            .filter((form) => form.templateName && form.templateName.trim() !== '')
            .map((form) => form.templateName)
          filledTemplateNames.forEach((name) => newSelectedForms.add(name))
        }

        if (trainingsRes && trainingsRes.items) {
          trainingsRes.items.forEach((training) => {
            newSelectedForms.add(FORM_NAME.TRAINING)
          })
        }

        setSelectedForms(newSelectedForms)
      })
      .catch((error) => {
        console.error('Error fetching user data:', error)
      })
  }, [id])

  const handleSelectedForms = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setCurrentSelectedForm(value)
    if (value === 'Feeding Tracking') {
      handleOpenFeedingModal()
    } else if (value === 'Conduct') {
      handleOpenConductModal()
    } else if (value === 'Training') {
      handleOpenTrainingModal()
    }

    setSelectedForms((prevSelected) => {
      const newSelected = new Set(prevSelected)
      if (newSelected.has(value)) {
        newSelected.delete(value)
      } else {
        newSelected.add(value)
      }
      return newSelected
    })
  }

  const handleEditSelectedForms = (formType: string) => {
    setCurrentSelectedForm(formType)
    if (formType === 'Feeding Tracking') {
      handleOpenFeedingModal()
    } else if (formType === 'Conduct') {
      handleOpenConductModal()
    } else if (formType === 'Training') {
      handleOpenTrainingModal()
    }
  }

  const handleOpenFeedingModal = () => {
    setOpenFeedingModal(!openFeedingModal)
  }

  const handleOpenConductModal = () => {
    setOpenConductModal(!openConductModal)
  }

  const handleOpenTrainingModal = () => {
    setOpenTrainingModal(!openTrainingModal)
  }

  const handleOpenSeeMedicationModal = () => {
    setOpenSeeMedicationModal(!openSeeMedicationModal)
  }

  useEffect(() => {
    console.log('openTrainingModal', openTrainingModal)
    console.log('openFeedingModal', openFeedingModal)
    console.log('openConductModal', openConductModal)
  }, [openConductModal, openFeedingModal, openTrainingModal])

  return {
    selectedForms,
    openConductModal,
    openFeedingModal,
    openSeeMedicationModal,
    handleOpenConductModal,
    handleOpenFeedingModal,
    handleSelectedForms,
    handleEditSelectedForms,
    handleOpenSeeMedicationModal,
    openTrainingModal,
    handleOpenTrainingModal,
    userForms,
  }
}
