import i18n from '../../../i18n'

export enum FormFieldType {
  CheckBox = 1,
  Text = 2,
  TextArea = 3,
  Number = 4,
  RadioButton = 5,
  Select = 6,
  MultiSelect = 7,
  /*GridSelect =8,
  GridMultiSelect =9,*/
  Date = 10,
  Time = 11,
  DateTime = 12,
  FirstCourseRButton = 13,
  SecondCourseRButton = 14,
  DessertRButton = 15,
  FirstCourseSelect = 16,
  SecondCourseSelect = 17,
  DessertSelect = 18,
  /*  File = 13,*/
}

export const formFieldTypes = (): Record<FormFieldType, string> => ({
  [FormFieldType.CheckBox]: i18n.t('checkbox'),
  [FormFieldType.Text]: i18n.t('text'),
  [FormFieldType.TextArea]: i18n.t('textarea'),
  [FormFieldType.Number]: i18n.t('number'),
  [FormFieldType.RadioButton]: i18n.t('radiobutton'),
  [FormFieldType.Select]: i18n.t('selectButton'),
  [FormFieldType.MultiSelect]: i18n.t('multiselect'),
  [FormFieldType.Date]: i18n.t('date'),
  [FormFieldType.Time]: i18n.t('time'),
  [FormFieldType.DateTime]: i18n.t('datetime'),
  [FormFieldType.FirstCourseRButton]: i18n.t('firstCourse'),
  [FormFieldType.SecondCourseRButton]: i18n.t('secondCourse'),
  [FormFieldType.DessertRButton]: i18n.t('dessert'),
  [FormFieldType.FirstCourseSelect]: i18n.t('firstCourse'),
  [FormFieldType.SecondCourseSelect]: i18n.t('secondCourse'),
  [FormFieldType.DessertSelect]: i18n.t('dessert'),
  /*  [FormFieldType.File]: i18n.t('file'),*/
})
