export enum UnitType {
  mg = 0,
  ml = 1,
  mm,
  gr,
}

export const unitTypeTypes = (): Record<UnitType, string> => ({
  [UnitType.mg]: 'mg',
  [UnitType.ml]: 'ml',
  [UnitType.mm]: 'mm',
  [UnitType.gr]: 'gr',
})
