export type CircleProps = {
  id: string | undefined
  patientID: string
  familiars: string[] | undefined
}

export class Circle {
  private readonly _id: string | undefined
  private readonly _patientID: string
  private readonly _familiars: string[] | undefined

  constructor(p: CircleProps) {
    this._id = p.id
    this._patientID = p.patientID
    this._familiars = p.familiars
  }

  get id(): string | undefined {
    return this._id
  }

  get patientID(): string {
    return this._patientID
  }

  get familiars(): string[] | undefined {
    return this._familiars
  }
}

export interface CircleQuery {
  patientID?: string
}
