import React, { useEffect, useState } from 'react'
import { AppTable, Field } from '../../components/table'
import { Box, Button, Modal } from '@mui/material'
import { Actions, Pager, Search, SearchValue } from '../../components/table/types'
import seeIcon from '../../assets/table_icons/ico-ver.svg'
import { useTranslation } from 'react-i18next'
import { getTrainingContainer } from '../../container/training-module'
import { ITRainingService, TRAINING_SERVICE_KEY } from '../../modules/training'
import { Query, QueryParam, QueryParamN } from '../../common/api/Query'
import { ROUTE_TRAININGS } from '../../routes/routes-constants'
import { emptyTrainingDTO, TrainingDTO } from '../../modules/training/models/TrainingDTO'
import { getUserContainer } from '../../container/user-module'
import { UserService } from '../../modules/users/services/UserService'
import { LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { User, UserQuery } from '../../modules/users/models/User'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { reduceString } from '../../common/utils/strings'
import { useNavigate } from 'react-router-dom'
import genericStyle from '../../common/utils/generic.module.css'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import styles from '../treatments/AllTreatment.module.scss'
import { Pagination } from '../../components/table/Pagination'
import { CustomGeneralTable } from '../treatments/CustomGeneralTreatmentTable'
import newEvent from '../../assets/calendar/add.svg'
import searchIcon from '../../assets/user-table/searchIcon.svg'
import { Searcher } from '../../components/table/Searcher'
import { ViewMedicalModal } from './ViewMedical'
import { getMediaContainer } from '../../container/media-module'
import { MediaService } from '../../modules/media/services/MediaService'
import { MEDIA_SERVICE_KEY } from '../../modules/media'
import i18n from '../../i18n'

const userContainer = getUserContainer()
const userService = userContainer.get<UserService>(USER_SERVICE_KEY)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const trainingService = getTrainingContainer().get<ITRainingService>(TRAINING_SERVICE_KEY)
const mediaService = getMediaContainer().get<MediaService>(MEDIA_SERVICE_KEY)

type TableTrainingsProps = {
  id?: string
}

export function Table(props: TableTrainingsProps): JSX.Element {
  const { t } = useTranslation('translation')
  const loggedUser = loggedUserService.get()
  const navigate = useNavigate()
  const [user, setUser] = useState<User>()
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [count, setCount] = useState<number>(0)
  const [training, setTraining] = useState<TrainingDTO[]>([])
  const [exercisesModalOpened, setExercisesModalOpened] = useState<boolean>(false)
  const [currentExercise, setCurrentExercide] = useState<TrainingDTO>(emptyTrainingDTO())
  const [currentPhoto, setCurrentPhoto] = useState<TrainingDTO>()
  const [image, setImage] = useState<string>('')
  const innerWidth = window.innerWidth
  const { openMenuLeft } = useIsOpenMenuLeftContext()

  const [searcher, setSearcher] = useState<SearchValue<TrainingDTO>[]>([
    {
      name: 'title',
      label: t('search') + '...',
    },
  ])

  useEffect(() => {
    if (!loggedUser?.id) return
    setIsLoading(true)
    userService.getByID(loggedUser.id).subscribe((res) => {
      if (res) {
        setUser(res)
      }
      setIsLoading(false)
    })
  }, [loggedUser?.id])

  useEffect(() => {
    if (!currentExercise) return
    mediaService.getByName(currentExercise.title).subscribe((res) => {
      if (res) {
        setImage(res.data)
      }
    })
  }, [currentExercise])

  useEffect(() => {
    if (!isLoading) return
    trainingService
      .getFilteredList(
        new Query({
          pager: { offset: page * rowsPerPage, limit: rowsPerPage },
          query: [...searcherQuery(searcher)],
        })
      )
      .subscribe((res) => {
        if (res) {
          setTraining(res.items)
          setCount(res.count)
        }
        setIsLoading(false)
      })
  }, [isLoading, page, rowsPerPage, searcher])

  const handleChangePage = (event: unknown, value: number) => {
    setPage(value)
    setIsLoading(true)
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10)
    setRowsPerPage(isNaN(value) ? 10 : value)
    setPage(0)
    setIsLoading(true)
  }

  const search: Search<TrainingDTO> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<TrainingDTO>[]) => {
      setSearcher(svs)
      setIsLoading(true)
    },
  }

  useEffect(() => {
    setPager({
      page,
      count,
      handleChangePage,
      rowsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, rowsPerPage])

  const handlerSeeTraining = (tr: TrainingDTO) => {
    setCurrentExercide(tr)
    setExercisesModalOpened(true)
  }

  const fields: Field<TrainingDTO>[] = [
    {
      name: 'title',
      label: t('title'),
      renderFunc: (f, i) => <span>{t(i.title)}</span>,
    },
    {
      name: 'description',
      label: t('description'),
      renderFunc: (f, i) => <span>{reduceString(t(i.description), 50)}</span>,
    },
  ]

  const actions: Actions<TrainingDTO> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: handlerSeeTraining,
        icon: seeIcon,
        label: 'see',
      },
    ],
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: innerWidth > 900 ? (openMenuLeft ? '50px' : '') : '',
      }}
    >
      <Box
        style={{
          maxWidth:
            innerWidth > 900 ? (openMenuLeft ? 'calc(100% - 250px)' : 'calc(100% - 300px)') : '95%',
          position: 'relative',
        }}
        className={genericStyle.pageContainer}
      >
        <div style={{ height: '100%' }} className={genericStyle.container}>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            width={'100%'}
            alignItems={'center'}
          >
            <Box ml={8} width={'80%'}>
              <CustomGeneralTable
                openMenuLeft={openMenuLeft}
                innerWidth={innerWidth}
                isLoading={isLoading}
                items={training}
                fields={fields}
                actions={actions}
                rowKeyField={'id'}
              />

              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                width={'100%'}
              >
                <Box sx={{ marginLeft: openMenuLeft ? '35px' : '10px', marginTop: 2 }}>
                  {pager && <Pagination {...pager} />}
                </Box>
                <Box
                  display={'flex'}
                  alignItems={'center'}
                  mr={!openMenuLeft ? '28px' : '44px'}
                  mt={2}
                >
                  {/*<img*/}
                  {/*  style={{ marginRight: 20, marginBottom: 2 }}*/}
                  {/*  src={searchIcon}*/}
                  {/*  alt={'search training'}*/}
                  {/*/>*/}
                  {/*<Searcher search={search} />*/}
                </Box>
              </Box>
            </Box>
            <Box
              mr={3}
              width={'20%'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'center'}
              alignItems={'center'}
              height={'100%'}
            >
              <Box className={styles.boxTraining} style={{ alignItems: 'center' }}>
                <span className={styles.registeredMedicine} style={{ padding: '10px' }}>
                  {t('nExercises')}
                </span>
                <span className={styles.registeredMedicine}>{t('available')}</span>
                <span className={styles.countMedicine} style={{ alignSelf: 'center' }}>
                  {training.length}
                </span>
              </Box>
              <Box className={styles.newTreatmentButtonContainer}></Box>
            </Box>
          </Box>
        </div>
      </Box>
      <Modal open={exercisesModalOpened} onClose={() => setExercisesModalOpened(false)}>
        <ViewMedicalModal
          currentTraining={currentExercise}
          currentImage={image}
          handleClose={() => setExercisesModalOpened(false)}
        />
      </Modal>
    </div>
  )
}

const searcherQuery = (
  svs: SearchValue<TrainingDTO>[]
): QueryParam<TrainingDTO>[] | QueryParamN<TrainingDTO>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))
