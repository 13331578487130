import { styled } from '@mui/material/styles'
import { Tooltip, TooltipProps } from '@mui/material'
import { tooltipClasses } from '@mui/material/Tooltip'

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f9f9f9',
    color: '#a9ba37',
    fontSize: '1rem',
    fontFamily: 'Montserrat-Regular, sans-serif',
    boxShadow: theme.shadows[4],
    borderRadius: 32,
    marginBottom: 10,
    minWidth: 60,
    textAlign: 'center',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'red',
  },
  '&:hover': {
    transform: 'scale(1.1)',
    transition: 'all 0.2s ease-in-out',
    color: 'black',
  },
}))
