import { FormFieldType } from '../../modules/forms/enums/FormFieldType'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Checkbox,
  FormControl,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { FormControlLabel } from '@mui/material'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { FORM_SERVICE_KEY, USER_FORM_SERVICE_KEY } from '../../modules/forms'
import { FormService } from '../../modules/forms/services/FormService'
import { getFormContainer } from '../../container/form-module'
import { UserFormService } from '../../modules/forms/services/UserFormService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { FormCard } from '../../components/form-card/FormCard'
import genericStyle from '../../common/utils/generic.module.css'
import {
  emptyUserFormDTO,
  fromModel as userFormModel,
  UserFormDTO,
  UserFormValueDTO,
} from '../../modules/forms/models/UserFormDTO'
import { FormFieldDTO, fromModelFormField } from '../../modules/forms/models/FormDTO'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { ROUTE_PATIENT_FORMS } from '../../routes/routes-constants'
import { FileItem } from '../../components/form-card/FileItem'
import { File as F } from '../../modules/files/models/File'
import { TextFieldItem } from '../../components/form-card/TextFieldItem'
import { Alert } from '@mui/material'
import styles from './View.module.css'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { ICircleService } from '../../modules/users/services/CircleService'
import { CIRCLE_SERVICE_KEY } from '../../modules/users/container'
import { ReadingStatus } from '../../modules/forms/enums/ReadingStatus'
import { CustomModal } from '../../components/modal/CustomModal'
import style from '../generic/GenericTable.module.css'
import { LoadingSpinner } from '../../components/loading-spinner/LoadingSpinner'
import loaderStyles from '../../components/loading-spinner/LoadingSpinner.module.css'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import TextField from '@mui/material/TextField'
import { TimePicker } from '@mui/x-date-pickers'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { useNavigate } from 'react-router-dom'

type PatientFormViewProps = {
  id: string
  edit: boolean
}

const rows = 10
const loggedUserContainer = getUserContainer()
const loggedUserService = loggedUserContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const formContainer = getFormContainer()
const userFormService = formContainer.get<UserFormService>(USER_FORM_SERVICE_KEY)
const formService = formContainer.get<FormService>(FORM_SERVICE_KEY)
const fileContainer = getFileContainer()
const fileService = fileContainer.get<FileService>(FILE_SERVICE_KEY)

export function View(props: PatientFormViewProps) {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const circle = getUserContainer().get<ICircleService>(CIRCLE_SERVICE_KEY).getActive()
  const navigate = useNavigate()

  const [userForm, setUserForm] = useState<UserFormDTO>(
    emptyUserFormDTO(loggedUser?.id || '', circle?.id || '')
  )
  const [formFields, setFormFields] = useState<FormFieldDTO[]>([])
  const [saveAndSend, setSaveAndSend] = useState<boolean>(false)
  const [files, setFiles] = useState<Map<string, F[]>>(new Map())
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [formTitle, setFormTitle] = useState<string>('')
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openModalSend, setOpenModalSend] = useState<boolean>(false)
  const [formFieldNoAnswer, setFormFielNoAnswer] = useState<string>('')
  const [date, setDate] = useState<Date>(new Date())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [permanent, setPermanent] = useState<number>(0)
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false)

  useEffect(() => {
    if (props.id) {
      userFormService.getByID(props.id).subscribe((uf) => {
        if (uf) {
          setUserForm(userFormModel(uf))
          if (uf.formID) {
            formService.getByID(uf.formID).subscribe((f) => {
              if (f) {
                setFormFields(f.formFields.map((ff) => fromModelFormField(ff, f.id)))
                setFormTitle(f.title)
                setIsLoading(false)
              }
            })
          }
        }
      })
    }
    const interval = setInterval(() => {}, 500)

    return () => {
      clearInterval(interval)
    }
  }, [])

  /*  useEffect(() => {
    const fileFields = formFields.filter((ff) => ff.type === FormFieldType.File)
    fileFields.forEach((ff) => {
      const ufv = userForm.userFormValues.find((ufv) => ufv.formFieldID === ff.id)
      if (ufv && ufv.values && ufv.values.length > 0) {
        const newMap = new Map()
        fileService.getByID(ufv.values[0]).subscribe((file) => {
          if (file) {
            newMap.set(ff.id, [file])
            setFiles(new Map(newMap))
          }
        })
      }
    })
  }, [formFields])*/

  const handleChange = (formFieldID: string, values: string[]) => {
    if (Array.isArray(userForm?.userFormValues)) {
      let index = -1
      userForm.userFormValues.some((ufv, i) => {
        if (ufv.formFieldID === formFieldID) {
          index = i
          return true
        }
      })
      if (index > -1) {
        const ufv = userForm.userFormValues.slice()
        ufv[index] = {
          userFormID: userForm.id || '',
          formFieldID,
          values,
          date: date,
          done: true,
        }
        setUserForm(Object.assign({ ...userForm }, { userFormValues: ufv }))
      }
    }
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    handleChange(e.target.name, [e.target.value])
  }

  const handleDate = (e: any, formFieldID: string) => {
    if (e) {
      handleChange(formFieldID, [e.toDate().toDateString()])
    }
  }

  const handleTime = (e: any, formFieldID: string) => {
    if (e) {
      handleChange(formFieldID, [e.toDate().toISOString()])
    }
  }

  const handleSelect = (e: SelectChangeEvent<string[]>, formFieldID: string, multiple: boolean) => {
    handleChange(
      formFieldID,
      multiple ? (e.target.value as string[]).filter((o) => o !== '') : [e.target.value as string]
    )
  }

  const handleCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e.target.name, [e.target.checked ? 'true' : 'false'])
  }

  const handleRadioButton = (formFieldID: string, option: string) => {
    handleChange(formFieldID, [option])
  }

  const handleFiles = (formFieldID: string, value: F[]) => {
    const newMap = new Map(files)
    newMap.set(formFieldID, value)
    setFiles(newMap)
    handleChange(
      formFieldID,
      value.map((f) => f.id || '')
    )
  }

  const generateTextField = (
    formField: FormFieldDTO,
    values: string[] | undefined,
    multiline: boolean
  ) => {
    let textValue = ''
    if (values && values.length > 0) values.forEach((v) => (textValue = textValue + v))
    return (
      <Box mb={3}>
        <p>{formField.title}</p>
        <TextFieldItem
          key={formField.id}
          field={formField.id}
          type={formField.type === FormFieldType.Number ? 'number' : 'text'}
          label={''}
          rows={multiline ? rows : undefined}
          value={values && values.length > 0 ? textValue : ''}
          handleChange={handleInput}
          required={formField?.required}
          disabled={!props.edit}
        />
      </Box>
    )
  }

  const generateNoResponseField = (formField: FormFieldDTO, values: string[] | undefined) => {
    return (
      <Box mb={3}>
        <p>{formField.title}</p>
      </Box>
    )
  }

  const generateDateField = (formField: FormFieldDTO, values: string[] | undefined) => {
    let date = new Date()
    if (values && values.length > 0 && values[0] !== '') {
      date = new Date(values[0])
    }
    return (
      <Box mb={3}>
        <p>{formField.title}</p>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
          <DatePicker
            open={isDatePickerOpen}
            onOpen={() => setIsDatePickerOpen(true)}
            onClose={() => setIsDatePickerOpen(false)}
            key={formField.id}
            onError={(reason, value) => {
              switch (reason) {
                case 'invalidDate':
                  setDateTimePickerError(t('invalidDateMessage'))
                  break
                case 'minDate':
                  setDateTimePickerError(t('minDateMessage'))
                  break
              }
            }}
            slotProps={{
              textField: {
                size: 'small',
                required: formField.required,
                InputProps: {
                  sx: {
                    '.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input': {
                      fontFamily: 'Poppins',
                    },
                  },
                  onClick: () => setIsDatePickerOpen(true),
                },
              },
              popper: {
                sx: {
                  marginBottom: 8,
                  width: '220px',
                  '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                    fontFamily: 'Montserrat',
                    textTransform: 'capitalize',
                  },
                  '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                    fontFamily: 'Montserrat',
                  },
                  '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                    fontFamily: 'Montserrat',
                  },
                  '& .css-3eghsz-PrivatePickersYear-button': {
                    fontFamily: 'Montserrat',
                  },
                },
              },
            }}
            format="DD/MM/YYYY"
            onChange={(e) => handleDate(e, formField.id)}
            value={date}
            disabled={!props.edit}
          />
        </LocalizationProvider>
      </Box>
    )
  }

  const generateTimeField = (formField: FormFieldDTO, values: string[] | undefined) => {
    let date = new Date()
    if (values && values.length > 0 && values[0] !== '') {
      date = new Date(values[0])
    }
    return (
      <Box>
        <p>{formField.title}</p>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
          <TimePicker
            key={formField.id}
            className={styles.hourPicker}
            onError={(reason, value) => {
              switch (reason) {
                case 'invalidDate':
                  setDateTimePickerError(t('invalidDateMessage'))
                  break
              }
            }}
            slotProps={{
              textField: {
                size: 'small',
                required: formField.required,
                variant: 'standard',
                helperText: DateTimePickerError,
                InputProps: {
                  disableUnderline: true,
                  sx: {
                    marginBottom: 8,
                    '.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input': {
                      fontFamily: 'Poppins',
                    },
                  },
                  onClick: () => setIsDatePickerOpen(true),
                },
              },
              popper: {
                sx: {
                  '& .MuiButtonBase-root': {
                    fontFamily: 'Poppins',
                  },
                },
              },
            }}
            format="HH:mm"
            ampm={false}
            onChange={(e) => handleTime(e, formField.id)}
            value={date}
            disabled={!props.edit}
          />
        </LocalizationProvider>
      </Box>
    )
  }

  const generateDateTimeField = (formField: FormFieldDTO, values: string[] | undefined) => {
    let date = new Date()
    if (values && values.length > 0 && values[0] !== '') {
      date = new Date(values[0])
    }
    return (
      <Box>
        <p>{formField.title}</p>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
          <DateTimePicker
            key={formField.id}
            onError={(reason, value) => {
              switch (reason) {
                case 'invalidDate':
                  setDateTimePickerError(t('invalidDateMessage'))
                  break
                case 'minDate':
                  setDateTimePickerError(t('minDateMessage'))
                  break
              }
            }}
            slotProps={{
              textField: {
                size: 'small',
                required: formField.required,
                variant: 'standard',
                helperText: DateTimePickerError,
                InputProps: {
                  disableUnderline: true,
                  sx: {
                    marginBottom: 8,
                    width: '220px',
                    '.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input': {
                      fontFamily: 'Poppins',
                    },
                  },
                  onClick: () => setIsDatePickerOpen(true),
                },
              },
              popper: {
                sx: {
                  '& .css-dplwbx-MuiPickersCalendarHeader-label': {
                    fontFamily: 'Poppins',
                    textTransform: 'capitalize',
                  },
                  '& .css-bkrceb-MuiButtonBase-root-MuiPickersDay-root': {
                    fontFamily: 'Poppins',
                  },
                  '& .css-raiqh1-MuiTypography-root-MuiDayPicker-weekDayLabel': {
                    fontFamily: 'Poppins',
                  },
                  '& .css-3eghsz-PrivatePickersYear-button': {
                    fontFamily: 'Poppins',
                  },
                },
              },
            }}
            ampm={false}
            format="DD/MM/YYYY HH:mm"
            value={date}
            onChange={(e) => handleTime(e, formField.id)}
            label={''}
            disabled={!props.edit}
          />
        </LocalizationProvider>
      </Box>
    )
  }

  const generateSelectField = (
    formField: FormFieldDTO,
    values: string[] | undefined,
    multiple: boolean
  ) => {
    return (
      <Box mb={3}>
        <p>{formField.title}</p>
        <Select
          key={formField.id}
          id={formField.id}
          variant={'outlined'}
          value={values}
          onChange={(e) => handleSelect(e, formField.id, multiple)}
          className={styles.select}
          multiple={multiple}
          disabled={!props.edit}
          required={formField.required}
        >
          {formField.optionValues.map((k) => (
            <MenuItem key={k} value={k}>
              {k}
            </MenuItem>
          ))}
        </Select>
      </Box>
    )
  }

  const generateCheckboxField = (formField: FormFieldDTO, values: string[] | undefined) => {
    return (
      <Box mb={3}>
        <p className={styles.checkbox}>{formField.title}</p>
        <Checkbox
          name={formField.id}
          checked={values && values.length > 0 ? values[0] === 'true' : false}
          onChange={handleCheckbox}
          disabled={!props.edit}
        />
      </Box>
    )
  }

  const generateRadioButtonField = (formField: FormFieldDTO, values: string[] | undefined) => {
    const radioButtons = Array.isArray(formField.optionValues) ? formField.optionValues : []
    return (
      radioButtons.length > 0 && (
        <Box mb={3}>
          <FormControl component="fieldset">
            <p>{formField.title}</p>
            <RadioGroup name={formField.title}>
              {radioButtons.map((o, i) => (
                <FormControlLabel
                  key={`${o}-${i}`}
                  value={o}
                  control={
                    <Radio
                      required={formField.required}
                      checked={(Array.isArray(values) ? values[0] : values) === o}
                    />
                  }
                  label={<span style={{ fontFamily: 'Poppins' }}>{o}</span>}
                  onChange={() => handleRadioButton(formField.id, o)}
                  disabled={!props.edit}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Box>
      )
    )
  }

  const generateFileField = (formField: FormFieldDTO) => {
    const fl = files.get(formField.id)
    return (
      <Box mb={3} key={formField.id + 'box'}>
        <p>{formField.title}</p>
        <FileItem
          key={formField.id}
          field={formField.id}
          filesID={[]}
          handleFiles={(_, f) => handleFiles(formField.id, f)}
          userID={loggedUser?.id || ''}
          cleanFiles={false}
          uploadRemoveFileDirectly={true}
          disabled={!props.edit || (fl ? fl.length > 0 : false)}
          defaultFile={fl || []}
        />
      </Box>
    )
  }

  const generateField = (formField: FormFieldDTO, userFormValue: UserFormValueDTO) => {
    switch (formField.type) {
      case FormFieldType.Text:
      case FormFieldType.TextArea:
      case FormFieldType.Number:
        const multiline = formField.type === FormFieldType.TextArea
        return generateTextField(formField, userFormValue.values, multiline)

      case FormFieldType.Date:
        return generateDateField(formField, userFormValue.values)

      case FormFieldType.Select:
      case FormFieldType.MultiSelect:
        const multiple = formField.type === FormFieldType.MultiSelect
        return generateSelectField(formField, userFormValue.values, multiple)

      case FormFieldType.CheckBox:
        return generateCheckboxField(formField, userFormValue.values)

      case FormFieldType.RadioButton:
        return generateRadioButtonField(formField, userFormValue.values)

      /*    case FormFieldType.File:
         return generateFileField(formField)*/

      case FormFieldType.Time:
        return generateTimeField(formField, userFormValue.values)

      case FormFieldType.DateTime:
        return generateDateTimeField(formField, userFormValue.values)
    }
  }

  const validateUserForm = (uf: UserFormDTO): boolean => {
    if (!saveAndSend) {
      return true
    }

    const fieldsRequired = formFields.filter((ff) => ff.required)
    const fieldsToValidate = fieldsRequired.filter((ff) =>
      uf.userFormValues.find(
        (ufv) =>
          ufv.formFieldID === ff.id &&
          ufv.values &&
          ((ufv.values.length > 0 && ufv.values[0] === '') || ufv.values.length === 0)
      )
    )

    if (fieldsToValidate.length > 0) {
      setErrorMessage(`${t('requiredFields')}: ${fieldsToValidate.map((f) => f.title).join(', ')}`)
      return false
    }

    setErrorMessage('')
    return true
  }

  const goBack = () => navigate(ROUTE_PATIENT_FORMS)

  const saveOrSendForm = () => {
    const values = userForm.userFormValues.map((ufv) => {
      const ff = formFields.find((ff) => ff.id === ufv.formFieldID)
      if (ff) {
        if (ufv.values && ufv.values.length > 0 && ufv.values[0] === '') {
          const newUserFormValue = Object.assign({ ...ufv }, { ...ufv }, { done: true })
          switch (ff.type) {
            case FormFieldType.Date:
              newUserFormValue.values = [new Date().toDateString()]
              break
            case FormFieldType.CheckBox:
              newUserFormValue.values = ['false']
              break
          }
          return newUserFormValue
        }
      }
      return ufv
    })

    const newUserForm = Object.assign(
      { ...userForm },
      {
        userFormValues: values,
        readingStatus: saveAndSend ? ReadingStatus.Completed : ReadingStatus.Pending,
      }
    )

    if (!validateUserForm(newUserForm)) {
      return
    }

    userFormService.update(newUserForm).subscribe()

    const userFormTemp: UserFormDTO = {
      id: userForm?.id || '',
      userID: userForm?.userID || '',
      circleID: userForm?.circleID || '',
      formID: userForm?.formID || '',
      userFormValues: [],
      readingStatus: userForm.readingStatus,
      typeForm: userForm.typeForm,
    }

    if (permanent === 1) {
      newUserForm.userFormValues.map((v: UserFormValueDTO) => {
        userFormTemp.userFormValues.push(v)
      })
    } else {
      newUserForm.userFormValues.map((v: UserFormValueDTO) => {
        userFormService.updateValue(v).subscribe()
      })
    }

    goBack()
  }

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const handleCloseModalSend = () => {
    setOpenModalSend(false)
  }

  const handleSaveModal = () => {
    setOpenModal(false)
    saveOrSendForm()
  }

  const handleSaveModalSend = () => {
    setOpenModalSend(false)
    saveOrSendForm()
  }

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    //e.preventDefault()
    saveOrSendForm()
  }

  return (
    <Box className={genericStyle.pageContainer}>
      {!isLoading ? (
        <>
          <h2>{formTitle}</h2>
          <FormCard>
            <form onSubmit={handleSave}>
              {userForm.userFormValues.map((ufv, index) => {
                const ff = formFields.find((ff) => ff.order === index + 1)
                return ff && generateField(ff, ufv)
              })}
              {formFieldNoAnswer !== '' && (
                <p style={{ marginBottom: '2%' }}>{formFieldNoAnswer}</p>
              )}
              {errorMessage && (
                <Box mb={3}>
                  <Alert severity="warning" key="errorMessage" id="errorMessage">
                    {errorMessage}
                  </Alert>
                </Box>
              )}
              <Box display="flex" justifyContent="space-between">
                <AppButton
                  theme={ButtonTheme.NewSecondary}
                  type={'button'}
                  label={props.edit ? t('cancel') : t('back')}
                  handler={goBack}
                />
                {props.edit && !permanent && (
                  <AppButton
                    theme={ButtonTheme.NewPrimary}
                    label={t('save')}
                    type={'button'}
                    handler={(e) => {
                      e.preventDefault()
                      setSaveAndSend(false)
                      setOpenModal(true)
                    }}
                  />
                )}
                {props.edit && (
                  <AppButton
                    theme={ButtonTheme.NewPrimary}
                    label={t('send')}
                    type={'submit'}
                    handler={(e) => {
                      e.preventDefault()
                      setSaveAndSend(true)
                      setOpenModalSend(true)
                    }}
                  />
                )}
              </Box>
            </form>
          </FormCard>
          <>
            <Modal open={openModal} className={style.eventModal} onClose={handleCloseModal}>
              <CustomModal
                handleClose={handleCloseModal}
                handleSave={handleSaveModal}
                title={t('saveForm')}
                warningText={t('saveFormText')}
              />
            </Modal>
            <Modal open={openModalSend} className={style.eventModal} onClose={handleCloseModalSend}>
              <CustomModal
                handleClose={handleCloseModalSend}
                handleSave={handleSaveModalSend}
                title="Enviar"
                warningText={t('sendCustomForm')}
              />
            </Modal>
          </>
        </>
      ) : (
        <LoadingSpinner className={loaderStyles.loadingSpinner} />
      )}
    </Box>
  )
}
