import React, { useEffect, useState } from 'react'
import { getUserContainer } from '../../container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { emptyUserDTO, UserDTO } from '../../modules/users/models/User'
import { Avatar, Modal } from '@mui/material'
import style from './AvatarProfile.module.css'
import AvatarImage from '../../assets/patient_data/avatar.svg'
import { useTranslation } from 'react-i18next'
import { ModalUpdatePhoto } from './modalUpdatePhoto'
import { getFileContainer } from '../../container/file-module'
import { FILE_SERVICE_KEY, IFileService } from '../../modules/files'
import { fromModel } from '../../modules/files/models/FileDTO'
import { useProfilePhotoGlobalContext } from '../../common/utils/ProfilePhotoContext'

const userContainer = getUserContainer()
const fileContainer = getFileContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = userContainer.get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const fileService = fileContainer.get<IFileService>(FILE_SERVICE_KEY)

const AvatarProfile = () => {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()

  const [userToEdit, setUserToEdit] = useState<UserDTO>(emptyUserDTO())
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { profilePhoto, setProfilePhoto } = useProfilePhotoGlobalContext()

  useEffect(() => {
    userService.getByID(loggedUser?.id || '').subscribe((res) => res && setUserToEdit(res.toDTO()))
    fileService.getProfilePhotoByUserID(loggedUser?.id || '').subscribe((res) => {
      res && setProfilePhoto(fromModel(res))
    })
  }, [])

  return (
    <div className={style.container}>
      <div className={style.avatarContainer}>
        <div className={style.profilepic} onClick={handleOpen}>
          <Avatar
            style={{ width: '100%!important', height: '100%!important' }}
            sx={{
              width: '10rem',
              height: '10rem',
              marginRight: 1,
              objectFit: 'cover',
              '& img': { objectFit: 'scale-down' },
            }}
            className={style.profilepic__image}
            alt="Remy Sharp"
            src={
              profilePhoto.data.length > 0
                ? `data:image/jpg;base64, ${profilePhoto.data}`
                : AvatarImage
            }
          />
          <div className={style.profilepic__content}>
            <span className={style.profilepic__icon}>
              <i className="fas fa-camera"></i>
            </span>
            <span className={style.profilepic__text}>{t('editPhoto')}</span>
          </div>
        </div>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={style.modal}>
          <ModalUpdatePhoto handleClose={handleClose} />
        </div>
      </Modal>
    </div>
  )
}

export default AvatarProfile
