import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import ChatList from './ChatList'
import { useTranslation } from 'react-i18next'
import Avatar1 from '../../assets/new-right-menu/profile.svg'
import messages from '../../assets/new-right-menu/email.svg'
import setting from '../../assets/new-right-menu/settings.svg'
import exitIcon from '../../assets/new-right-menu/onOff.svg'
import { ROUTE_LOGIN, ROUTE_MESSENGER, ROUTE_USER_PROFILE } from '../../routes/routes-constants'
import { getUserContainer } from 'container/user-module'
import { ILoggedUserService } from '../../modules/users/services/LoggedUserService'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import styles from '../header/Header.module.css'
import { User } from '../../modules/users/models/User'
import { Avatar } from '@mui/material'
import style from '../avatar-profile/AvatarProfile.module.css'
import { getFileContainer } from '../../container/file-module'
import { FileService } from '../../modules/files/services/FileService'
import { FILE_SERVICE_KEY } from '../../modules/files'
import { emptyFileDTO } from '../../modules/files/models/FileDTO'
import { useProfilePhotoGlobalContext } from '../../common/utils/ProfilePhotoContext'
import { cloneDeep } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import { useNavigate } from 'react-router-dom'

const filesContainer = getFileContainer()
const fileService = filesContainer.get<FileService>(FILE_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
type CustomAppBarProps = {
  onChange?: () => void
}

type NotificationGotify = {
  id: string
  title: string
  message: string
  createAd: Date
}

const NavRight = (props: CustomAppBarProps) => {
  const { t } = useTranslation()
  const [listOpen, setListOpen] = useState<boolean>(false)
  const [user, setUser] = useState<User>()
  const { profilePhoto, setProfilePhoto } = useProfilePhotoGlobalContext()
  const [socket, setWebsocket] = useState<WebSocket>()
  const [notifications, setNotifications] = useState<NotificationGotify[]>([])
  const [count, setCount] = useState<number>(0)
  const navigate = useNavigate()

  const loggedUser = loggedUserService.get()

  /*useEffect(() => {
    if (!socket) {
      setWebsocket(
          new WebSocket(
              `ws://backend-gotify.visualtis.com/stream?token=CXwS_5hqEgJV1xZ`
          )
      )
    }
  }, [])*/
  useEffect(() => {
    const ws = new WebSocket(`ws://localhost/stream?token=CCyYe9JUcw0aFq7`)
    ws.onopen = () => {
      console.log('Successfully Connected')
    }
    ws.onmessage = (msg) => {
      if (notifications) {
        let m = JSON.parse(msg.data)
        const notification = cloneDeep<NotificationGotify[]>(notifications)
        let mAux = notifications
        mAux.push({
          id: uuidv4(),
          title: m.title,
          message: m.message,
          createAd: m.createAd,
        })

        setNotifications(mAux)
      }
    }
    ws.onclose = (event) => {
      console.log('Socket Closed Connection: ', event)
    }
    ws.onerror = (error) => {
      console.log('Socket Error: ', error)
    }
    return () => {
      ws.close()
    }
  }, [])

  /*let connect = () => {
    //console.log('connecting')

    if (socket) {
      socket.onopen = () => {
        // console.log('Successfully Connected')
      }
    }

    if (socket) {
      socket.onmessage = (msg) => {
        if (notifications) {
          let m = JSON.parse(msg.data)
          const notification = cloneDeep<NotificationGotify[]>(notifications)
          let mAux = notifications
          mAux.push(
             {
                id: uuidv4(),
                title: m.title,
                message: m.message,
                createAd: m.createAd
              }
          )
          console.log('notification', notification)

          setNotifications(mAux)

        }
      }
    }

    if (socket) {
      socket.onclose = (event) => {
        console.log('Socket Closed Connection: ')
      }
    }

    if (socket) {
      socket.onerror = (error) => {
        console.log('Socket Error: ', error)
      }
    }
  }

  useEffect(() => {
    if (socket) {
      connect()
    }
  }, [socket])*/

  useEffect(() => {
    if (!loggedUser?.id) return
    //cargamos la foto de perfil
    fileService.getProfilePhotoByUserID(loggedUser.id).subscribe((res) => {
      setProfilePhoto(res || emptyFileDTO(''))
    })

    userService.getByID(loggedUser?.id).subscribe((res) => {
      setUser(res)
    })
  }, [])

  const handleSetMenuSelected = (menuSelected: string) => {
    navigate(menuSelected)
  }

  const handleLogout = () => {
    loggedUserService.logout()
    navigate(ROUTE_LOGIN)
    props.onChange && props.onChange()
  }

  const [fade, setFade] = useState(false)

  const triggerFade = () => {
    setFade((prevState) => {
      return !prevState
    })
  }

  return (
    <>
      {/*<Dropdown className={styles.navRightContainer} style={{ marginRight: '-1.5%' }}>
        <Dropdown.Toggle variant="link" id="dropdown-basic" style={{ color: 'black' }}>
          <i className="icon feather icon-bell" />
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight className="notification">
          <div className={styles.notiHead}>
            <h6 className="d-inline-block m-b-0">Notifications</h6>
            <div className="float-right">
              <a className="m-r-10">mark as read</a>
              <a className={styles.clearAll}>clear all</a>
            </div>
          </div>
          <ul className="noti-body">
            <li className="n-title">
              <p className="m-b-0">NEW</p>
            </li>
            <li className="notification">
              <div className="media">
                <img className="img-radius" src={Avatar1} alt="Generic placeholder" />
                <div className="media-body">
                  <p>
                    <strong>John Doe</strong>
                    <span className="n-time text-muted">
                      <i className="icon feather icon-clock m-r-10" />
                      30 min
                    </span>
                  </p>
                  <p>New ticket Added</p>
                </div>
              </div>
            </li>
            <li className="n-title">
              <p className="m-b-0">EARLIER</p>
            </li>
            <li className="notification">
              <div className="media">
                <img className="img-radius" src={Avatar2} alt="Generic placeholder" />
                <div className="media-body">
                  <p>
                    <strong>Joseph William</strong>
                    <span className="n-time text-muted">
                      <i className="icon feather icon-clock m-r-10" />
                      30 min
                    </span>
                  </p>
                  <p>Prchace New Theme and make payment</p>
                </div>
              </div>
            </li>
            <li className="notification">
              <div className="media">
                <img className="img-radius" src={Avatar3} alt="Generic placeholder" />
                <div className="media-body">
                  <p>
                    <strong>Sara Soudein</strong>
                    <span className="n-time text-muted">
                      <i className="icon feather icon-clock m-r-10" />
                      30 min
                    </span>
                  </p>
                  <p>currently login</p>
                </div>
              </div>
            </li>
          </ul>
          <div className="noti-footer">
            <a>show all</a>
          </div>
        </Dropdown.Menu>
      </Dropdown>*/}
      {/*<Dropdown className={styles.navRightContainer} style={{ marginRight: '-1.5%' }}>
        <Dropdown.Toggle
          variant="link"
          className="displayChatbox"
          onClick={() => setListOpen(true)}>
          <i className="icon feather icon-mail" style={{ color: 'black' }} />
        </Dropdown.Toggle>
      </Dropdown>*/}

      <Dropdown
        bsPrefix="vertical-dropdown"
        className={styles.navRightContainer}
        style={{ marginRight: '-1%' }}
      >
        <div>
          <img
            src={messages}
            alt="messages"
            className={styles.iconMenu}
            onClick={() => handleSetMenuSelected(ROUTE_MESSENGER)}
          />
        </div>
        <Dropdown.Toggle
          variant="link"
          id="dropdown-basic"
          style={{
            color: 'transparent',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: 0,
          }}
        >
          {/*         <i className="icon feather icon-settings" style={{ color: 'black' }} />*/}
          <Avatar
            style={{ width: '100%!important', height: '100%!important' }}
            sx={{ width: '3rem', height: '3rem', marginRight: 1, objectFit: 'cover' }}
            className={style.profilepic__image}
            alt="Remy Sharp"
            src={
              profilePhoto.data.length > 0 ? `data:image/jpg;base64, ${profilePhoto.data}` : Avatar1
            }
          />
        </Dropdown.Toggle>
        <Dropdown.Menu className={styles.profileNotification}>
          <ul className="pro-body">
            {/*{user && user.isPatient ? (
              ''
            ) : (
              <li style={{ cursor: 'pointer' }}>
                <a className="dropdown-item">
                  <i className="feather icon-settings" /> {t('config')}
                </a>
              </li>
            )}*/}
            <li style={{ cursor: 'pointer' }}>
              <a
                onClick={() => {
                  handleSetMenuSelected(ROUTE_USER_PROFILE)
                }}
                className="dropdown-item"
              >
                <img style={{ marginRight: 8 }} src={setting} alt={'profile'} />{' '}
                <span style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 15 }}>
                  {t('profile')}
                </span>
              </a>
            </li>
            {/*
            <li style={{ cursor: 'pointer' }}>
              <a
                onClick={() => {
                  handleSetMenuSelected(ROUTE_MESSENGER)
                }}
                className="dropdown-item"
              >
                <i className="feather icon-mail" /> {t('messages')}
              </a>
            </li>*/}
            <li style={{ cursor: 'pointer' }}>
              <a onClick={handleLogout} className="dropdown-item">
                <img style={{ marginRight: 16 }} src={exitIcon} alt={'exit'} />{' '}
                <span style={{ fontFamily: 'Montserrat-SemiBold', fontSize: 15 }}>{t('exit')}</span>
              </a>
            </li>
          </ul>
        </Dropdown.Menu>
      </Dropdown>
      <ChatList listOpen={listOpen} closed={() => setListOpen(false)} />
    </>
  )
}

export default NavRight
