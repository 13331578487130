import React, { ChangeEvent, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Input } from '../../../pages/user-profile/userProfileStylesMui'
import dayjs from 'dayjs'
import { UserDTO } from '../../../modules/users/models/User'
import calendarIcon from '../../../assets/calendar/calendar-newEvent.svg'
import styles from '../CreatePatientView.module.scss'
import { useTranslation } from 'react-i18next'

interface PatientFormProps {
  patient: UserDTO
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handleDateChange: (date: Date) => void
}

const PatientForm: React.FC<PatientFormProps> = ({ patient, handleChange, handleDateChange }) => {
  const { t } = useTranslation()
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false)
  const [DateTimePickerError, setDateTimePickerError] = useState<string>('')

  return (
    <Box id={'Patient Form'}>
      <Box style={{ maxWidth: '100%' }} className={styles.headerContainer}>
        <Typography className={styles.headerTitle}>{t('userData')}</Typography>
      </Box>
      <Box mb={1} className={styles.textFieldBox}>
        <Box marginRight={'20px'} width={'50%'}>
          <Typography className={styles.inputTitle}>{t('name')}</Typography>
          <Input
            id={'firstName'}
            label=""
            key={'firstName'}
            name="firstName"
            className={styles.textField}
            value={patient.firstName}
            onChange={handleChange}
            type="text"
            required={true}
            variant={'outlined'}
            size={'small'}
          />
        </Box>
        <Box marginRight={'20px'} width={'50%'}>
          <Typography className={styles.inputTitle}>{t('lastName')}</Typography>
          <Input
            id={'lastName'}
            label={''}
            key={'lastName'}
            name="lastName"
            className={styles.textField}
            value={patient.lastName}
            onChange={handleChange}
            type="text"
            required={true}
            variant={'outlined'}
            size={'small'}
          />
        </Box>
      </Box>
      <Box mb={1} className={styles.textFieldBox}>
        <Box marginRight={'20px'} width={'50%'}>
          <Typography className={styles.inputTitle}>{t('birthDate')}</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'es'}>
            <DatePicker
              sx={{ width: '100%' }}
              key={'dob'}
              value={patient ? dayjs(patient.dob) : dayjs(new Date())}
              open={isDatePickerOpen}
              onOpen={() => setIsDatePickerOpen(true)}
              onClose={() => setIsDatePickerOpen(false)}
              format={'DD/MM/YYYY'}
              onChange={(e) => {
                handleDateChange(e as unknown as Date)
              }}
              label={''}
              onError={(reason) => {
                switch (reason) {
                  case 'invalidDate':
                    setDateTimePickerError(t('invalidDateMessage'))
                    break
                  case 'minDate':
                    setDateTimePickerError(t('minDateMessage'))
                    break
                }
              }}
              slotProps={{
                textField: {
                  id: 'dob',
                  size: 'small',
                  InputProps: {
                    sx: {
                      width: '100%',
                      marginRight: '20px',
                      '& fieldset': {
                        borderRadius: 32,
                        border: '1px solid #E8E7EC',
                        fontFamily: 'Montserrat-Regular',
                      },
                      '&.MuiInputBase-root': { fontFamily: 'Montserrat-Regular' },
                      '&.MuiOutlinedInput-root': {
                        border: 'none',
                        '&.Mui-focused fieldset': {
                          borderColor: '#ADB84E',
                        },
                      },
                    },
                    onClick: () => setIsDatePickerOpen(true),
                  },
                },
              }}
              slots={{
                openPickerIcon: CalendarIcon,
              }}
            />
          </LocalizationProvider>
          {DateTimePickerError && (
            <Typography className={styles.errorText}>{DateTimePickerError}</Typography>
          )}
        </Box>
        <Box marginRight={'20px'} width={'50%'}>
          <Typography className={styles.inputTitle}>{t('role')}</Typography>
          <Typography className={styles.typoText} sx={{ paddingLeft: '14px' }}>
            {t('patient')}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

const CalendarIcon = () => <img src={calendarIcon} alt="Calendar" />

export default PatientForm
