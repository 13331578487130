import { UnitType } from '../enums/UnitType'

export type TreatmentProps = {
  id: string | undefined
  name: string
  unit: UnitType
  creatorID: string
}

export class Treatment {
  private readonly _id: string | undefined
  private readonly _name: string
  private readonly _unit: UnitType
  private readonly _creatorID: string

  constructor(p: TreatmentProps) {
    this._id = p.id
    this._name = p.name
    this._unit = p.unit
    this._creatorID = p.creatorID
  }

  get id(): string | undefined {
    return this._id
  }

  get name(): string {
    return this._name
  }

  get unit(): UnitType {
    return this._unit
  }

  get creatorID(): string {
    return this._creatorID
  }
}

export interface TreatmentQuery {
  name: string
}
