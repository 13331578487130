import React, { useEffect, useState } from 'react'
import { User } from '../modules/users/models/User'
import { getCircleContainer } from '../container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from '../modules/circle'
import { getUserContainer } from '../container/user-module'
import { IUserService, USER_SERVICE_KEY } from '../modules/users'
import { useParams } from 'react-router-dom'

export type Familiar = {
  id: string
  firstName: string
  lastName: string
  email: string
  phone: string
  role: string
  notification: string[]
}

const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)
const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)

export const useFamiliars = (initialFamiliars: Familiar[]) => {
  const { id } = useParams()

  const [selectedFamiliars, setSelectedFamiliars] = useState<Set<number>>(new Set())
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [isCheck, setIsCheck] = useState(new Array(4).fill(false))
  const [familiars, setFamiliars] = useState<Familiar[]>(initialFamiliars)
  const [isSave, setIsSave] = useState(false)

  const handleSelectFamiliar = (index: number) => {
    setSelectedFamiliars((prevSelected) => {
      const newSelected = new Set(prevSelected)
      if (newSelected.has(index)) {
        newSelected.delete(index)
      } else {
        newSelected.add(index)
      }
      return newSelected
    })
  }

  const handleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckAll(e.target.checked)
    setIsCheck(new Array(4).fill(e.target.checked))
    setFamiliars((prevState) => ({
      ...prevState,
      notification: e.target.checked ? ['food', 'medication', 'conduct', 'training'] : [],
    }))
  }

  const handleClickCheckbox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target
    const index = ['food', 'medication', 'conduct', 'training'].indexOf(name)
    const updatedCheck = [...isCheck]
    updatedCheck[index] = checked
    setIsCheck(updatedCheck)
    setIsCheckAll(updatedCheck.every(Boolean))
    /*
        setFamiliar((prevState) => {
            if (checked) {
                return { ...prevState, notification: [...prevState.notification, name] }
            } else {
                return {
                    ...prevState,
                    notification: prevState.notification.filter((notification) => notification !== name),
                }
            }
        })*/
  }

  const checkEmailFamiliar = (email: string) => {
    if (familiars.length > 0) {
      const emailFamiliars = familiars.map((familiar) => familiar.email)
      if (emailFamiliars.includes(email)) {
        setIsSave(true)
        return true
      }
    }

    setIsSave(false)
    return false
  }

  const handleRemoveFamiliar = () => {
    setFamiliars((prevFamiliars) =>
      prevFamiliars.filter((_, index) => !selectedFamiliars.has(index))
    )
    setSelectedFamiliars(new Set())
  }

  return {
    familiars,
    selectedFamiliars,
    isSave,
    isCheck,
    isCheckAll,
    handleSelectFamiliar,
    setSelectedFamiliars,
    handleClickCheckbox,
    handleSelectAll,
    handleRemoveFamiliar,
    checkEmailFamiliar,
  }
}
