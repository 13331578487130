import { UserTrainingDTO } from './UserTrainingDTO'
import { UserTrainingWithTrainingNameDTO } from './UserTrainingWithTrainingNameDTO'

export class UserTrainingWithTrainingName {
  private _userTraining: UserTrainingDTO
  private _title: string

  constructor(p: UserTrainingWithTrainingNameDTO) {
    this._userTraining = p.userTraining
    this._title = p.title
  }

  get userTraining(): UserTrainingDTO {
    return this._userTraining
  }

  get title(): string {
    return this._title
  }
}
