import { getUserContainer } from '../../container/user-module'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { Box, Modal, Tooltip } from '@mui/material'
import { AppTable, Field } from '../../components/table'
import { fromModel, User, UserQuery } from '../../modules/users/models/User'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import { BoolQueryParam, Query, QueryParam, QueryParamN } from '../../common/api/Query'
import { Actions, Pager, Search, SearchValue } from '../../components/table/types'
import genericStyle from '../../common/utils/generic.module.css'
import iconView from '../../assets/user-table/view.svg'
import treatmentView from '../../assets/user-table/treatment.svg'
import iconDelete from '../../assets/user-table/delete.svg'
// import iconChat from '../../assets/user-table/chat.svg'
import calendarIcon from '../../assets/user-table/calenar.svg'
import addPatientIcon from '../../assets/user-table/addPatient.svg'
import searchIcon from '../../assets/user-table/searchIcon.svg'

import {
  ROUTE_CALENDAR_EXERCISES,
  ROUTE_DASHBOARD,
  ROUTE_MESSENGER,
  ROUTE_STATISTICS,
  ROUTE_TREATMENTS,
  ROUTE_USERS_CREATE,
} from '../../routes/routes-constants'
import { UserGender } from '../../modules/users/enums/UserGender'
import editIcon from '../../assets/user-table/edit.svg'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { CustomModal } from '../../components/modal/CustomModal'
import { useSnackbar } from 'notistack'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import { Pagination } from '../../components/table/Pagination'
import { Searcher } from '../../components/table/Searcher'
import { useNavigate } from 'react-router-dom'
import { getCircleContainer } from '../../container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from '../../modules/circle'

const userContainer = getUserContainer()
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

export function Table() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [users, setUsers] = useState<User[]>([])
  const [count, setCount] = useState<number>(0)
  const [doctors, setDoctors] = useState<User[]>([])
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [rowsPerPage, setRowsPerPage] = useState<number>(10)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [currentUser, setCurrentUser] = useState<User>()
  const { openMenuLeft } = useIsOpenMenuLeftContext()
  const { enqueueSnackbar } = useSnackbar()
  const innerWidth = window.innerWidth

  const [searcher, setSearcher] = useState<SearchValue<UserQuery>[]>([
    {
      name: 'firstName',
      label: t('search') + '...',
    },
  ])

  useEffect(() => {
    // Esta función se ejecutará cada vez que cambie `openMenuLeft`
    console.log('componente renderizado')
  }, [openMenuLeft])

  useEffect(() => {
    if (!isLoading) {
      console.log('no esta loading')
      return
    }
    userService
      .getFilteredList(
        new Query({
          query: [
            {
              name: 'isPatient',
              value: new BoolQueryParam(false),
            },
            ...searcherQuery(searcher),
          ],
          pager: { offset: page * rowsPerPage, limit: rowsPerPage },
        })
      )
      .subscribe((res) => {
        console.log('respuesta', res)
        setUsers(res.items)
        setCount(res.count)
        setIsLoading(false)
      })
  }, [isLoading])

  useEffect(() => {
    if (!users.length) {
      return
    }

    const ids = users.map((u) => u.assignedID)
    userService
      .getFilteredList(
        new Query({
          pager: { offset: 0, limit: ids.length },
          query: [
            {
              name: 'ids',
              value: ids,
            },
          ],
        })
      )
      .subscribe((res) => {
        setDoctors(res.items)
        setIsLoading(false)
      })
  }, [users])

  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage,
      rowsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, rowsPerPage])

  useEffect(() => {
    setIsLoading(true)
  }, [searcher])

  const handleChangePage = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setRowsPerPage(10)
      return
    }
    setRowsPerPage(Number.parseInt(event.target.value))
  }

  const removeUser = (u: User) => {
    setCurrentUser(u)
    setOpenDeleteModal(true)
  }

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false)
  }
  const handleDeleteUser = () => {
    if (!currentUser) return
    userService.updateUserToInactiveByID(currentUser.id).subscribe((res) => {
      setIsLoading(!isLoading)
      setOpenDeleteModal(false)
      enqueueSnackbar(t('userUnassigned'), { variant: 'success' })
    })
  }

  const handlerAddUser = () => navigate(ROUTE_USERS_CREATE)

  const fields: Field<User>[] = [
    {
      name: 'firstName',
      label: t('fullName'),
      renderFunc: (f, i) => i.firstName + ' ' + i.lastName,
    },
    {
      name: 'email',
      label: t('email'),
    },
  ]

  const search: Search<UserQuery> = {
    searchValues: searcher,
    handleSearch: (svs: SearchValue<UserQuery>[]) => setSearcher(svs),
  }

  const editUser = (u: User) => navigate(`${ROUTE_USERS_CREATE}/${u.id}`)

  const seeCharts = (u: User) => navigate(`${ROUTE_STATISTICS}/${u.id}`)
  const seeDashboard = (u: User) => navigate(`${ROUTE_DASHBOARD}/${u.id}`)

  const deleteUser = (u: User) => {
    setCurrentUser(u)
    setOpenDeleteModal(true)
    // navigate(ROUTE_PATIENTS)
  }

  const calendarUser = (u: User) => navigate(`${ROUTE_CALENDAR_EXERCISES}/${u.id}`)

  const addTreatment = (u: User) => navigate(`${ROUTE_TREATMENTS}`, { state: { userID: u.id } })

  const isCreator = (u: User): boolean => u.assignedID === loggedUserService.get()?.id

  const isAdmin = loggedUserService.get()?.roles.includes('admin') || false

  const actions: Actions<User> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: editUser,
        icon: editIcon,
        label: t('edit'),
        hidden: (u) => !isAdmin,
      },

      {
        handler: deleteUser,
        icon: iconDelete,
        label: t('delete'),
        hidden: (u) => !isAdmin,
      },
    ],
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: innerWidth > 900 ? (openMenuLeft ? '50px' : '') : '',
      }}
    >
      <Box
        style={{
          maxWidth:
            innerWidth > 900 ? (openMenuLeft ? 'calc(100% - 250px)' : 'calc(100% - 300px)') : '95%',
          position: 'relative',
        }}
        className={genericStyle.pageContainer}
      >
        <div className={genericStyle.container}>
          <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
            <CustomModal
              title={t('deleteProfesional')}
              warningText={t('questionDeleteProfesional')}
              handleClose={handleCloseDeleteModal}
              handleSave={handleDeleteUser}
            />
          </Modal>
          <AppTable
            rowKeyField="id"
            items={users}
            fields={fields}
            actions={actions}
            search={search}
            isLoading={isLoading}
          />
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={'100%'}
          >
            <Box sx={{ marginLeft: openMenuLeft ? '35px' : '10px' }}>
              {pager && <Pagination {...pager} />}
            </Box>
            <Box display={'flex'} alignItems={'center'} mt={openMenuLeft ? 0 : 2} mr={10}>
              <img
                style={{ marginRight: 2, marginBottom: 2 }}
                src={searchIcon}
                alt={'search patient'}
              />
              <Searcher search={search} />
            </Box>
          </Box>
        </div>
        {isAdmin && (
          <Box
            style={{ position: 'absolute', right: -30, bottom: -3, cursor: 'pointer' }}
            onClick={handlerAddUser}
          >
            <Tooltip title={t('addPatient')}>
              <img src={addPatientIcon} alt="add patient" width={90} />
            </Tooltip>
          </Box>
        )}
      </Box>
    </div>
  )
}

const searcherQuery = (
  svs: SearchValue<UserQuery>[]
): QueryParam<UserQuery>[] | QueryParamN<UserQuery>[] =>
  svs.filter((sv) => sv.value).map((sv) => ({ name: sv.name, value: sv.value as string }))
