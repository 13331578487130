import { UserForm } from './UserForm'
import { UserFormValue } from './UserFormValue'
import { ReadingStatus } from '../enums/ReadingStatus'
import { v4 as uuidv4 } from 'uuid'
import { TemplateUserRecurrence } from './TemplateUserRecurrence'

export interface UserFormDTO {
  id: string | undefined
  userID: string
  circleID: string
  formID: string
  userFormValues: UserFormValueDTO[]
  readingStatus: ReadingStatus
  typeForm: string
}

export interface TemplateUserRecurrenceDTO {
  id: string
  templateID: string
  userID: string
  recurrence: string
}

export interface UserFormWithTemplateUserRecurrenceDTO {
  id: string | undefined
  userID: string
  circleID: string
  formID: string
  userFormValues: UserFormValueDTO[]
  readingStatus: ReadingStatus
  templateUserRecurrence: TemplateUserRecurrence
}

export function emptyUserFormDTO(user: string, circle: string) {
  return {
    id: uuidv4(),
    userID: user,
    circleID: circle,
    formID: uuidv4(),
    userFormValues: [],
    readingStatus: ReadingStatus.Pending,
    typeForm: '',
  }
}

export interface UserFormValueDTO {
  userFormID: string
  formFieldID: string
  values?: string[]
  date: Date
  done: boolean
}

export function fromModel(m: UserForm): UserFormDTO {
  return {
    id: m.id,
    userID: m.userID,
    circleID: m.circleID,
    formID: m.formID,
    userFormValues: m.userFormValues.map((v) => fromModelValue(v)),
    readingStatus: m.readingStatus,
    typeForm: m.typeForm,
  }
}

export function fromModelValue(v: UserFormValue): UserFormValueDTO {
  let values = v.getValues()
  let result: string[]
  if (values && !Array.isArray(values)) {
    if (typeof values === typeof false) {
      result = [values ? '1' : '0']
    } else {
      result = [values as unknown as string]
    }
  } else {
    result = Array.isArray(values) ? values : ['']
  }
  return {
    userFormID: v.userFormID,
    formFieldID: v.formFieldID,
    values: result,
    date: new Date(),
    done: v.done,
  }
}

export function toModel(d: UserFormDTO): UserForm {
  return new UserForm({
    id: d.id,
    userID: d.userID,
    circleID: d.circleID,
    formID: d.formID,
    userFormValues: d.userFormValues.map((v) => toModelValue(v)),
    readingStatus: d.readingStatus,
    typeForm: d.typeForm,
  })
}

export function toModelValue(v: UserFormValueDTO): UserFormValue {
  return new UserFormValue(v)
}
