import React, { createContext, useState, useContext } from 'react'
import { UserDTO } from '../../modules/users/models/User'

type PatientContextType = {
  patient: UserDTO | null
  setPatient: (patient: UserDTO) => void
}

const PatientContext = createContext<PatientContextType | undefined>(undefined)

export const PatientProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [patient, setPatient] = useState<UserDTO | null>(null)

  return (
    <PatientContext.Provider value={{ patient, setPatient }}>{children}</PatientContext.Provider>
  )
}

export const usePatient = (): PatientContextType => {
  const context = useContext(PatientContext)
  if (!context) {
    throw new Error('usePatient debe estar dentro del proveedor PatientContext')
  }
  return context
}
