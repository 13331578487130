import { Container, IInit } from '../../../common/container/Container'
import { ContentContainerConfig } from '../container'
import { Observable, of } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { catchError, map } from 'rxjs/operators'
import { prepareURL } from '../../../common/api/http-helpers'
import { HTTP_CLIENT_KEY, IHTTPClient } from '../../../common/api/HTTPClient'
import { IStatusService } from '../../../common/status/StatusService'
import { emptyList, ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { UserTraining } from '../models/UserTraining'
import { toModel, UserTrainingDTO } from '../models/UserTrainingDTO'
import { UserTrainingWithTrainingName } from '../models/UserTrainingWithTrainingName'
import { toModel as toModelWithTrainingName } from '../models/UserTrainingWithTrainingNameDTO'

export interface IUserTrainingApi extends IInit {
  getByID(id: string): Observable<UserTraining | undefined>

  getFilteredList(q: Query<UserTraining>): Observable<ItemList<UserTraining>>

  getListWithTrainingNameByUserID(
    q: Query<UserTrainingWithTrainingName>
  ): Observable<ItemList<UserTrainingWithTrainingName>>

  add(e: UserTrainingDTO): Observable<UserTraining | undefined>

  addUserTrainings(e: UserTrainingDTO[]): Observable<UserTraining | undefined>

  update(e: UserTrainingDTO): Observable<UserTraining | undefined>

  delete(id: string): Observable<boolean>

  deleteUserTrainings(ids: string[]): Observable<boolean>

  updateEditUserTrainings(e: UserTrainingDTO[]): Observable<UserTraining | undefined>
}

export class UserTrainingApi implements IUserTrainingApi {
  private _container!: Container
  private _httpClient!: IHTTPClient
  private _url!: string
  private _statusService!: IStatusService

  init(c: Container) {
    this._container = c
    this._httpClient = this._container.get<IHTTPClient>(HTTP_CLIENT_KEY)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
    this._url = (this._container.config as ContentContainerConfig).moduleFullUrl + '/user-training'
  }

  add(e: UserTrainingDTO): Observable<UserTraining | undefined> {
    return this._httpClient.post<UserTraining>({ url: this._url, body: e }).pipe(
      map<UserTrainingDTO, UserTraining>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  addUserTrainings(e: UserTrainingDTO[]): Observable<UserTraining | undefined> {
    return this._httpClient
      .post<UserTraining>({ url: this._url + '/create-user-trainings', body: e })
      .pipe(
        map<UserTrainingDTO, UserTraining>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }

  delete(id: string): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/${id}` }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  deleteUserTrainings(ids: string[]): Observable<boolean> {
    return this._httpClient.delete({ url: `${this._url}/delete-user-trainings`, body: ids }).pipe(
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(false)
      })
    )
  }

  getByID(id: string): Observable<UserTraining | undefined> {
    return this._httpClient.get<UserTraining>({ url: `${this._url}/${id}` }).pipe(
      map<UserTrainingDTO, UserTraining>((d) => toModel(d)),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  getFilteredList(q: Query<UserTraining>): Observable<ItemList<UserTraining>> {
    let url = this._url
    return this._httpClient.get<ItemList<UserTraining>>({ url: prepareURL(url, q) }).pipe(
      map<ItemList<UserTrainingDTO>, ItemList<UserTraining>>((dto) => {
        const itemList = emptyList<UserTraining>()
        itemList.count = dto.count
        itemList.items = dto.items.map((d) => toModel(d))
        return itemList
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(emptyList<UserTraining>())
      })
    )
  }

  getListWithTrainingNameByUserID(
    q: Query<UserTrainingWithTrainingName>
  ): Observable<ItemList<UserTrainingWithTrainingName>> {
    let url = this._url + '/list-with-training-title-by-user-id'
    return this._httpClient
      .get<ItemList<UserTrainingWithTrainingName>>({ url: prepareURL(url, q) })
      .pipe(
        map<ItemList<UserTrainingWithTrainingName>, ItemList<UserTrainingWithTrainingName>>(
          (dto) => {
            const itemList = emptyList<UserTrainingWithTrainingName>()
            itemList.count = dto.count
            itemList.items = dto.items.map((d) => toModelWithTrainingName(d))
            return itemList
          }
        ),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(emptyList<UserTrainingWithTrainingName>())
        })
      )
  }

  update(e: UserTrainingDTO): Observable<UserTraining | undefined> {
    return this._httpClient.put<UserTraining>({ url: this._url, body: e }).pipe(
      map<UserTrainingDTO, UserTraining>((d) => {
        this._statusService.sendStatus({ variant: 'success' })
        return toModel(d)
      }),
      catchError((err) => {
        this._statusService.sendStatus({ variant: 'error', error: err })
        return of(undefined)
      })
    )
  }

  updateEditUserTrainings(e: UserTrainingDTO[]): Observable<UserTraining | undefined> {
    return this._httpClient
      .put<UserTraining>({ url: this._url + '/update-edit-user-trainings', body: e })
      .pipe(
        map<UserTrainingDTO, UserTraining>((d) => {
          this._statusService.sendStatus({ variant: 'success' })
          return toModel(d)
        }),
        catchError((err) => {
          this._statusService.sendStatus({ variant: 'error', error: err })
          return of(undefined)
        })
      )
  }
}
