import { Observable } from 'rxjs'
import { Container, IInit } from '../../../common/container/Container'
import { Query } from '../../../common/api/Query'
import { IStatusService } from '../../../common/status/StatusService'
import { ItemList } from '../../../common/models/ItemList'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { UserTreatmentDTO } from '../models/UserTreatmentDTO'
import { UserTreatment, UserTreatmentQuery } from '../models/UserTreatment'
import { UserTreatmentApi } from '../api/UserTreatmentApi'
import { UserTreatmentWithTreatmentName } from '../models/UserTreatmentWithTreatmentName'

type Props = {
  apiKey: symbol
}

export interface IUserTreatmentService extends IInit {
  getByID(id: string): Observable<UserTreatment | undefined>

  getFilteredList(q: Query<UserTreatmentQuery>): Observable<ItemList<UserTreatment>>

  getFilteredItems(q: Query<UserTreatmentQuery>): Observable<UserTreatment[]>
  getListWithTreatmentNameByUserID(
    q: Query<UserTreatmentQuery>
  ): Observable<ItemList<UserTreatmentWithTreatmentName>>

  add(e: UserTreatmentDTO): Observable<UserTreatment | undefined>

  update(e: UserTreatmentDTO): Observable<UserTreatment | undefined>

  delete(id: string): Observable<boolean>
}

export class UserTreatmentService implements IUserTreatmentService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: UserTreatmentApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<UserTreatmentApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: UserTreatmentDTO): Observable<UserTreatment | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<UserTreatment | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<UserTreatmentQuery>): Observable<ItemList<UserTreatment>> {
    return this._api.getFilteredList(q).pipe()
  }

  getFilteredItems(q: Query<UserTreatmentQuery>): Observable<UserTreatment[]> {
    return this._api.getFilteredItems(q).pipe()
  }

  getListWithTreatmentNameByUserID(
    q: Query<UserTreatmentQuery>
  ): Observable<ItemList<UserTreatmentWithTreatmentName>> {
    return this._api.getListWithTreatmentNameByUserID(q).pipe()
  }

  update(e: UserTreatmentDTO): Observable<UserTreatment | undefined> {
    return this._api.update(e)
  }
}
