import React from 'react'
import { Box, Typography } from '@mui/material'
import { AppCheckbox } from 'components/app-checkbox/AppCheckbox'
import { CustomTooltip } from 'components/custom-tooltip/CustomTooltip'
import editIcon from 'assets/viewPatients/edit.svg'
import seeIcon from 'assets/articles/see.svg'
import styles from '../CreatePatientView.module.scss'
import { useTranslation } from 'react-i18next'
import { FORM_NAME } from '../constants'

interface Form {
  name: string
  value: string
}

interface FormsListProps {
  selectedForms: Set<string>
  handleSelectedForms: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleEditSelectedForms: (formType: string) => void
  handleOpenSeeMedicationModal: () => void
}

const forms: Form[] = [
  { name: FORM_NAME.FOOD, value: 'food' },
  { name: FORM_NAME.CONDUCT, value: 'conduct' },
  { name: FORM_NAME.MEDICATION, value: 'medication' },
  { name: FORM_NAME.TRAINING, value: 'training' },
]

const FormsList: React.FC<FormsListProps> = ({
  selectedForms,
  handleSelectedForms,
  handleEditSelectedForms,
  handleOpenSeeMedicationModal,
}) => {
  const { t } = useTranslation()
  return (
    <Box id={'Form List'} width="70%">
      <Box marginTop="16px" maxWidth="100%" className={styles.headerContainer}>
        <Typography className={styles.headerTitle}>{t('forms')}</Typography>
      </Box>
      <Box
        className={styles.textFieldBox}
        display={'flex'}
        flexWrap={'wrap'}
        width="100%"
        id={'Listado Formularios'}
      >
        {forms.map((form) => (
          <Box
            id={'Elemento Form'}
            display="flex"
            flexGrow="1"
            flexShrink="1"
            flexBasis="50%"
            alignItems="center"
            key={form.name}
          >
            {form.name === FORM_NAME.MEDICATION ? (
              <AppCheckbox
                checked={selectedForms.has(form.name)}
                value={form.name}
                onChange={handleSelectedForms}
                style={{ justifyContent: 'flex-start', visibility: 'hidden' }}
              />
            ) : (
              <AppCheckbox
                checked={selectedForms.has(form.name)}
                value={form.name}
                onChange={handleSelectedForms}
                style={{ justifyContent: 'flex-start' }}
              />
            )}

            <Typography className={styles.checkboxLabel}>{t(form.value)}</Typography>
            <Box display="flex" justifyContent="center">
              {form.name === FORM_NAME.MEDICATION ? (
                <CustomTooltip title={t('see')} placement="right">
                  <img
                    className={styles.iconEdit}
                    src={seeIcon}
                    alt="see"
                    onClick={handleOpenSeeMedicationModal}
                  />
                </CustomTooltip>
              ) : (
                <CustomTooltip title={t('edit')} placement="right">
                  <img
                    className={styles.iconEdit}
                    src={editIcon}
                    alt="edit"
                    onClick={() => handleEditSelectedForms(form.name)}
                  />
                </CustomTooltip>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default FormsList
