import React from 'react'
import { Card, FormControl, Button, InputGroup } from 'react-bootstrap'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { ChatMsg } from './ChatMsg'
import { Messages } from './Messages'

export const Chat = ({ user, chatOpen, listOpen, closed }) => {
  const getMessage = () => {
    let message = ''
    if (ChatMsg.filter((chats) => chats.friend_id === user.id).length === 0) {
      message = (
        <Card className="chat-messages text-center">
          <Card.Body className="chat-menu-content">
            <div className="">
              <p className="chat-cont">CHAT NOT FOUND</p>
            </div>
          </Card.Body>
        </Card>
      )
    } else {
      ChatMsg.filter((chats) => {
        if (chats.friend_id === user.id) {
          message = chats.messages.map((msg, index) => {
            return (
              <Messages key={index} message={msg} name={user.name} photo={chats.friend_photo} />
            )
          })
        }
        return false
      })
    }
    return message
  }

  let chatClass = ['header-chat']
  if (chatOpen && listOpen) {
    chatClass = [...chatClass, 'open']
  }

  return (
    <React.Fragment>
      <div className={chatClass.join(' ')}>
        <div className="h-list-header">
          <h6>{user.name}</h6>
          <div to="#" className="h-back-user-list" onClick={closed}>
            <i className="feather icon-chevron-left text-muted" />
          </div>
        </div>
        <div className="h-list-body">
          <div className="main-chat-cont">
            <PerfectScrollbar>
              <div className="main-friend-chat">{getMessage()}</div>
            </PerfectScrollbar>
          </div>
        </div>
        <div className="h-list-footer">
          <InputGroup>
            <Button variant="success" className="btn-attach">
              <i className="feather icon-paperclip" />
            </Button>
            <FormControl
              type="text"
              name="h-chat-text"
              className="h-send-chat"
              placeholder="Write hear . . "
            />
            <Button type="submit" className="input-group-append btn-send">
              <i className="feather icon-message-circle" />
            </Button>
          </InputGroup>
        </div>
      </div>
    </React.Fragment>
  )
}
