import { Circle } from '../models/Circle'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ICircleApi } from '../api/CircleApi'
import { ItemList } from '../../../common/models/ItemList'
import { CircleDTO } from '../models/CircleDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'

type Props = {
  apiKey: symbol
}

export interface ICircleService extends IInit {
  getByID(id: string): Observable<Circle | undefined>

  getByPatientID(patientID: string): Observable<Circle | undefined>

  getFilteredList(q: Query<Circle>): Observable<ItemList<Circle>>

  add(e: CircleDTO): Observable<Circle | undefined>

  update(e: CircleDTO): Observable<Circle | undefined>

  delete(id: string): Observable<boolean>
}

export class CircleService implements ICircleService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: ICircleApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<ICircleApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: CircleDTO): Observable<Circle | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Circle | undefined> {
    return this._api.getByID(id)
  }

  getByPatientID(patientID: string): Observable<Circle | undefined> {
    return this._api.getByPatientID(patientID)
  }

  getFilteredList(q: Query<Circle>): Observable<ItemList<Circle>> {
    return this._api.getFilteredList(q)
  }

  update(e: CircleDTO): Observable<Circle | undefined> {
    return this._api.update(e)
  }
}
