import { UserTrainingDTO } from './UserTrainingDTO'
import { TypeOfRepeating } from '../enum/TypeOfRepeatingTypes'

export class UserTraining {
  private _id: string
  private _trainingID: string
  private _userID: string
  private _startDate: Date
  private _finishDate: Date
  private _frecuency: number
  private _series: number
  private _minReps: number
  private _maxReps: number
  private _repsEspecs: string
  private _typeOfRepeating: TypeOfRepeating
  private _completed: boolean
  private _recurrence: string

  constructor(p: UserTrainingDTO) {
    this._id = p.id
    this._trainingID = p.trainingID
    this._userID = p.userID
    this._startDate = p.startDate
    this._finishDate = p.finishDate
    this._frecuency = p.frecuency
    this._series = p.series
    this._minReps = p.minReps
    this._maxReps = p.maxReps
    this._repsEspecs = p.repsEspecs
    this._typeOfRepeating = p.typeOfRepeating
    this._completed = p.completed
    this._recurrence = p.recurrence
  }

  get id(): string {
    return this._id
  }

  set id(value: string) {
    this._id = value
  }

  get trainingID(): string {
    return this._trainingID
  }

  set trainingID(value: string) {
    this._trainingID = value
  }

  get userID(): string {
    return this._userID
  }

  set userID(value: string) {
    this._userID = value
  }

  get startDate(): Date {
    return this._startDate
  }

  set startDate(value: Date) {
    this._startDate = value
  }

  get finishDate(): Date {
    return this._finishDate
  }

  set finishDate(value: Date) {
    this._finishDate = value
  }

  get frecuency(): number {
    return this._frecuency
  }

  set frecuency(value: number) {
    this._frecuency = value
  }

  get series(): number {
    return this._series
  }

  set series(value: number) {
    this._series = value
  }

  get minReps(): number {
    return this._minReps
  }

  set minReps(value: number) {
    this._minReps = value
  }

  get maxReps(): number {
    return this._maxReps
  }

  set maxReps(value: number) {
    this._maxReps = value
  }

  get repsEspecs(): string {
    return this._repsEspecs
  }

  set repsEspecs(value: string) {
    this._repsEspecs = value
  }

  get typeOfRepeating(): TypeOfRepeating {
    return this._typeOfRepeating
  }

  set typeOfRepeating(value: TypeOfRepeating) {
    this._typeOfRepeating = value
  }

  get completed(): boolean {
    return this._completed
  }

  set completed(value: boolean) {
    this._completed = value
  }

  get recurrence(): string {
    return this._recurrence
  }

  set recurrence(value: string) {
    this._recurrence = value
  }
}
