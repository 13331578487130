import { Box, Skeleton, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material'
import { Head } from '../../components/table/Head'
import React from 'react'
import { GenericTooltip } from '../../components/generic-tooltip'
import { Field } from '../../components/table'
import { Actions, Pager, Sort } from '../../components/table/types'

type CustomGeneralTreatmentTableProps<T> = {
  readonly fields: Field<T>[]
  readonly items: T[]
  readonly rowKeyField: keyof T
  readonly actions?: Actions<T>
  readonly sort?: Sort<T>
  readonly pager?: Pager
  readonly isLoading?: boolean
  openMenuLeft: boolean
  innerWidth: number
}

const styles = {
  body: {
    fontSize: '14px',
    fontFamily: 'Montserrat-Regular, sans-serif',
    borderBottom: '1px solid #D1D1D1',
    padding: '0 auto',
    verticalAlign: 'initial',
  },
  icon: {
    verticalAlign: 'middle',
    cursor: 'pointer',
    padding: '0 4px',
  },
}

export const CustomGeneralTable = <T extends { [key: string]: any }>({
  openMenuLeft,
  innerWidth,
  isLoading,
  actions,
  fields,
  items,
  rowKeyField,
}: CustomGeneralTreatmentTableProps<T>) => {
  const rows = items.map((item, i) => {
    const actionStyle = actions?.styleFunc ? actions.styleFunc(item) : ''

    return !isLoading ? (
      <TableRow sx={styles.body} key={`${item[rowKeyField]}  ${i}`}>
        {fields.map((field, index) => {
          const styleFuncResult = field.styleFunc ? field.styleFunc(field, item) : ''
          const style = typeof styleFuncResult === 'object' ? styleFuncResult : {}

          return (
            <TableCell
              sx={{
                ...styles.body,
                ...style,
                width: `${100 / fields.length}%`,
                minWidth: '180px',
                marginLeft: 4,
              }}
              key={(field.name as string) + index + 'cell'}
            >
              {field.renderFunc ? field.renderFunc(field, item) : item[field.name]?.toString()}
            </TableCell>
          )
        })}

        {actions && actions.items?.length > 0 && (
          <TableCell
            sx={{
              ...styles.body,
              width: `${100 / fields.length}%`,
              minWidth: '180px',
              marginLeft: 4,
            }}
            key={item[rowKeyField] + 'action-cell'}
          >
            <Box
              sx={{ display: 'flex', justifyContent: 'space-around' }}
              key={item[rowKeyField] + 'box'}
            >
              {actions.items.map((action, actionIndex) => {
                if (action.hidden && action.hidden(item)) {
                  return null
                }

                return (
                  <GenericTooltip
                    values={[action.label || action.icon]}
                    icon={
                      <img
                        src={action.icon}
                        style={{
                          verticalAlign: 'middle',
                          cursor: 'pointer',
                          padding: '0 4px',
                        }}
                        key={`${item[rowKeyField]}  ${actionIndex}`}
                        onClick={() => action.handler(item)}
                        alt="action-icon"
                      />
                    }
                    noUseFab={true}
                  />
                )
              })}
            </Box>
          </TableCell>
        )}
      </TableRow>
    ) : (
      <TableRow sx={styles.body} key={`${item[rowKeyField]}  ${i}`}>
        {fields.map((field, index) => {
          const styleFuncResult = field.styleFunc ? field.styleFunc(field, item) : ''
          const style = typeof styleFuncResult === 'object' ? styleFuncResult : {}

          return (
            <TableCell
              sx={{
                ...styles.body,
                ...style,
              }}
              key={(field.name as string) + index + 'cell'}
            >
              <Skeleton />
            </TableCell>
          )
        })}

        {actions && actions.items?.length > 0 && (
          <TableCell
            sx={{
              ...styles.body,
              ...(typeof actionStyle === 'object' ? actionStyle : {}),
            }}
            key={item[rowKeyField] + 'action-cell'}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              {actions.items.map((a, actionIndex) => {
                if (a.hidden && a.hidden(item)) {
                  return null
                }

                return (
                  <GenericTooltip
                    key={actionIndex}
                    values={[a.label || a.icon]}
                    icon={<Skeleton variant="circular" width={10} height={10} />}
                    noUseFab={true}
                  />
                )
              })}
            </Box>
          </TableCell>
        )}
      </TableRow>
    )
  })

  return (
    <TableContainer
      sx={{
        overflowX: openMenuLeft && innerWidth > 1500 ? 'hidden' : 'auto',
        marginLeft: '8px',
        marginRight: openMenuLeft
          ? innerWidth > 1300
            ? '134px'
            : '8px'
          : innerWidth > 1100
          ? '8px'
          : '8px',
      }}
    >
      <Table
        sx={{
          border: 'none',
          background: 'transparent',
          width: innerWidth < 1300 ? '95%' : '95%',
          margin: 0,
          '& td': {
            borderWidth: 1,
            borderStyle: 'dotted',
            borderColor: '#ADB84E',
            '&:first-child': {
              borderLeftWidth: 1,
              borderLeft: 'none',
              borderColor: '#ADB84E',
              color: '#ADB84E',
              fontFamily: 'Montserrat-SemiBold, sans-serif',
            },
          },
          '& tr': {
            borderStyle: 'dotted',
            borderColor: '#ADB84E',
          },
          '& th': {
            borderColor: '#ADB84E',
          },
        }}
        style={{ border: 'none' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: openMenuLeft ? '' : '100%',
            marginLeft: innerWidth < 900 ? '' : openMenuLeft ? 30 : '',
            marginRight: innerWidth < 900 ? '' : openMenuLeft ? 30 : '',
          }}
        >
          <Head fields={fields} actions={actions} />
          <TableBody style={{ overflow: 'auto', maxHeight: '450px', minHeight: '450px' }}>
            {rows}
          </TableBody>
        </div>
      </Table>
    </TableContainer>
  )
}
