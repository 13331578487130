import { UserTrainingWithTrainingName } from './UserTrainingWithTrainingName'
import { emptyUserTrainingDTO, UserTrainingDTO } from './UserTrainingDTO'

export interface UserTrainingWithTrainingNameDTO {
  userTraining: UserTrainingDTO
  title: string
}

export function emptyUserTrainingWithTrainingNameDTO(): UserTrainingWithTrainingNameDTO {
  return {
    userTraining: emptyUserTrainingDTO(),
    title: '',
  }
}

export function fromModel(t: UserTrainingWithTrainingName): UserTrainingWithTrainingNameDTO {
  return {
    userTraining: t.userTraining,
    title: t.title,
  }
}

export function toModel(d: UserTrainingWithTrainingNameDTO): UserTrainingWithTrainingName {
  return new UserTrainingWithTrainingName(d)
}
