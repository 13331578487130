export type UserTreatmentProps = {
  id: string | undefined
  treatmentID: string
  startDate: Date
  endDate: Date | undefined
  recurrence: string
  withoutEndDate: boolean
  duration: number
  frequency: number
  dose: string
  userID: string
  creatorID?: string
  status: number
  completedDate: Date | undefined
}

export class UserTreatment {
  private readonly _id: string | undefined
  private readonly _treatmentID: string
  private readonly _startDate: Date
  private readonly _endDate: Date | undefined
  private readonly _recurrence: string
  private readonly _withoutEndDate: boolean
  private readonly _duration: number
  private readonly _frequency: number
  private readonly _dose: string
  private readonly _userID: string
  private readonly _creatorID?: string
  private _status: number
  private _completedDate: Date | undefined

  constructor(p: UserTreatmentProps) {
    this._id = p.id
    this._treatmentID = p.treatmentID
    this._startDate = p.startDate
    this._endDate = p.endDate
    this._recurrence = p.recurrence
    this._withoutEndDate = p.withoutEndDate
    this._duration = p.duration
    this._frequency = p.frequency
    this._dose = p.dose
    this._userID = p.userID
    this._creatorID = p.creatorID
    this._status = p.status
    this._completedDate = p.completedDate
  }

  get id(): string | undefined {
    return this._id
  }

  get treatmentID(): string {
    return this._treatmentID
  }

  get startDate(): Date {
    return this._startDate
  }

  get endDate(): Date | undefined {
    return this._endDate
  }

  get recurrence(): string {
    return this._recurrence
  }

  get withoutEndDate(): boolean {
    return this._withoutEndDate
  }

  get duration(): number {
    return this._duration
  }

  get frequency(): number {
    return this._frequency
  }

  get dose(): string {
    return this._dose
  }

  get userID(): string {
    return this._userID
  }

  get creatorID(): string | undefined {
    return this._creatorID
  }

  get status(): number {
    return this._status
  }

  set status(value: number) {
    this._status = value
  }

  get completedDate(): Date | undefined {
    return this._completedDate
  }

  set completedDate(value: Date | undefined) {
    this._completedDate = value
  }
}

export interface UserTreatmentQuery {
  treatmentID: string
  userID: string
}
