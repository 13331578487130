import { v4 as uuidv4 } from 'uuid'
import { Circle } from './Circle'

export interface CircleDTO {
  id: string | undefined
  patientID: string
  familiars: string[] | undefined
}

export function emptyCircleDTO() {
  return {
    id: uuidv4(),
    patientID: '',
    familiars: [],
  }
}

export function fromModel(d: Circle): CircleDTO {
  return {
    id: d.id,
    patientID: d.patientID,
    familiars: d.familiars,
  }
}

export function toModel(d: CircleDTO): Circle {
  const circle = new Circle(d)
  return new Circle(d)
}
