import { UserTreatmentDTO } from './UserTreatmentDTO'
import { UserTreatmentWithTreatmentNameDTO } from './UserTreatmentWithTreatmentNameDTO'

export class UserTreatmentWithTreatmentName {
  private _userTreatment: UserTreatmentDTO
  private _name: string

  constructor(p: UserTreatmentWithTreatmentNameDTO) {
    this._userTreatment = p.userTreatment
    this._name = p.name
  }

  get userTreatment(): UserTreatmentDTO {
    return this._userTreatment
  }

  get name(): string {
    return this._name
  }
}
