import { Box, Typography } from '@mui/material'
import { Header } from '../../components/header/Header'
import styles from './NewTreatmentModal.module.scss'
import { useTranslation } from 'react-i18next'
import style from '../calendar/Editor.module.css'
import React from 'react'
import { unitTypeTypes } from '../../modules/treatments/enums/UnitType'
import { Treatment } from '../../modules/treatments/models/Treatment'
import close from '../../assets/articles/delete.svg'

interface NewTreatmentModalProps {
  currentTreatment?: Treatment
  handleOpenSeeTreatment: () => void
}

export const SeeTreatmentModal: React.FC<NewTreatmentModalProps> = ({
  currentTreatment,
  handleOpenSeeTreatment,
}) => {
  const { t } = useTranslation()

  return (
    <Box className={styles.container}>
      <Box className={styles.content}>
        <Header label={t('seeMedicine')} icon={close} onClick={handleOpenSeeTreatment} />
        {currentTreatment && (
          <>
            <Box display={'flex'} alignItems={'center'} mb={2}>
              <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                {t('name')}
              </p>
              <Typography sx={{ fontFamily: 'Montserrat-Regular, sans-serif' }}>
                {currentTreatment?.name}
              </Typography>
            </Box>
            <Box display={'flex'} alignItems={'center'} mb={2}>
              <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
                {t('units')}
              </p>
              <Typography sx={{ fontFamily: 'Montserrat-Regular, sans-serif' }}>
                {unitTypeTypes()[currentTreatment?.unit]}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Box>
  )
}
