import { Box, MenuItem } from '@mui/material'
import { Header } from '../../components/header/Header'
import styles from './NewTreatmentModal.module.scss'
import { useTranslation } from 'react-i18next'
import style from '../calendar/Editor.module.css'
import { Input } from '../../pages/user-profile/userProfileStylesMui'
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import {
  emptyTreatmentDTO,
  fromModel,
  TreatmentDTO,
} from '../../modules/treatments/models/TreatmentDTO'
import { UnitType, unitTypeTypes } from '../../modules/treatments/enums/UnitType'
import { AppButton, ButtonTheme } from '../../components/app-button/AppButton'
import { getContentContainer } from '../../container/treatment-module'
import { ITreatmentService, TREATMENT_SERVICE_KEY } from '../../modules/treatments'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { Treatment } from '../../modules/treatments/models/Treatment'
import close from '../../assets/articles/delete.svg'

const userContainer = getUserContainer()
const treatmentService = getContentContainer().get<ITreatmentService>(TREATMENT_SERVICE_KEY)
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)

interface NewTreatmentModalProps {
  handleClose: () => void
  currentTreatment?: Treatment
  handleLoading: () => void
}

export const NewTreatmentModal: React.FC<NewTreatmentModalProps> = ({
  handleClose,
  currentTreatment,
  handleLoading,
}) => {
  const loggedUser = loggedUserService.get()
  const [treatment, setTreatment] = useState<TreatmentDTO>(emptyTreatmentDTO())
  const { t } = useTranslation()

  useEffect(() => {
    if (currentTreatment) {
      setTreatment(fromModel(currentTreatment))
    }
  }, [currentTreatment])

  const handleTreatment = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setTreatment({
      ...treatment,
      [e.target.name]: e.target.value,
    })
  }

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const newTreatment = {
      ...treatment,
      creatorID: loggedUser?.id ?? '',
    }

    if (currentTreatment) {
      treatmentService.update(newTreatment).subscribe((res) => {
        handleLoading()
        handleClose()
      })
      return
    }
    treatmentService.add(newTreatment).subscribe((res) => {
      handleLoading()
      handleClose()
    })
  }

  const handleBack = () => {
    handleClose()
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.content}>
        <Header label={t('addMedicine')} icon={close} onClick={handleBack} />
        <Box mb={2}>
          <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
            {t('name')}
          </p>
          <Input
            fullWidth
            value={treatment.name}
            id="name"
            name="name"
            label={''}
            variant="outlined"
            required={true}
            onChange={(e) => handleTreatment(e)}
          />
        </Box>
        <Box mb={2}>
          <p style={{ marginBottom: 0, marginRight: 24 }} className={style.label}>
            {t('units')}
          </p>
          <Input
            select
            variant={'outlined'}
            sx={{ width: '80%' }}
            id="unit"
            name={'unit'}
            value={treatment.unit}
            label={''}
            onChange={handleTreatment}
          >
            <MenuItem value={UnitType.ml}>ml</MenuItem>
            <MenuItem value={UnitType.gr}>gr</MenuItem>
            <MenuItem value={UnitType.mg}>mg</MenuItem>
            <MenuItem value={UnitType.mm}>mm</MenuItem>
          </Input>
        </Box>
        <Box className={styles.buttons} display="flex" justifyContent="center">
          <Box style={{ marginRight: 10 }}>
            <AppButton
              theme={ButtonTheme.NewPrimary}
              type={'submit'}
              label={t('add')}
              handler={(e) => handleSave(e)}
            />
          </Box>
          <AppButton
            theme={ButtonTheme.NewSecondary}
            type={'button'}
            label={t('cancel')}
            handler={handleBack}
          />
        </Box>
      </Box>
    </Box>
  )
}
