import i18n from '../../../i18n'

export enum FormType {
  Conduct = 0,
  Feeding,
  Medication,
  Training,
}

export const formTypes = (): Record<FormType, string> => ({
  [FormType.Conduct]: 'Conduct',
  [FormType.Feeding]: 'Feeding Tracking',
  [FormType.Medication]: 'Medication',
  [FormType.Training]: 'Training',
})

export enum FeedType {
  Breakfast = 0,
  Lunch,
  Dinner,
}

export const feedTypes = (): Record<FeedType, string> => ({
  [FeedType.Breakfast]: i18n.t('Breakfast'),
  [FeedType.Lunch]: i18n.t('Lunch'),
  [FeedType.Dinner]: i18n.t('Dinner'),
})
