import { v4 as uuidv4 } from 'uuid'
import { UserTreatment } from './UserTreatment'

export interface UserTreatmentDTO {
  id: string | undefined
  treatmentID: string
  startDate: Date
  endDate: Date | undefined
  recurrence: string
  withoutEndDate: boolean
  duration: number
  frequency: number
  dose: string
  userID: string
  creatorID?: string
  status: number
  completedDate: Date | undefined
}

export function emptyUserTreatmentDTO(userID: string) {
  return {
    id: uuidv4(),
    treatmentID: '',
    startDate: new Date(),
    endDate: new Date(),
    recurrence: '',
    withoutEndDate: false,
    duration: 0,
    frequency: 0,
    dose: '',
    creatorID: '',
    userID,
    status: 0,
    completedDate: undefined,
  }
}

export function fromModel(d: UserTreatment): UserTreatmentDTO {
  return {
    id: d.id,
    treatmentID: d.treatmentID,
    startDate: d.startDate,
    endDate: d.endDate,
    recurrence: d.recurrence,
    withoutEndDate: d.withoutEndDate,
    duration: d.duration,
    frequency: d.frequency,
    dose: d.dose,
    userID: d.userID,
    creatorID: d.creatorID,
    status: d.status,
    completedDate: d.completedDate,
  }
}

export function toModel(d: UserTreatmentDTO): UserTreatment {
  return new UserTreatment(d)
}
