import { Field } from '../../components/table'
import React, { useEffect, useState } from 'react'
import { Actions, Pager } from '../../components/table/types'
import viewIcon from '../../assets/articles/see.svg'
import editIcon from '../../assets/articles/edit.svg'
import deleteIcon from '../../assets/articles/delete.svg'
import { useTranslation } from 'react-i18next'
import { getContentContainer } from '../../container/treatment-module'
import { ITreatmentService, TREATMENT_SERVICE_KEY } from '../../modules/treatments'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import { IUserService, LOGGED_USER_SERVICE_KEY, USER_SERVICE_KEY } from '../../modules/users'
import { getUserContainer } from '../../container/user-module'
import { Treatment } from '../../modules/treatments/models/Treatment'
import { Query } from '../../common/api/Query'
import { ROUTE_TREATMENTS } from '../../routes/routes-constants'
import { CustomModal } from '../../components/modal/CustomModal'
import { Box, Button, Modal, Table } from '@mui/material'
import { TableContainer } from '@mui/material'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import { useNavigate } from 'react-router-dom'
import genericStyle from '../../common/utils/generic.module.css'
import { Pagination } from '../../components/table/Pagination'
import styles from './AllTreatment.module.scss'
import newEvent from '../../assets/calendar/add.svg'
import { Head } from '../../components/table/Head'
import { Body } from '../../components/table/Body'
import { NewTreatmentModal } from './NewTreatmentModal'
import { CustomGeneralTable } from './CustomGeneralTreatmentTable'
import { SeeTreatmentModal } from './SeeTreatmentModal'

type TreatmentTableProps = {
  id?: string
}

const userContainer = getUserContainer()
const treatmentService = getContentContainer().get<ITreatmentService>(TREATMENT_SERVICE_KEY)
const loggedUserService = userContainer.get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const userService = userContainer.get<IUserService>(USER_SERVICE_KEY)

export function AllTreatmentTable(props: TreatmentTableProps) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const loggedUser = loggedUserService.get()
  const [treatments, setTreatments] = useState<Treatment[]>([])
  const [treatmentsPerPage, setTreatmentsPerPage] = useState<number>(10)
  const [page, setPage] = useState<number>(0)
  const [pager, setPager] = useState<Pager>()
  const [users, setUsers] = useState<Map<string, string>>(new Map())
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [count, setCount] = useState<number>(0)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [currentTreatment, setCurrentTreatment] = useState<Treatment>()
  const [openNewTreatmentModal, setOpenNewTreatmentModal] = useState<boolean>(false)
  const [openModalSeeTreatment, setOpenModalSeeTreatment] = useState<boolean>(false)
  const { openMenuLeft } = useIsOpenMenuLeftContext()
  const innerWidth = window.innerWidth

  useEffect(() => {
    if (!isLoading) {
      return
    }

    treatmentService
      .getAllTreatmentsWithCreatorID(
        new Query({
          query: [],
          pager: { limit: treatmentsPerPage, offset: page * treatmentsPerPage },
        })
      )
      .subscribe((res) => {
        setCount(res.count)
        setTreatments(res.items)
        setIsLoading(false)
      })
  }, [isLoading, props.id])

  const handlePaginationChange = (event: unknown, value: number) => setPage(value)

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (Number.isNaN(event.target.value)) {
      setTreatmentsPerPage(10)
      return
    }
    setTreatmentsPerPage(Number.parseInt(event.target.value))
  }
  useEffect(() => {
    setIsLoading(true)
    setPager({
      page,
      count,
      handleChangePage: handlePaginationChange,
      rowsPerPage: treatmentsPerPage,
      handleChangeRowsPerPage,
    })
  }, [page, count, treatmentsPerPage])

  const seeTreatment = (t: Treatment) => {
    setCurrentTreatment(t)
    setOpenModalSeeTreatment(true)
  }

  const editTreatment = (t: Treatment) => {
    setCurrentTreatment(t)
    /* navigate(`${ROUTE_TREATMENTS}/${t.id}`)*/
    setOpenNewTreatmentModal(true)
  }

  const removeTreatment = (t: Treatment) => {
    setCurrentTreatment(t)
    setOpenDeleteModal(true)
  }

  const handleCloseDeleteModal = () => setOpenDeleteModal(false)

  const handleDeleteTreatment = () => {
    if (currentTreatment?.id)
      treatmentService.delete(currentTreatment.id).subscribe((_) => setIsLoading(true))
    setOpenDeleteModal(false)
    setIsLoading(true)
  }

  const handleNewTreatment = () => {
    setOpenNewTreatmentModal(!openNewTreatmentModal)
    if (!openNewTreatmentModal) {
      setCurrentTreatment(undefined)
    }
  }

  const handleOpenSeeTreatment = () => {
    setOpenModalSeeTreatment(!openModalSeeTreatment)
  }

  const handleLoading = () => {
    setIsLoading(true)
  }

  const isCreator = (t: Treatment): boolean => t.creatorID === loggedUser?.id

  const fields: Field<Treatment>[] = [
    {
      name: 'name',
      label: t('name'),
    },
    {
      name: 'creatorID',
      label: t('creator'),
    },
  ]

  const actions: Actions<Treatment> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: seeTreatment,
        icon: viewIcon,
        label: t('see'),
      },
      {
        handler: editTreatment,
        icon: editIcon,
        label: t('edit'),
      },
      {
        handler: removeTreatment,
        icon: deleteIcon,
        label: t('delete'),
      },
    ],
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: innerWidth > 900 ? (openMenuLeft ? '50px' : '') : '',
      }}
    >
      <Box
        style={{
          maxWidth:
            innerWidth > 900 ? (openMenuLeft ? 'calc(100% - 250px)' : 'calc(100% - 300px)') : '95%',
          position: 'relative',
        }}
        className={genericStyle.pageContainer}
      >
        <div style={{ height: '100%' }} className={genericStyle.container}>
          <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
            <Modal open={openDeleteModal} onClose={handleCloseDeleteModal}>
              <CustomModal
                title={t('deleteTreatment')}
                warningText={t('irreversibleTreatmentAction')}
                handleClose={handleCloseDeleteModal}
                handleSave={handleDeleteTreatment}
              />
            </Modal>
          </Modal>
          {openNewTreatmentModal && (
            <Modal open={openNewTreatmentModal} onClose={handleNewTreatment}>
              <NewTreatmentModal
                currentTreatment={currentTreatment}
                handleClose={handleNewTreatment}
                handleLoading={handleLoading}
              />
            </Modal>
          )}
          {openModalSeeTreatment && (
            <Modal open={openModalSeeTreatment} onClose={handleOpenSeeTreatment}>
              <SeeTreatmentModal
                handleOpenSeeTreatment={handleOpenSeeTreatment}
                currentTreatment={currentTreatment}
              />
            </Modal>
          )}
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            width={'100%'}
            alignItems={'center'}
          >
            <Box ml={8} width={'80%'}>
              <CustomGeneralTable
                openMenuLeft={openMenuLeft}
                innerWidth={innerWidth}
                isLoading={isLoading}
                items={treatments}
                fields={fields}
                actions={actions}
                rowKeyField={'id'}
              />
              <Box
                display={'flex'}
                justifyContent={'space-between'}
                alignItems={'center'}
                width={'100%'}
              >
                <Box sx={{ marginLeft: openMenuLeft ? '35px' : '10px' }}>
                  {pager && <Pagination {...pager} />}
                </Box>
              </Box>
            </Box>
            <Box
              mr={3}
              width={'20%'}
              display={'flex'}
              flexDirection={'column'}
              justifyContent={'space-around'}
              alignItems={'center'}
              height={'100%'}
            >
              <span className={styles.registeredMedicine}>Medicamentos registrados</span>
              <span className={styles.countMedicine}>
                {treatments.length > 0 ? treatments.length : '0'}
              </span>
              <Box className={styles.newTreatmentButtonContainer}>
                <Button
                  className={styles.newTreatmentButton}
                  type={'button'}
                  onClick={handleNewTreatment}
                >
                  <Box width={'100%'} display={'flex'} justifyContent={'space-around'}>
                    <img src={newEvent} alt="newEvent" width={40} />
                    <Box width={'190px'}>
                      <span className={styles.newTreatmentButtonText}>{t('newMedicine')}</span>
                    </Box>
                  </Box>
                </Button>
              </Box>
            </Box>
          </Box>
        </div>
      </Box>
    </div>
  )
}
