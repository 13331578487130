import { UserTreatmentWithTreatmentName } from './UserTreatmentWithTreatmentName'
import { emptyUserTreatmentDTO, UserTreatmentDTO } from './UserTreatmentDTO'

export interface UserTreatmentWithTreatmentNameDTO {
  userTreatment: UserTreatmentDTO
  name: string
}

export function emptyUserTreatmentWithTreatmentNameDTO(): UserTreatmentWithTreatmentNameDTO {
  return {
    userTreatment: emptyUserTreatmentDTO(''),
    name: '',
  }
}

export function fromModel(t: UserTreatmentWithTreatmentName): UserTreatmentWithTreatmentNameDTO {
  return {
    userTreatment: t.userTreatment,
    name: t.name,
  }
}

export function toModel(d: UserTreatmentWithTreatmentNameDTO): UserTreatmentWithTreatmentName {
  return new UserTreatmentWithTreatmentName(d)
}
