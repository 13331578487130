import styles from './Header.module.css'

type HeaderProps = {
  label: string
  icon?: string
  onClick?: () => void
  labelStyle?: string
}

export function Header(props: HeaderProps) {
  return (
    <div className={styles.container}>
      <p className={props.labelStyle ?? styles.header}>{props.label}</p>
      {props.icon && (
        <img className={styles.icon} src={props.icon} alt={props.label} onClick={props.onClick} />
      )}
    </div>
  )
}
