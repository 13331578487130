import React, { useEffect, useState } from 'react'
import { ViewProps } from './types'
import { Box, Modal, Tooltip } from '@mui/material'
import { Event as E } from '../../modules/calendar/models/Event'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import { dateToDateString, dateToTimeWithoutSecondsString } from '../../common/utils/date'
import { useTranslation } from 'react-i18next'
import style from './ListView.module.css'
import { Query, QueryParam } from '../../common/api/Query'
import { getUserContainer } from '../../container/user-module'
import { LOGGED_USER_SERVICE_KEY } from '../../modules/users'
import { AppTable, Field } from '../../components/table'
import { Actions } from '../../components/table/types'
import { EventCategory } from '../../modules/calendar/enums/EventCategory'
import othersIcon from '../../assets/events_icons/bola-otros.svg'
import adviceIcon from '../../assets/events_icons/bola-consejos.svg'
import landmarkIcon from '../../assets/events_icons/bola-hitos.svg'
import vaccineIcon from '../../assets/events_icons/bola-vacunas.svg'
import dateIcon from '../../assets/events_icons/bola-cita.svg'
import formIcon from '../../assets/events_icons/evento-formulario.svg'
import trainingIcon from '../../assets/events_icons/bola-wearables.svg'
import reviewIcon from '../../assets/events_icons/bola-revision.svg'
import { LoggedUserService } from '../../modules/users/services/LoggedUserService'
import seeIcon from '../../assets/table_icons/ico-ver.svg'
import { CalendarModal } from './CalendarModal'
import { getCalendarContainer } from '../../container/calendar-module'
import { EventService } from '../../modules/calendar/services/EventService'
import { APPOINTMENT_SERVICE_KEY, EVENT_SERVICE_KEY } from '../../modules/calendar'
import { IStatusService } from '../../common/status/StatusService'
import { getAppContainer, STATUS_SERVICE_KEY } from '../../container/app'
import { IAppointmentService } from '../../modules/calendar/services/AppointmentService'
import { Appointment } from '../../modules/calendar/models/Appointment'
import { emptyList, ItemList } from '../../common/models/ItemList'
import { TextField } from '@mui/material'

require('dayjs/locale/it')

dayjs.extend(isBetween)

const calendarContainer = getCalendarContainer()
const eventService = calendarContainer.get<EventService>(EVENT_SERVICE_KEY)
type ListViewProps = { id: string | undefined } & ViewProps

const loggedUserService = getUserContainer().get<LoggedUserService>(LOGGED_USER_SERVICE_KEY)
const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)
const appointmentService = getCalendarContainer().get<IAppointmentService>(APPOINTMENT_SERVICE_KEY)

export interface FieldsTable extends E {
  readonly title: string
  readonly startDate: Date
  readonly date: Date
  readonly finishDate: Date
  readonly place: string
  readonly typeOf: number
}

export function ListView(props: ListViewProps) {
  const { t } = useTranslation()
  const loggedUser = loggedUserService.get()
  const [events, setEvents] = useState<E[]>(props.events || [])
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [openModalGenerate, setOpenModalGenerate] = useState<boolean>(false)
  const [selectedEvent, setSelectedEvent] = useState<E>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [appointment, setAppointment] = useState<ItemList<Appointment>>(emptyList())

  useEffect(() => {
    const finishDate = new Date(props.selectedDate)
    finishDate.setDate(finishDate.getDate() + 1)
    eventService
      .getFilteredList(
        new Query({
          query: [new QueryParam<E>('startDate', props.selectedDate.toISOString())],
        }),
        props.id ? props.id : loggedUserService.get()?.id || ''
      )
      .subscribe((res) => {
        setEvents(
          res.items.filter(
            (i) =>
              new Date(i.startDate).getDate() == props.selectedDate.getDate() &&
              new Date(i.startDate).getMonth() == props.selectedDate.getMonth()
          )
        )
        setIsLoading(false)
      })
  }, [props.selectedDate])

  useEffect(() => {
    if (!loggedUser) return
    appointmentService
      .getFilteredList(
        new Query({
          query: [new QueryParam<Appointment>('userID', loggedUser?.id)],
        })
      )
      .subscribe((res) => {
        if (!res) return
        setAppointment(res)
      })
  }, [])

  const getIcon = (type: EventCategory) => {
    switch (type) {
      case EventCategory.Reviews:
        return (
          <Tooltip title={t('reviews') || 'reviews'}>
            <img src={reviewIcon} alt={'reviews'} />
          </Tooltip>
        )

      case EventCategory.Medication:
        return (
          <Tooltip title={t('medicalappointment') || 'medicalappointment'}>
            <img src={dateIcon} alt={'medicalappointment'} />
          </Tooltip>
        )

      case EventCategory.Vaccines:
        return (
          <Tooltip title={t('vaccine') || 'vaccine'}>
            <img src={vaccineIcon} alt={'vaccine'} />
          </Tooltip>
        )

      case EventCategory.Appointment:
        return (
          <Tooltip title={t('appointment') || 'appointment'}>
            <img src={dateIcon} alt={'appointment'} />
          </Tooltip>
        )

      case EventCategory.Advices:
        return (
          <Tooltip title={t('advices') || 'advices'}>
            <img src={adviceIcon} alt={'advices'} />
          </Tooltip>
        )

      case EventCategory.Landmarks:
        return (
          <Tooltip title={t('landmarks') || 'landmarks'}>
            <img src={landmarkIcon} alt={'landmarks'} />
          </Tooltip>
        )

      case EventCategory.Training:
        return (
          <Tooltip title={t('training') || 'training'}>
            <img src={trainingIcon} alt={'training'} />
          </Tooltip>
        )
      case EventCategory.Forms:
        return (
          <Tooltip title={t('form') || 'form'}>
            <img src={formIcon} alt={'training'} />
          </Tooltip>
        )

      default:
        return (
          <Tooltip title={t('others') || 'others'}>
            <img src={othersIcon} alt={'others'} />
          </Tooltip>
        )
    }
  }

  const handleClick = (e: E) => {
    setSelectedEvent(e)
    setOpenModal(true)
  }

  const handleCloseModal = () => setOpenModal(false)

  const handleCloseModalGenerate = () => setOpenModalGenerate(false)

  const handleGenerateExercise = () => {
    setOpenModalGenerate(true)
  }

  const editEvent = (e: E) => {
    props.handlerEdit(e)
    setOpenModal(false)
    //statusService.sendStatus({ variant: 'success' })
  }

  const deleteEvent = (e: E) => {
    props.handlerRemove(e)
    setOpenModal(false)
    statusService.sendStatus({ variant: 'success' })
  }

  const isOwner = (e: E) => loggedUser?.id === e.ownerID

  const isTraining = (e: E) => e.eventCategory === EventCategory.Training

  //el plugin relativeTime permite usar from pero hay problema con la data (startDate y finishDate) no se lee bien
  //en BD event_calendar solo se guarda la fecha sin la hora
  var relativeTime = require('dayjs/plugin/relativeTime')
  dayjs.extend(relativeTime)
  const fields: Field<E>[] = [
    {
      name: 'startDate',
      label: t('date'),
      renderFunc: (f, i) => {
        if (i.eventCategory !== EventCategory.Training) {
          return `${dateToTimeWithoutSecondsString(i.startDate)}-${dateToTimeWithoutSecondsString(
            i.finishDate
          )}`
        } else {
          return `${dateToDateString(i.startDate)}-${dateToDateString(i.finishDate)}`
        }
      },
    },
    {
      name: 'finishDate',
      label: t('eventDuration'),
      //startDate y finishDate cambiado de orden, mirar documentacion de dayjs
      renderFunc: (f, i) =>
        dayjs(i.startDate)
          .locale(window.navigator.language.split('-')[0])
          .from(dayjs(i.finishDate), true),
    },
    {
      name: 'title',
      label: t('title'),
      renderFunc: function (f, i): string {
        if (i.eventCategory == EventCategory.Training) {
          return t(i.title)
        } else {
          return i.title
        }
      },
    },

    {
      name: 'eventCategory',
      label: t('type'),
      renderFunc: (f, i) => getIcon(i.eventCategory),
    },
  ]

  const actions: Actions<E> = {
    actionsColumn: t('Actions'),
    items: [
      {
        handler: handleClick,
        icon: seeIcon,
        label: 'see',
      },
      /*    {
              handler: editEvent,
              icon: editIcon,
              label: 'edit',
              hidden: (e) => !isOwner(e),
            },
            {
              handler: deleteEvent,
              icon: deleteIcon,
              label: 'delete',
              hidden: (e) => !isOwner(e),
            },
            */
    ],
  }

  return (
    <>
      {!isLoading && (
        <>
          {/*<Box className={style.role} display="flex" alignItems="center">
            <div className={style.roleStringDays}>
              <p>
                {dayjs(props.selectedDate)
                  .locale(navigator.language.split('-')[0])
                  .format('DD MMMM, dddd')}
              </p>
            </div>
          </Box>*/}
          <Box display={'flex'} className={style.listContainer}>
            {events.length > 0 && (
              <AppTable fields={fields} actions={actions} items={events} rowKeyField="id" />
            )}
            {events.length == 0 && <h3 style={{ padding: 20 }}>{t('noEventsDiary')}</h3>}
          </Box>
        </>
      )}
      {selectedEvent && (
        <Modal open={openModal} className={style.eventModal} onClose={handleCloseModal}>
          <CalendarModal
            event={selectedEvent}
            handleClose={handleCloseModal}
            handleEdit={editEvent}
            handleRemove={deleteEvent}
          />
        </Modal>
      )}
    </>
  )
}
