import { Box, Alert, Typography, Modal, Tabs, Tab } from '@mui/material'
import { RouteProps } from 'routes/AppRouter'
import genericStyle from '../../common/utils/generic.module.css'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useState } from 'react'
import styles from './CreatePatientView.module.scss'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { CustomModalRemoveFamiliar } from '../../components/modal/CustomModalRemoveFamiliar'
import { usePatientData } from '../../hooks/usePatientData'
import { useFamiliars } from '../../hooks/useFamiliars'
import { useForms } from '../../hooks/useForms'
import PatientForm from './patient-view/PatientForm'
import FamiliarsList from './patient-view/FamiliarList'
import FormsList from './patient-view/FormList'
import { useIsOpenMenuLeftContext } from '../../common/utils/isOpenMenuLeftContext'
import AvatarProfile from '../../components/avatar-profile/AvatarProfile'
import { ConductFormModal } from '../form-generator/conduct-form/ConductFormModal'
import { FeedingFormModal } from '../form-generator/feeding-form/FeedingFormModal'
import { FormType, formTypes } from '../../modules/forms/enums/FormType'
import { ListUserTrainingForm } from '../form-generator/training-form/ListUserTrainingForm'
import { AssignTrainingFormModal } from '../form-generator/training-form/AssignTrainingFormModal'
import { UserTrainingWithTrainingNameDTO } from '../../modules/training/models/UserTrainingWithTrainingNameDTO'
import { getTrainingContainer } from '../../container/training-module'
import { IUserTrainingService, USER_TRAINING_SERVICE_KEY } from '../../modules/training'
import { UserDTO } from '../../modules/users/models/User'
import { UserTrainingWithTrainingName } from '../../modules/training/models/UserTrainingWithTrainingName'
import { UserFormWithTemplateName } from '../../modules/forms/models/UserFormWithTemplateName'
import { getFormContainer } from '../../container/form-module'
import { UserFormService } from '../../modules/forms/services/UserFormService'
import { USER_FORM_SERVICE_KEY } from '../../modules/forms'
import { Query, QueryParam } from '../../common/api/Query'
import { UserTreatmentQuery } from '../../modules/treatments/models/UserTreatment'
import { getContentContainer } from '../../container/treatment-module'
import { IUserTreatmentService } from '../../modules/treatments/services/UserTreatmentService'
import { USER_TREATMENT_SERVICE_KEY } from '../../modules/treatments'
import { UserTreatmentWithTreatmentName } from '../../modules/treatments/models/UserTreatmentWithTreatmentName'
import { MedicationListModal } from './patient-view/MedicationListModal'
import cronParser from 'cron-parser'
import { UserTreatmentWithTreatmentNameDTO } from '../../modules/treatments/models/UserTreatmentWithTreatmentNameDTO'
import { TreatmentDTO } from '../../modules/treatments/models/TreatmentDTO'
import { Treatment } from '../../modules/treatments/models/Treatment'

enum Messages {
  SUCCESS = 'Paciente añadido',
}

type Familiar = {
  id: string
  firstName: string
  lastName: string
  email: string
  phone: string
  role: string
  notification: string[]
}

const userTrainingService =
  getTrainingContainer().get<IUserTrainingService>(USER_TRAINING_SERVICE_KEY)

interface CreatePatientViewProps extends RouteProps {
  onAddFamiliar: () => void
  onRemoveFamiliar: () => void
  familiars: Familiar[]
  isFamiliarViewOpen: boolean
  patient: UserDTO
  userForms: UserFormWithTemplateName[]
}

export interface Schedule extends UserTreatmentWithTreatmentNameDTO {
  frequency: string
}

const userFormService = getFormContainer().get<UserFormService>(USER_FORM_SERVICE_KEY)
const userTreatmentService = getContentContainer().get<IUserTreatmentService>(
  USER_TREATMENT_SERVICE_KEY
)

export const CreatePatientView = (props: CreatePatientViewProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    handleChange,
    familiars,
    handleDateChange,
    savePatient,
    handleRecurrence,
    training,
    setTraining,
  } = usePatientData(props.familiars, [], props?.id)
  const { selectedFamiliars, handleSelectFamiliar, handleRemoveFamiliar, setSelectedFamiliars } =
    useFamiliars(props.familiars)
  const {
    selectedForms,
    handleSelectedForms,
    handleEditSelectedForms,
    openConductModal,
    openFeedingModal,
    handleOpenConductModal,
    handleOpenFeedingModal,
    openTrainingModal,
    handleOpenTrainingModal,
    handleOpenSeeMedicationModal,
    openSeeMedicationModal,
  } = useForms(props?.id)
  const { openMenuLeft } = useIsOpenMenuLeftContext()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [message, setMessage] = useState<string>()
  const [editTraining, setEditTraining] = useState<UserTrainingWithTrainingName>()
  const [openAssignTraining, setOpenAssignTraining] = React.useState<boolean>(false)
  const [numberTimesDays, setNumberTimesDays] = useState<number | string | undefined>()
  const [frequency, setFrequency] = useState<number | string | undefined>()
  const [treatments, setTreatments] = useState<UserTreatmentWithTreatmentNameDTO[]>([])
  const [schedules, setSchedules] = useState<Schedule[]>([])
  const [trainingSelectedWithUserAndNameTraining, setTrainingSelectedWithUserAndNameTraining] =
    React.useState<UserTrainingWithTrainingNameDTO>()

  const [tabIndex, setTabIndex] = useState(0)

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue)
  }

  useEffect(() => {
    if (!props.id) return
    userFormService.getListWithTemplateUserRecurrenceByUserID(props.id).subscribe((response) => {
      if (!response) return
      if (response.items.length > 0) {
        const recurrence = response.items[0].templateUserRecurrence.recurrence
        const match = recurrence.match(/0 (\d+)-(\d+)\/(\d+) \* \* \*/)
        if (match) {
          const [, , , freq] = match.map(Number)
          if (24 % freq === 0) {
            setNumberTimesDays(24 / freq)
            setFrequency('')
          } else {
            setNumberTimesDays('')
            setFrequency(freq)
          }
        }
      }
    })

    console.log('rpobando')
    userTreatmentService
      .getListWithTreatmentNameByUserID(
        new Query({
          query: [new QueryParam<UserTreatmentQuery>('userID', props.id)],
        })
      )
      .subscribe((res) => {
        setTreatments(res.items)
      })
  }, [props.id])

  useEffect(() => {
    const newSchedules = treatments.map((treatment) => {
      const interval = cronParser.parseExpression(treatment.userTreatment.recurrence)
      const nextTimes = []
      for (let i = 0; i < 5; i++) {
        // Calcula las próximas 5 ocurrencias
        nextTimes.push(interval.next().toString())
      }

      // Extraer la frecuencia en horas de la expresión cron
      const cronParts = treatment.userTreatment.recurrence.split(' ')
      let frequency = ''

      if (cronParts[1].startsWith('*/')) {
        frequency = `${cronParts[1].substring(2)}h`
      } else if (
        cronParts[0] === '0' &&
        cronParts[1] === '0' &&
        cronParts[2] === '*' &&
        cronParts[3] === '*' &&
        cronParts[4] === '*'
      ) {
        frequency = '24h'
      }

      return {
        ...treatment,
        frequency,
      }
    })
    setSchedules(newSchedules)
  }, [treatments])

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault()
    savePatient(e)
    setMessage(Messages.SUCCESS)
  }

  const handleOpenAssignTraining = () => {
    setOpenAssignTraining(!openAssignTraining)
  }

  console.log('selectedForms', selectedForms)

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginLeft: openMenuLeft ? '50px' : '',
      }}
    >
      <Box
        style={{
          maxWidth: openMenuLeft ? 'calc(100% - 250px)' : 'calc(100% - 300px)',
          minWidth: '1040px',
        }}
        className={genericStyle.pageContainer}
      >
        <Tabs
          value={tabIndex}
          onChange={handleTabChange}
          centered
          classes={{ indicator: styles.customIndicator }}
        >
          <Tab
            label={<span className={styles.tabStyle}>{t('userData')}</span>}
            className={`${tabIndex === 0 ? styles.tabActive : styles.tab} ${styles.firstTab}`}
          />
          <Tab
            label={<span className={styles.tabStyle}>{t('familiars')}</span>}
            className={tabIndex === 1 ? styles.tabActive : styles.tab}
          />
          <Tab
            label={<span className={styles.tabStyle}>{t('forms')}</span>}
            className={`${tabIndex === 2 ? styles.tabActive : styles.tab} ${styles.lastTab}`}
          />
        </Tabs>
        <Box
          className={genericStyle.container}
          style={{
            maxHeight: 'fit-content',
          }}
        >
          {openSeeMedicationModal && (
            <Modal open={openSeeMedicationModal}>
              <MedicationListModal
                schedules={schedules}
                treatments={treatments}
                onClose={handleOpenSeeMedicationModal}
              />
            </Modal>
          )}
          {selectedForms.has(formTypes()[FormType.Conduct]) && (
            <Modal open={openConductModal}>
              <ConductFormModal
                handleRecurrence={handleRecurrence}
                onClose={handleOpenConductModal}
                userNumberTimesDays={numberTimesDays}
                userFrequency={frequency}
                id={props?.id}
              />
            </Modal>
          )}
          {selectedForms.has(formTypes()[FormType.Feeding]) && (
            <Modal open={openFeedingModal}>
              <FeedingFormModal
                handleRecurrence={handleRecurrence}
                onClose={handleOpenFeedingModal}
                id={props?.id}
                userForms={props.userForms}
              />
            </Modal>
          )}
          {selectedForms.has(formTypes()[FormType.Training]) && (
            <Modal open={openTrainingModal}>
              <ListUserTrainingForm
                userID={props.patient.id}
                handleOpenAssignTraining={handleOpenAssignTraining}
                onClose={handleOpenTrainingModal}
                trainingSelected={trainingSelectedWithUserAndNameTraining}
                setTraining={setTraining}
                setEditTraining={setEditTraining}
              />
            </Modal>
          )}
          {openAssignTraining && (
            <Modal open={openAssignTraining}>
              <AssignTrainingFormModal
                handleRecurrence={handleRecurrence}
                onClose={handleOpenAssignTraining}
                setTrainingSelected={setTrainingSelectedWithUserAndNameTraining}
                editTraining={editTraining}
              />
            </Modal>
          )}

          <form className={styles.form} id={'editUser'}>
            <Box display={'flex'} bgcolor="transparent" m="1rem">
              <Box id={'Seccion Izquierda'} className={styles.avatarContainer}>
                <AvatarProfile />
                <Box maxWidth={'100%'}>
                  <Typography className={styles.userName}>
                    {`${props.patient.firstName} ${props.patient.lastName}`}
                  </Typography>
                  <Typography className={styles.roleText}>{t('patient')}</Typography>
                </Box>
              </Box>
              <Box display={'flex'} flexGrow={1} id={'Seccion Derecha'} flexDirection={'column'}>
                {tabIndex === 0 && (
                  <PatientForm
                    patient={props.patient}
                    handleChange={handleChange}
                    handleDateChange={handleDateChange}
                  />
                )}
                {tabIndex === 1 && (
                  <>
                    <FamiliarsList
                      familiars={props.familiars}
                      selectedFamiliars={selectedFamiliars}
                      handleSelectFamiliar={handleSelectFamiliar}
                      onAddFamiliar={props.onAddFamiliar}
                      onRemoveFamiliar={() => setIsModalOpen(true)}
                    />
                    <CustomModalRemoveFamiliar
                      title={t('confirmRemoveFamiliar')}
                      warningText={t('areYouSureRemoveFamiliar')}
                      handleClose={() => setIsModalOpen(false)}
                      handleRemove={() => {
                        handleRemoveFamiliar()
                        setIsModalOpen(false)
                        setSelectedFamiliars(new Set())
                      }}
                      open={isModalOpen}
                    />
                  </>
                )}
                <Box id={'Form y Buttons'} display="flex">
                  {tabIndex === 2 && (
                    <FormsList
                      selectedForms={selectedForms}
                      handleSelectedForms={handleSelectedForms}
                      handleEditSelectedForms={handleEditSelectedForms}
                      handleOpenSeeMedicationModal={handleOpenSeeMedicationModal}
                    />
                  )}
                  <Box
                    className={styles.buttonContainer}
                    display="flex"
                    paddingLeft="40px"
                    paddingBottom="5px"
                  >
                    <Button className={styles.buttonSubmit} type={'button'} onClick={handleSave}>
                      {t('save')}
                    </Button>
                    <Button
                      className={styles.buttonCancel}
                      type={'button'}
                      onClick={() => navigate(-1)}
                    >
                      {t('cancel')}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </form>
          {message && (
            <Box mb={3}>
              <Alert className={styles.message} severity="success" key="message" id="message">
                {t(message)}
              </Alert>
            </Box>
          )}
        </Box>
      </Box>
    </div>
  )
}
