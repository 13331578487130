import React, { createContext, useState, useContext } from 'react'
import { Recurrence } from '../../modules/forms/models/FormDTO'

type RecurrencesContextType = {
  recurrences: Recurrence[] | null
  setRecurrences: (re: Recurrence[]) => void
}

const RecurrencesContext = createContext<RecurrencesContextType | undefined>(undefined)

export const RecurrencesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [recurrences, setRecurrences] = useState<Recurrence[] | null>(null)

  return (
    <RecurrencesContext.Provider value={{ recurrences, setRecurrences }}>
      {children}
    </RecurrencesContext.Provider>
  )
}

export const useRecurrence = (): RecurrencesContextType => {
  const context = useContext(RecurrencesContext)
  if (!context) {
    throw new Error('usePatient debe estar dentro del proveedor PatientContext')
  }
  return context
}
