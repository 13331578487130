import { UserDTO } from '../../modules/users/models/User'
import { LangType } from '../../modules/content/enums/LangType'
import { LoggedUser } from '../../modules/users/models/LoggedUser'

export const validatePatientForm = (patient: UserDTO): boolean => {
  const { dob, lastName, firstName } = patient
  return !!(dob && lastName && firstName)
}

export const formatPatientData = (patient: UserDTO, loggedUser: LoggedUser | null): UserDTO => {
  return {
    ...patient,
    language: loggedUser?.language || LangType.Spanish,
    assignedID: loggedUser?.id || '',
  }
}
