import i18n from 'i18n/index'

export const CIRCLES_TITLE = i18n.t('circles')
export const USERS_TITLE = i18n.t('patients')
export const USER_PROFILE_TITLE = i18n.t('userProfile')
export const NEWSWALL_TITLE = i18n.t('newsWall')
export const FORM_GENERATOR_TITLE = i18n.t('generateForms')
export const FORM_GENERATOR_EDITOR_TITLE = `${FORM_GENERATOR_TITLE} - ${i18n.t('newForm')}`
export const FORM_GENERATOR_RESULTS_TITLE = i18n.t('formResults')
export const FORM_GENERATOR_REMOVE_TITLE = i18n.t('removeForms')
export const FORM_GENERATOR_USERS_TITLE = `${FORM_GENERATOR_TITLE} - ${i18n.t('sendForms')}`
export const MESSENGER_TITLE = i18n.t('messenger')
export const CALENDAR_TITLE = i18n.t('calendar')
export const CALENDAR_EXERCISE_TITLE = i18n.t('calendarExercises')
export const ARTICLES_TITLE = i18n.t('news')
export const CONFIGURATION_TITLE = i18n.t('configuration')
export const PATIENTS_FORMS_TITLE = i18n.t('patientsForms')
export const PATIENT_FORM_VIEW_TITLE = i18n.t('form')
export const RESOURCES_TITLE = i18n.t('resources')
export const RESOURCES_SHARED_WITH_ME_TITLE = i18n.t('resourcesSharedWithMe')
export const RESOURCES_NOTIFICATIONS_CONFIG_TITLE = i18n.t('notificationsConfig')
export const ROLE_PERMISSIONS_TITLE = i18n.t('title_role_permissions')
export const MOBILE_MENU_TITLE = i18n.t('menu')
export const CREATE_PARTICIPANT_TITLE = i18n.t('addParticipant')
export const CREATE_PATIENT_TITLE = i18n.t('addPatient')
export const EDIT_PATIENT_TITLE = i18n.t('edit_patient')
export const PATIENT_CHARTS_TITLE = i18n.t('patientCharts')
export const STATISTICS_TITLE = i18n.t('statistics')
export const TRAINING_TITLE = i18n.t('training')
export const CREATE_TRAINING_TITLE = i18n.t('addTraining')
export const DASHBOARD_TITLE = i18n.t('dashboard')
export const TREATMENT_TITLE = i18n.t('treatments')
export const FORMS_TITLE = i18n.t('forms')
export const FORM_GENERATOR_FORM_CREATE_TITLE = i18n.t('createForm')
export const FORM_GENERATOR_ASSIGNTO_TITLE = i18n.t('assignFormTo')
export const PRIVACY_POLICY_TITLE = i18n.t('privacyPolicy')
export const COOKIES_POLICY_TITLE = i18n.t('cookiesPolicy')
export const ALL_TREATMENTS_TITLE = i18n.t('treatments')
export const PROFESIONALS_TITLE = i18n.t('profesionals')
