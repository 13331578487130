import { Familiar } from '../../hooks/useFamiliars'
import { v4 as uuidv4 } from 'uuid'

export const NOTIFICATIONS = ['food', 'medication', 'conduct', 'training']

export const initialFamiliars: Familiar = {
  id: uuidv4(),
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  role: '',
  notification: [],
}

export const FORM_NAME = {
  FOOD: 'Feeding Tracking',
  CONDUCT: 'Conduct',
  MEDICATION: 'Medication',
  TRAINING: 'Training',
}
