import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { STATUS_SERVICE_KEY } from '../../../container/app'
import { UserTraining } from '../models/UserTraining'
import { UserTrainingDTO } from '../models/UserTrainingDTO'
import { IUserTrainingApi } from '../api/UserTrainingApi'
import { UserTrainingWithTrainingName } from '../models/UserTrainingWithTrainingName'

type Props = {
  apiKey: symbol
}

export interface IUserTrainingService extends IInit {
  getByID(id: string): Observable<UserTraining | undefined>

  getFilteredList(q: Query<UserTraining>): Observable<ItemList<UserTraining>>

  getListWithTrainingNameByUserID(
    q: Query<UserTrainingWithTrainingName>
  ): Observable<ItemList<UserTrainingWithTrainingName>>

  add(e: UserTrainingDTO): Observable<UserTraining | undefined>

  addUserTrainings(e: UserTrainingDTO[]): Observable<UserTraining | undefined>

  update(e: UserTrainingDTO): Observable<UserTraining | undefined>

  delete(id: string): Observable<boolean>

  deleteUserTrainings(ids: string[]): Observable<boolean>

  updateEditUserTrainings(e: UserTrainingDTO[]): Observable<UserTraining | undefined>
}

export class UserTrainingService implements IUserTrainingService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IUserTrainingApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IUserTrainingApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: UserTrainingDTO): Observable<UserTraining | undefined> {
    return this._api.add(e)
  }

  addUserTrainings(e: UserTrainingDTO[]): Observable<UserTraining | undefined> {
    return this._api.addUserTrainings(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  deleteUserTrainings(ids: string[]): Observable<boolean> {
    return this._api.deleteUserTrainings(ids)
  }

  getByID(id: string): Observable<UserTraining | undefined> {
    return this._api.getByID(id)
  }

  getFilteredList(q: Query<UserTraining>): Observable<ItemList<UserTraining>> {
    return this._api.getFilteredList(q).pipe()
  }

  getListWithTrainingNameByUserID(
    q: Query<UserTrainingWithTrainingName>
  ): Observable<ItemList<UserTrainingWithTrainingName>> {
    return this._api.getListWithTrainingNameByUserID(q)
  }

  update(e: UserTrainingDTO): Observable<UserTraining | undefined> {
    return this._api.update(e)
  }

  updateEditUserTrainings(e: UserTrainingDTO[]): Observable<UserTraining | undefined> {
    return this._api.updateEditUserTrainings(e)
  }
}
