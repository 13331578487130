import { Treatment } from './Treatment'
import { v4 as uuidv4 } from 'uuid'
import { UnitType } from '../enums/UnitType'

export interface TreatmentDTO {
  id: string | undefined
  name: string
  unit: UnitType
  creatorID: string
}

export function emptyTreatmentDTO() {
  return {
    id: uuidv4(),
    name: '',
    unit: UnitType.mg,
    creatorID: '',
  }
}

export function fromModel(d: Treatment): TreatmentDTO {
  return {
    id: d.id,
    name: d.name,
    unit: d.unit,
    creatorID: d.creatorID,
  }
}

export function toModel(d: TreatmentDTO): Treatment {
  return new Treatment(d)
}
