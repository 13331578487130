import { CreateFamiliarView } from './CreateFamiliarView'
import { CreatePatientView } from './CreatePatientView'
import { useParams } from 'react-router-dom'
import { RouteProps } from '../../routes/AppRouter'
import { useEffect, useState } from 'react'
import { PatientProvider } from './PatientContext'
import { Familiar, useFamiliars } from '../../hooks/useFamiliars'
import { usePatient } from './patient-view/PatientContext'
import { getUserContainer } from '../../container/user-module'
import { IUserService, USER_SERVICE_KEY } from '../../modules/users'
import { emptyUserDTO, fromModel, User } from '../../modules/users/models/User'
import { getFormContainer } from '../../container/form-module'
import { IUserFormService, USER_FORM_SERVICE_KEY } from '../../modules/forms'
import { UserFormWithTemplateName } from '../../modules/forms/models/UserFormWithTemplateName'
import { RecurrencesProvider } from './RecurrenceContext'
import { getCircleContainer } from '../../container/circle-module'
import { CIRCLE_SERVICE_KEY, ICircleService } from '../../modules/circle'

const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const userFormService = getFormContainer().get<IUserFormService>(USER_FORM_SERVICE_KEY)
const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)
export function Editor(props: RouteProps) {
  const { id } = useParams()
  const [isFamiliarViewOpen, setFamiliarViewOpen] = useState(false)
  const [isPatientViewOpen, setPatientViewOpen] = useState(true)
  const [familiars, setFamiliars] = useState<Familiar[]>([])
  const [userForms, setUserForms] = useState<UserFormWithTemplateName[]>([])
  const { patient, setPatient } = usePatient()
  const { handleRemoveFamiliar, isSave } = useFamiliars(familiars)

  useEffect(() => {
    if (id)
      circleService.getByPatientID(id).subscribe((res) => {
        if (res?.familiars) {
          Promise.all(
            res.familiars.map((familiarId) => userService.getByID(familiarId).toPromise())
          ).then((familiarsInfo) => {
            const filteredFamiliarsInfo = familiarsInfo.filter(
              (familiar): familiar is User => familiar !== undefined
            )
            const familiars: Familiar[] = filteredFamiliarsInfo.map((user) => ({
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              email: user.email,
              phone: ' ',
              role: 'familiar',
              notification: [],
            }))
            setFamiliars(familiars)
          })
        }
      })
  }, [id])

  useEffect(() => {
    if (id) {
      userService.getByID(id).subscribe((response) => {
        if (!response) return
        setPatient(fromModel(response))
      })
      userFormService.getWithTemplateNamesByUserID(id).subscribe((response) => {
        if (!response) return
        setUserForms(response.items)
      })
      return
    }
    setPatient(emptyUserDTO())
  }, [id])

  const handlerFamiliars = (familiar: Familiar) => {
    console.log('familiar del editor', familiar)
    setFamiliars([...familiars, familiar])
  }

  const handleOpenFamiliarView = () => {
    setFamiliarViewOpen(true)
    setPatientViewOpen(false)
  }
  const handleCloseFamiliarView = () => {
    setFamiliarViewOpen(false)
    setPatientViewOpen(true)
  }

  useEffect(() => {}, [familiars])

  const handleAddFamiliar = () => {
    setFamiliarViewOpen(isSave)
    if (isSave) {
      setPatientViewOpen(false)
    } else {
      setPatientViewOpen(true)
    }
  }

  return (
    <PatientProvider>
      <RecurrencesProvider>
        <>
          {isPatientViewOpen && (
            <CreatePatientView
              id={id}
              familiars={familiars}
              isFamiliarViewOpen={isFamiliarViewOpen}
              onAddFamiliar={handleOpenFamiliarView}
              onRemoveFamiliar={handleRemoveFamiliar}
              patient={patient}
              userForms={userForms}
            />
          )}
          {isFamiliarViewOpen && (
            <CreateFamiliarView
              id={id}
              familiars={familiars}
              onSave={handleAddFamiliar}
              handleAddFamiliar={handlerFamiliars}
              onCancel={handleCloseFamiliarView}
            />
          )}
        </>
      </RecurrencesProvider>
    </PatientProvider>
  )
}
