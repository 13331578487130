import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import styles from './MedicationListModal.module.scss'
import { Header } from '../../../components/header/Header'
import React from 'react'
import { useTranslation } from 'react-i18next'
import close from '../../../assets/articles/delete.svg'
import { UserTreatmentWithTreatmentNameDTO } from '../../../modules/treatments/models/UserTreatmentWithTreatmentNameDTO'
import { Schedule } from '../CreatePatientView'

type MedicationListModalProps = {
  treatments: UserTreatmentWithTreatmentNameDTO[]
  schedules: Schedule[]
  onClose: () => void
}

export const MedicationListModal: React.FC<MedicationListModalProps> = ({
  treatments,
  onClose,
  schedules,
}) => {
  const { t } = useTranslation()

  return (
    <Box className={styles.container}>
      <Box className={styles.content}>
        <Header labelStyle={styles.header} label={t('medication')} icon={close} onClick={onClose} />
        <TableContainer
          sx={{
            boxShadow: 'none',
            backgroundColor: 'transparent',
            overflowY: 'auto',
            maxHeight: '400px',
          }}
          component={Paper}
        >
          <Table
            sx={{
              border: 'none',
              background: 'transparent',
              margin: 0,
              overflow: 'auto',
              '& td': {
                background: 'transparent',
                border: 'none',
                fontFamily: 'Montserrat-Regular, sans-serif',
                color: '#474747',
                fontSize: '1rem',
                '&:last-child': {
                  border: 'none',
                },
                '&:first-child': {
                  borderLeftWidth: 1,
                  borderLeft: 'none',
                  borderColor: '#ADB84E',
                  paddingRight: '120px',
                },
                '&:nth-child(2)': {
                  paddingRight: '60px',
                },
              },
              '& tr': {
                border: 'none',
                background: 'transparent',
              },
              '& th': {
                border: 'none',
                color: '#ADB84E',
                fontFamily: 'Montserrat-SemiBold, sans-serif',
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>{t('Nombre')}</TableCell>
                <TableCell>{t('Dosis')}</TableCell>
                <TableCell>{t('Frecuencia')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                background: 'transparent',
              }}
            >
              {schedules.map((schedule: any) => (
                <TableRow key={schedule.id}>
                  <TableCell>{schedule._name}</TableCell>
                  <TableCell>{schedule._userTreatment.dose}</TableCell>
                  <TableCell>{schedule.frequency}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}
