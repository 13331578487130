import React, { useState, useEffect, ChangeEvent } from 'react'
import { UserDTO, emptyUserDTO, fromModel, User } from '../modules/users/models/User'
import { getUserContainer } from '../container/user-module'
import { usePatient } from '../features/users/patient-view/PatientContext'
import { useNavigate } from 'react-router-dom'
import {
  IUserService,
  LOGGED_USER_SERVICE_KEY,
  ROLES_SERVICE_KEY,
  USER_SERVICE_KEY,
} from '../modules/users'
import { IStatusService } from '../common/status/StatusService'
import { getAppContainer, STATUS_SERVICE_KEY } from '../container/app'
import { CircleDTO } from '../modules/circle/models/CircleDTO'
import { ICircleService, CIRCLE_SERVICE_KEY } from '../modules/circle'
import { v4 as uuidv4 } from 'uuid'
import { Familiar } from './useFamiliars'
import { Recurrence } from '../modules/forms/models/FormDTO'
import { ILoggedUserService } from '../modules/users/services/LoggedUserService'
import { getCircleContainer } from '../container/circle-module'
import { Role } from '../modules/users/models/Role'
import { RolesService } from '../modules/users/services/RolesServices'
import { FeedType, feedTypes, FormType, formTypes } from '../modules/forms/enums/FormType'
import { formatPatientData, validatePatientForm } from '../features/users/utils'
import { UserTrainingDTO } from '../modules/training/models/UserTrainingDTO'
import { getTrainingContainer } from '../container/training-module'
import { IUserTrainingService, USER_TRAINING_SERVICE_KEY } from '../modules/training'
import { getFormContainer } from '../container/form-module'
import { IUserFormService, USER_FORM_SERVICE_KEY } from '../modules/forms'
import { useRecurrence } from '../features/users/RecurrenceContext'
getTrainingContainer().get<IUserTrainingService>(USER_TRAINING_SERVICE_KEY)
const userService = getUserContainer().get<IUserService>(USER_SERVICE_KEY)
const loggedUserService = getUserContainer().get<ILoggedUserService>(LOGGED_USER_SERVICE_KEY)
const statusService = getAppContainer().get<IStatusService>(STATUS_SERVICE_KEY)
const circleService = getCircleContainer().get<ICircleService>(CIRCLE_SERVICE_KEY)
const roleContainer = getUserContainer()
const roleService = roleContainer.get<RolesService>(ROLES_SERVICE_KEY)
const userTrainingService =
  getTrainingContainer().get<IUserTrainingService>(USER_TRAINING_SERVICE_KEY)
const userFormService = getFormContainer().get<IUserFormService>(USER_FORM_SERVICE_KEY)

export const usePatientData = (
  initialFamiliars: Familiar[],
  initialRecurrence: Recurrence[],
  id: string | undefined
) => {
  const [patient, setPatient] = useState<UserDTO>(emptyUserDTO())
  const { patient: patientContext, setPatient: setPatientContext } = usePatient()
  const [familiars, setFamiliars] = useState<Familiar[]>(initialFamiliars)
  const [recurrence, setRecurrence] = useState<Recurrence[]>(initialRecurrence)
  const [roles, setRoles] = useState<Role[]>([])
  const [training, setTraining] = useState<UserTrainingDTO[]>([])
  const { setRecurrences } = useRecurrence()
  const navigate = useNavigate()

  useEffect(() => {
    if (!id) {
      return
    }

    userFormService.getWithTemplateNamesByUserID(id).subscribe((response) => {
      if (!response) return

      const feedTypeToRecurrenceMap = {
        [feedTypes()[FeedType.Breakfast]]: '0 6 * * *',
        [feedTypes()[FeedType.Lunch]]: '0 12 * * *',
        [feedTypes()[FeedType.Dinner]]: '0 19 * * *',
      }

      const newRecurrences: Recurrence[] = response.items.reduce<Recurrence[]>((acc, form) => {
        const recurrence = feedTypeToRecurrenceMap[form.typeForm]
        if (recurrence) {
          acc.push({
            templateType: formTypes()[FormType.Feeding],
            recurrence,
          })
        }
        return acc
      }, [])
      setRecurrence(newRecurrences)
      setRecurrences(newRecurrences)
    })

    let rolesAux = new Array<Role>()
    roleService.getAll().subscribe((res) => {
      res.forEach((e) => {
        if (
          e.name === 'externProfessional' ||
          e.name === 'family/Tutor' ||
          e.name === 'carer/Teacher'
        ) {
          rolesAux.push(e)
        }
      })
      setRoles(rolesAux)
    })
  }, [])

  const handleUpdateFamiliars = (familiar: Familiar) => {
    const updatedFamiliars = familiars.map((f) => (f.id === familiar.id ? familiar : f))
    setFamiliars((prevState) => ({
      ...prevState,
      familiar,
    }))
  }

  useEffect(() => {
    console.log('FAMILIARS', familiars)
  }, [familiars])

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target
    const updatedPatient = { ...patientContext, [id]: value }
    setPatientContext(updatedPatient)
  }

  const handleDateChange = (date: Date) => {
    const updatedPatient = { ...patientContext, dob: date }
    setPatientContext(updatedPatient)
  }

  const handleRecurrence = (templateType: FormType, recurrence: string) => {
    setRecurrence((prevRecurrences) => {
      const newRecurrences = prevRecurrences ? [...prevRecurrences] : []
      const index = newRecurrences.findIndex((re) => re.recurrence === recurrence)
      if (index !== -1) {
        newRecurrences.splice(index, 1)
      } else {
        newRecurrences.push({ templateType: formTypes()[templateType], recurrence })
      }
      setRecurrences(newRecurrences)
      return newRecurrences
    })
  }
  const savePatient = (e: React.FormEvent) => {
    console.log('entro al save')
    e.preventDefault()
    if (!validatePatientForm(patientContext)) return

    const loggedUser = loggedUserService.get()
    const newPatient = formatPatientData(patientContext, loggedUser || null)

    if (id) {
      const familiarsDTO: UserDTO[] = familiars.map((f) => ({
        ...f,
        id: uuidv4(),
        language: patientContext.language || 0,
        assignedID: loggedUserService.get()?.id || '',
        isPatient: false,
        gender: 1,
        dob: new Date(),
        firstName: f.firstName,
        lastName: f.lastName,
        email: f.email,
        role: f.role,
        notification: f.notification,
        diagnosis: '',
        treatment: '',
        recurrence: [],
        diagnosisSummary: '',
        treatmentSummary: '',
        hasUsedEpico: false,
        isActive: true,
        patologies: [],
        roles: [],
        historyRecordNumber: 0,
      }))
      console.log('familiarDTO', familiarsDTO)
      userService
        .updateWithFamiliars(newPatient, familiarsDTO, recurrence, navigator.language)
        .subscribe(() => {
          statusService.sendStatus({ variant: 'success' })
          navigate('/patients')
        })
      userTrainingService.updateEditUserTrainings(training).subscribe((response) => {})
    } else {
      console.log('entro en el else')
      const familiarsDTO: UserDTO[] = familiars.map((f) => ({
        ...f,
        id: uuidv4(),
        language: patientContext.language || 0,
        assignedID: loggedUserService.get()?.id || '',
        isPatient: false,
        gender: 1,
        dob: new Date(),
        firstName: f.firstName,
        lastName: f.lastName,
        email: f.email,
        role: f.role,
        notification: f.notification,
        diagnosis: '',
        treatment: '',
        recurrence: [],
        diagnosisSummary: '',
        treatmentSummary: '',
        hasUsedEpico: false,
        isActive: true,
        patologies: [],
        roles: [],
        historyRecordNumber: 0,
      }))
      userService
        .addWithFamiliars(newPatient, familiarsDTO, recurrence, navigator.language)
        .subscribe((res) => {
          if (res) {
            statusService.sendStatus({ variant: 'success' })
            navigate('/patients')
          }
        })
      handleSaveTraining()
    }
  }
  const handleSaveTraining = () => {
    userTrainingService.addUserTrainings(training).subscribe((response) => {})
  }

  return {
    patient,
    familiars,
    roles,
    recurrence,
    handleChange,
    handleDateChange,
    handleRecurrence,
    savePatient,
    setFamiliars,
    setRecurrence,
    training,
    setTraining,
    handleUpdateFamiliars,
  }
}
