import { Box, Typography } from '@mui/material'
import style from './CustomModalRemoveFamiliar.module.css'
import { useTranslation } from 'react-i18next'
import { AppButton, ButtonTheme } from '../app-button/AppButton'
import close from '../../assets/articles/delete.svg'

type toAgoraProps = {
  title: string
  warningText: string
  handleClose: () => void
  handleRemove: () => void
  open: boolean
}

export const CustomModalRemoveFamiliar = (props: toAgoraProps): JSX.Element | null => {
  const { t } = useTranslation()

  if (!props.open) {
    return null
  }

  return (
    <div className={style.modalOverlay}>
      <div className={style.agoraContainer}>
        <Box className={style.containerInside}>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
            <Box className={style.headerContainer}>
              <Typography className={style.headerTitle}>{props.title}</Typography>
            </Box>
            <img
              src={close}
              alt="Close"
              onClick={props.handleClose}
              style={{ cursor: 'pointer', marginTop: '-70px' }}
            />
          </Box>
          <form>
            <Box mb={3}>
              <p style={{ textAlign: 'center' }}>{props.warningText}</p>
            </Box>
            <Box mt={1} mb={1} display="flex" justifyContent="center">
              <Box mr={2}>
                <AppButton
                  theme={ButtonTheme.CancelButton}
                  type={'button'}
                  label={t('delete')}
                  handler={props.handleRemove}
                />
              </Box>
              <Box ml={2}>
                <AppButton
                  theme={ButtonTheme.NewSecondary}
                  type={'button'}
                  label={t('cancel')}
                  handler={props.handleClose}
                />
              </Box>
            </Box>
          </form>
        </Box>
      </div>
    </div>
  )
}
