import React, { useEffect } from 'react'
import { IUserTrainingService, USER_TRAINING_SERVICE_KEY } from '../../../modules/training'
import { getTrainingContainer } from '../../../container/training-module'
import { Query } from '../../../common/api/Query'
import { Header } from '../../../components/header/Header'
import { useTranslation } from 'react-i18next'
import styles from '../feeding-form/FeedingForm.module.scss'
import style from './ListUserTrainingForm.module.scss'
import editIcon from '../../../assets/user-table/edit.svg'
import close from '../../../assets/articles/delete.svg'
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from '@mui/material'
import { UserTrainingWithTrainingName } from '../../../modules/training/models/UserTrainingWithTrainingName'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import cancelIcon from '../../../assets/user-table/removeTraining.svg'
import {
  UserTrainingWithTrainingNameDTO,
  toModel,
} from '../../../modules/training/models/UserTrainingWithTrainingNameDTO'
import { UserTrainingDTO } from '../../../modules/training/models/UserTrainingDTO'

type ListTrainingFormModalProps = {
  userID: string
  onClose: () => void
  handleOpenAssignTraining: () => void
  trainingSelected?: UserTrainingWithTrainingNameDTO
  setTraining: (training: UserTrainingDTO[]) => void
  setEditTraining: (training: UserTrainingWithTrainingName) => void
}

type ListItemUserTrainingProps = {
  userTrainingList: UserTrainingWithTrainingName[]
  selectedTraining: UserTrainingWithTrainingName[]
  onToggleSelection: (training: UserTrainingWithTrainingName) => void
}

const userTrainingService =
  getTrainingContainer().get<IUserTrainingService>(USER_TRAINING_SERVICE_KEY)

export const ListUserTrainingForm: React.FC<ListTrainingFormModalProps> = ({
  userID,
  onClose,
  handleOpenAssignTraining,
  trainingSelected,
  setTraining,
  setEditTraining,
}) => {
  const { t } = useTranslation()
  const [trainingList, setTrainingList] = React.useState<UserTrainingWithTrainingName[]>([])
  const [selectedTraining, setSelectedTraining] = React.useState<UserTrainingWithTrainingName[]>([])
  const [editTrain, setEditTrain] = React.useState<UserTrainingWithTrainingName>()

  console.log('trainingList', trainingList)

  useEffect(() => {
    userTrainingService
      .getListWithTrainingNameByUserID(
        new Query({
          query: [
            {
              name: 'userID',
              value: userID || '',
            },
          ],
        })
      )
      .subscribe((response) => {
        console.log('userTrainingList', response)
        setTrainingList(response.items)
      })
  }, [])

  useEffect(() => {
    if (trainingSelected) {
      const index = checkIfRepeatingID([
        ...trainingList,
        trainingSelected as UserTrainingWithTrainingName,
      ])
      if (index !== -1) {
        // Reemplazar el objeto repetido con el más reciente
        const newTrainingList = [...trainingList]
        newTrainingList[index] = toModel(trainingSelected)
        setTrainingList(newTrainingList)
      } else {
        setTrainingList([...trainingList, trainingSelected as UserTrainingWithTrainingName])
      }
    }
  }, [trainingSelected])

  const checkIfRepeatingID = (userTraining: UserTrainingWithTrainingName[]) => {
    const ids: { [key: string]: number } = {}
    for (let i = 0; i < userTraining.length; i++) {
      const obj = userTraining[i]
      if (ids[obj.userTraining.id] !== undefined) {
        return ids[obj.userTraining.id] // Devuelve el índice del ID repetido
      }
      ids[obj.userTraining.id] = i
    }
    return -1
  }

  const handleToggleSelection = (training: UserTrainingWithTrainingName) => {
    const selectedIndex = selectedTraining.findIndex(
      (item) => item.userTraining.id === training.userTraining.id
    )
    let newSelected: UserTrainingWithTrainingName[] = []

    if (selectedIndex === -1) {
      newSelected = [...selectedTraining, training]
    } else {
      newSelected = selectedTraining.filter(
        (item) => item.userTraining.id !== training.userTraining.id
      )
    }

    setSelectedTraining(newSelected)
  }

  const handleRemoveTraining = () => {
    const updatedList = trainingList.filter(
      (training) =>
        !selectedTraining.some((selected) => selected.userTraining.id === training.userTraining.id)
    )
    setTrainingList(updatedList)
    setSelectedTraining([])
  }

  const handleSave = () => {
    const userTrainingDTOArray: UserTrainingDTO[] = trainingList.map((training) => ({
      id: training.userTraining.id,
      userID: userID,
      trainingID: training.userTraining.trainingID,
      startDate: training.userTraining.startDate,
      finishDate: training.userTraining.finishDate,
      frecuency: training.userTraining.frecuency,
      series: training.userTraining.series,
      minReps: training.userTraining.minReps,
      maxReps: training.userTraining.maxReps,
      repsEspecs: training.userTraining.repsEspecs,
      typeOfRepeating: training.userTraining.typeOfRepeating,
      completed: training.userTraining.completed,
      recurrence: training.userTraining.recurrence,
    }))
    setTraining(userTrainingDTOArray)
    onClose()
  }

  const handleEditTrain = (training: UserTrainingWithTrainingName) => () => {
    setEditTraining(training)
    handleOpenAssignTraining()
  }

  const ListItemUserTraining: React.FC<ListItemUserTrainingProps> = ({
    userTrainingList,
    selectedTraining,
    onToggleSelection,
  }) => {
    const { t } = useTranslation()

    return (
      <List style={{ display: 'flex', flexDirection: 'column' }}>
        {userTrainingList.map((value) => {
          const labelId = `checkbox-list-label-${value.userTraining.id}`
          const isSelected =
            selectedTraining.findIndex((item) => item.userTraining.id === value.userTraining.id) !==
            -1

          return (
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="comments">
                  <img src={editIcon} alt="edit" onClick={handleEditTrain(value)} />
                </IconButton>
              }
              key={value.userTraining.id}
              disablePadding
            >
              <ListItemButton role={undefined} onClick={() => onToggleSelection(value)} dense>
                <ListItemIcon>
                  <Checkbox
                    style={{ color: 'var(--verde-topbar-y-botones)' }}
                    edge="start"
                    tabIndex={-1}
                    disableRipple
                    checked={isSelected}
                    inputProps={{ 'aria-labelledby': labelId }}
                  />
                </ListItemIcon>
                <p className={style.textTrainingList} id={labelId}>
                  {t(value.title)}
                </p>
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>
    )
  }

  return (
    <Box className={styles.container}>
      <Box className={styles.content}>
        <Header
          labelStyle={styles.header}
          label={t('trainingForm')}
          onClick={onClose}
          icon={close}
        />
        <ListItemUserTraining
          userTrainingList={trainingList}
          selectedTraining={selectedTraining}
          onToggleSelection={handleToggleSelection}
        />
        <Box className={style.buttonContainer} display="flex">
          <Button
            className={style.buttonAdd}
            startIcon={<AddCircleIcon style={{ color: 'var(--verde-topbar-y-botones)' }} />}
            onClick={() => {
              handleOpenAssignTraining()
              setSelectedTraining([])
            }}
          >
            {t('addTraining')}
          </Button>
          <Button
            className={style.buttonRemove}
            startIcon={
              <img
                src={cancelIcon}
                width={'18px'}
                style={{ marginBottom: 4, marginLeft: 1, padding: 0 }}
              />
            }
            onClick={() => {
              handleRemoveTraining()
            }}
          >
            {t('removeTraining')}
          </Button>
        </Box>
        <Box className={style.buttonContainer} display="flex">
          <Button className={style.buttonSave} onClick={handleSave}>
            {t('save')}
          </Button>
          <Button className={style.buttonCancel} onClick={onClose}>
            {t('cancel')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
