import { Form } from '../models/Form'
import { Container, IInit } from '../../../common/container/Container'
import { IStatusService } from '../../../common/status/StatusService'
import { Observable } from 'rxjs'
import { Query } from '../../../common/api/Query'
import { ItemList } from '../../../common/models/ItemList'
import { IFormApi } from '../api/FormApi'
import { FormDTO, Recurrence } from '../models/FormDTO'
import { STATUS_SERVICE_KEY } from '../../../container/app'

type Props = {
  apiKey: symbol
}

export interface IFormService extends IInit {
  getByID(id: string): Observable<Form | undefined>

  getByName(name: string): Observable<Form | undefined>

  getByNamesAndAddUserForms(
    circleID: string,
    userID: string,
    recurrence: Recurrence[]
  ): Observable<any>

  getFilteredList(q: Query<Form>): Observable<ItemList<Form>>

  add(e: FormDTO): Observable<Form | undefined>

  update(e: FormDTO): Observable<Form | undefined>

  //updateFormField(e: FormField): Observable<FormField | undefined>
  delete(id: string): Observable<boolean>
}

export class FormService implements IFormService {
  private readonly _apiKey: symbol
  private _container!: Container
  private _api!: IFormApi
  private _statusService!: IStatusService

  constructor(p: Props) {
    this._apiKey = p.apiKey
  }

  init(c: Container) {
    this._container = c
    this._api = this._container.get<IFormApi>(this._apiKey)
    this._statusService = this._container.get<IStatusService>(STATUS_SERVICE_KEY)
  }

  add(e: FormDTO): Observable<Form | undefined> {
    return this._api.add(e)
  }

  delete(id: string): Observable<boolean> {
    return this._api.delete(id)
  }

  getByID(id: string): Observable<Form | undefined> {
    return this._api.getByID(id)
  }

  getByName(name: string): Observable<Form | undefined> {
    return this._api.getByName(name)
  }

  getByNamesAndAddUserForms(
    circleID: string,
    userID: string,
    recurrence: Recurrence[]
  ): Observable<any> {
    return this._api.getByNamesAndAddUserForms(circleID, userID, recurrence)
  }

  getFilteredList(q: Query<Form>): Observable<ItemList<Form>> {
    return this._api.getFilteredList(q).pipe()
  }

  update(e: FormDTO): Observable<Form | undefined> {
    return this._api.update(e)
  }

  /*updateFormField(e: FormField): Observable<FormField | undefined> {
    return this._api.updateFormField(e)
  }*/
}
